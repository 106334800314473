export default function NoAccess() {
  return (
    <>
      {' '}
      <div className="auth-container">
        <div className="text-center justify-content-center">
          <div className="access-warning">Not Authorized</div>
        </div>
        <div className="text-center justify-content-center">
          You are not authorized to view this page. Please request a role with
          access to this page. <br />
          Here is the link to the <a href="/home"> Home</a> page.
        </div>
      </div>
    </>
  );
}
