import React, { Component } from 'react';
import FieldList from './fieldList';
import { Api } from './interface';

import _ from 'lodash';

class FieldView extends Component {
  constructor(props) {
    super(props);

    this.tableCellHover = [
      {
        '&:hover': {
          backgroundColor: '#BDBDBD',
        },
      },
    ];

    this.textInput = React.createRef();
    this.commentInput = React.createRef();
    this.historyRef = React.createRef();

    this.state = {
      entities: [],
      parentEntityList: [],
      items: [],
      filteredItems: [],
      entityTypes: [],
      template: [],
      filteredTemplate: [],
      statuses: [],
      metaOptions: [],
      fieldArray: [],
      currentEntity: {},
      currentEntityId: -1,
      isOpen: false,
      editing: false,
      cloning: false,
      renaming: false,
      deleting: false,
      statusEdit: -1,
      commenting: false,
      showHistory: true,
      comment: '',
      currentGroupChildren: [],
      currentHistory: [],
      filteredHistory: [],
      currentFilterText: '',
      filterTerm: '',
      filteredRows: [],
      rawTemplate: [],
    };
  }

  componentDidMount() {
    this.getData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.getData();
    }
  }

  getData() {
    Api({
      sp: 'getUsers',
      json: {},
    }).then((users) => {
      this.setState({ users });
    });

    Api({
      sp: 'getTemplateByEntity',
      json: { id: this.props.item.Entity_DBID },
    }).then((template) => {
      this.processTemplate(template);
    });
  }

  processTemplate(template) {
    let fieldArray = [...template];
    let raw = [];
    let rootNodes = fieldArray.filter((x) => x.ParentEntityMetaType_DBID === 0);

    Api({
      sp: 'getGroupHierarchyByEntities',
      json: {
        ids: JSON.stringify([this.props.item.Entity_DBID]),
      },
    }).then((g) => {
      const groups = this.makeTree(
        g,
        (node) => node.ParentEntityGroup_DBID,
        (node, next) => ({ ...node, children: next(node.EntityGroup_DBID) })
      );

      fieldArray.forEach((x) => {
        x.children = x.children || [];
        groups.forEach((g) => {
          if (
            x.EntityMetaType_DBID === g.EntityMetaType_DBID &&
            x.EntityMetaValue === g.EntityMetaValue
          ) {
            x.children = g.children;
          }
        });
        x['Entity_DBID'] = this.props.item.Entity_DBID;
        x['EntityName'] = this.props.item.EntityName;
        if (!x.options) {
          x.options = [{ ParentValue: null }];
        }
        if (x.AllowMultiple) {
          x['values'] = [];
        }
        raw.push(x);
      });

      this.createValueArrays(fieldArray);
      let treeBackup = fieldArray.filter(
        (x) => x.ParentEntityMetaType_DBID !== 0
      );

      let finalTree = this.createNestedTree(rootNodes, treeBackup);
      finalTree = finalTree.filter((x) => {
        return x.DataType !== 'Image';
      });

      this.setState({
        template: _.cloneDeep(finalTree),
        filteredTemplate: _.cloneDeep(finalTree),
        editing: true,
        currentEntity: this.props.item,
        currentEntityId: this.props.item.Entity_DBID,
        rawTemplate: raw,
        fieldArray,
        treeBackup: _.cloneDeep(
          fieldArray.filter((x) => x.ParentEntityMetaType_DBID !== 0)
        ),
      });
    });
  }

  makeTree(input, indexer, maker, root = 0) {
    const index = this.makeIndex(input, indexer);
    const many = (all = []) => all.map(one); // 1
    const one = (single = {}) => maker(single, (r) => many(index.get(r))); // 2
    return many(index.get(root));
  }

  makeIndex = (items, indexer) => {
    const insert = (r, k, v) => r.set(k, (r.get(k) ?? []).concat([v]));
    return items.reduce((r, i) => insert(r, indexer(i), i), new Map());
  };

  createValueArrays = (tree) => {
    for (let i = tree.length - 1; i >= 0; i--) {
      // if the field allows more than one entry value
      if (tree[i].AllowMultiple) {
        // create a container object for the array of values
        if (
          !tree.filter(
            (n) =>
              n.Entity_DBID === tree[i].Entity_DBID &&
              n.EntityMetaType_DBID === tree[i].EntityMetaType_DBID &&
              n.container
          ).length
        ) {
          // make the first value the container
          tree[i]['container'] = true;
        }
        // add the value as a child to the container element
        let value1 = { ...tree[i] };
        value1.values = []; // on removal of this infinite value nesting is created
        tree
          .filter(
            (n) =>
              n.Entity_DBID === tree[i].Entity_DBID &&
              n.EntityMetaType_DBID === tree[i].EntityMetaType_DBID &&
              n.container
          )[0]
          .values.push(value1);

        // if this element is not the container, remove it
        if (!tree[i].container) {
          tree.splice(i, 1);
        }
      }
    }
    // sort value arrays
    tree.forEach((x) => {
      if (x.AllowMultiple) {
        x.values.sort((a, b) =>
          a.EntityMeta_DBID > b.EntityMeta_DBID ? 1 : -1
        );
      }
    });
  };

  createNestedTree = (tree, treeBackup) => {
    for (let i = 0; i < tree.length; i++) {
      if (tree[i].AllowMultiple === 1 && tree[i].values.length > 0) {
        tree[i].values = this.createNestedTree(tree[i].values, treeBackup);
      } else if (
        tree[i].AllowMultiple === 0 ||
        (tree[i].AllowMultiple === 1 && tree[i].values.length === 0)
      ) {
        treeBackup.forEach((c) => {
          if (c.AllowMultiple === 1 && c.values.length > 0) {
            let values = c.values;
            values.forEach((v) => {
              if (
                v.ParentEntityMetaType_DBID === tree[i].EntityMetaType_DBID &&
                v.ParentValue === tree[i].EntityMetaValue
              ) {
                tree[i].children.push(_.cloneDeep(v));
              }
            });
          } else {
            if (
              c.ParentEntityMetaType_DBID === tree[i].EntityMetaType_DBID &&
              c.ParentValue === tree[i].EntityMetaValue
            ) {
              tree[i].children.push(_.cloneDeep(c));
            }
          }
        });

        if (tree[i].children.length > 0) {
          tree[i].children = this.createNestedTree(
            tree[i].children,
            treeBackup
          );
        }
      }
    }
    tree.sort((a, b) => (a.Sort > b.Sort ? 1 : -1));
    return tree;
  };

  openEmail = (item) => {
    window.open(
      'mailto:' + item.EntityMetaValue + '?subject=' + item.EntityName
    );
  };

  openLink = (item) => {
    let link = item.EntityMetaValue;
    if (link.indexOf('https://') === -1 && link.indexOf('http://') === -1) {
      link = 'http://' + link;
    }
    window.open(link);
  };

  render = () => {
    return (
      <>
        <div className="text-center p-2">
          <h4>Field Data</h4>
          <hr />
        </div>
        <div className="field-view">
          <FieldList
            items={this.state.filteredTemplate}
            entity={this.state.currentEntity.EntityName}
            email={this.openEmail}
            link={this.openLink}
            disabled={true}
          />
        </div>
      </>
    );
  };
}

export default FieldView;
