import moment from 'moment';
import React from 'react';
import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import { Button, Form, Modal, Stack } from 'react-bootstrap';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { TextField } from '@mui/material';
import { Trash } from 'react-bootstrap-icons';
import { Api } from './interface';
import { DebounceInput } from 'react-debounce-input';
import { IsRole } from './validator';
import { toast } from 'react-toastify';
import { Hint } from './constants.js';

const localizer = momentLocalizer(moment);

const DragAndDropCalendar = withDragAndDrop(Calendar);

class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      displayDragItemInCell: true,
      editing: false,
      currentEvent: {},
      newEventName: '',
      entities: [],
      currentEntity: {},
      currentEntityName: '',
      eventTypes: [],
      currentEventType: {},
      deleting: false,
    };

    this.textInput = React.createRef();

    this.moveEvent = this.moveEvent.bind(this);
  }

  componentDidMount() {
    Api({ sp: 'getEntities', json: {} }).then((response) => {
      let entities = response;
      Api({ sp: 'getEvents', json: { id: entities[0].Entity_DBID } }).then(
        (response) => {
          this.setState({
            entities: entities,
            currentEntity: entities[0],
            events: response,
          });
        }
      );
    });
    Api({ sp: 'getEventTypes', json: {} }).then((response) => {
      this.setState({ eventTypes: response, currentEventType: response[0] });
    });
  }

  handleDragStart = (event) => {
    this.setState({ draggedEvent: event });
  };

  dragFromOutsideItem = () => {
    return this.state.draggedEvent;
  };

  onDropFromOutside = ({ start, end, allDay }) => {
    const { draggedEvent } = this.state;

    const event = {
      id: draggedEvent.id,
      title: draggedEvent.title,
      start,
      end,
      allDay: allDay,
    };

    this.setState({ draggedEvent: null });
    this.moveEvent({ event, start, end });
  };

  moveEvent = ({ event, start, end, isAllDay: droppedOnAllDaySlot }) => {
    const { events } = this.state;

    let allDay = event.allDay;

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true;
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false;
    }

    const nextEvents = events.map((existingEvent) => {
      return existingEvent.id == event.id
        ? { ...existingEvent, start, end, allDay }
        : existingEvent;
    });

    this.setState({
      events: nextEvents,
    });

    toast.success(event.title + ' successfully updated');

    // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
  };

  resizeEvent = ({ event, start, end }) => {
    const { events } = this.state;

    const nextEvents = events.map((existingEvent) => {
      return existingEvent.id == event.id
        ? { ...existingEvent, start, end }
        : existingEvent;
    });

    this.setState({
      events: nextEvents,
    });

    //alert(`${event.title} was resized to ${start}-${end}`)
  };

  openEvent(event) {
    if (!IsRole(['Equipment Manager', 'ES'])) {
      return;
    }

    if (!event) {
      event = {
        start: new Date(Date.now() + 60 * 1000),
        end: new Date(Date.now() + 61 * 60 * 1000),
        title: '',
      };
    }
    this.setState({ currentEvent: event, editing: true });
  }

  updateEvent(event) {
    console.log(event);
  }

  confirmDelete = (e) => {
    e.stopPropagation();
    this.setState({ deleting: true });
  };

  deleteEvent() {
    Api({
      sp: 'deleteEvent',
      json: { eventId: this.state.currentEvent.id },
    }).then(() => {
      let events = this.state.events.filter(
        (x) => x.id !== this.state.currentEvent.id
      );
      this.setState({ events: events, editing: false, deleting: false });
    });
    toast.success(this.state.currentEvent.title + ' successfully deleted');
  }

  upsertEvent = (e) => {
    let event = e ? e : this.state.currentEvent;
    event.EventType_DBID = event.EventType_DBID
      ? event.EventType_DBID
      : this.state.currentEventType.EventType_DBID;
    Api({
      sp: 'upsertEvent',
      json: {
        title: event.title,
        desc: event.EventDetails,
        entityId: this.state.currentEntity.Entity_DBID,
        typeId: event.EventType_DBID,
        start: event.start,
        end: event.end,
        freq: 0,
        eventId: event.id ? event.id : -1,
      },
    }).then((response) => {
      event.color = this.state.eventTypes.filter(
        (x) => x.EventType_DBID === parseInt(event.EventType_DBID)
      )[0].EventColor;
      let events = this.state.events;
      if (!e) {
        event.id = response[0].Event_DBID;
        events.push(event);
        toast.success(event.title + ' successfully created');
      }
      this.setState({ events: events, editing: e ? true : false });
      toast.success(event.title + ' successfully updated');
    });
  };

  updateEventTitle = (title) => {
    let event = this.state.currentEvent;
    event.title = title;
    this.setState({ currentEvent: event });
    if (event.id) {
      this.upsertEvent(event);
    }
  };

  updateEventDescription = (desc) => {
    let event = this.state.currentEvent;
    event.EventDetails = desc;
    this.setState({ currentEvent: event });
    if (event.id) {
      this.upsertEvent(event);
    }
  };

  updateEventType = (id) => {
    let event = this.state.currentEvent;
    event.EventType_DBID = id;
    event.color = this.state.eventTypes.filter(
      (x) => x.EventType_DBID === parseInt(id)
    )[0].EventColor;
    this.setState({ currentEvent: event });
    if (event.id) {
      this.upsertEvent(event);
    }
  };

  updateEntity = (id) => {
    let entity = this.state.entities.filter(
      (x) => x.Entity_DBID === parseInt(id)
    )[0];
    Api({ sp: 'getEvents', json: { id: entity.Entity_DBID } }).then(
      (response) => {
        this.setState({ currentEntity: entity, events: response });
      }
    );
  };

  // eslint-disable-next-line no-unused-vars
  eventStyleGetter = (event, start, end, isSelected) => {
    var style = {
      backgroundColor: event.color,
      borderRadius: '5px',
      opacity: 0.8,
      color: 'black',
      border: '0px',
      display: 'block',
    };
    return {
      style: style,
    };
  };

  render() {
    // eslint-disable-next-line no-unused-vars
    const { currentEntityName, suggestions } = this.state;
    // eslint-disable-next-line no-unused-vars
    const inputProps = {
      placeholder: 'Select Equipment or Location to manage Events',
      value: currentEntityName,
      onChange: this.onChange,
    };

    return (
      <>
        <Stack className="pb-2" direction="horizontal" gap={3}>
          <h2>Events</h2>
          <Form.Control
            as="select"
            onChange={(e) => this.updateEntity(e.target.value)}
          >
            {this.state.entities.map((opt, i) => (
              <option value={opt.Entity_DBID} key={i}>
                {opt.EntityName}
              </option>
            ))}
          </Form.Control>
        </Stack>

        <DragAndDropCalendar
          selectable
          localizer={localizer}
          events={this.state.events}
          onEventDrop={this.moveEvent}
          onDoubleClickEvent={(e) => this.openEvent(e)}
          onSelecting={(e) => console.log(e)}
          resizable
          style={{ height: '80vh', width: '100%' }}
          onEventResize={this.resizeEvent}
          showMultiDayTimes={true}
          onSelectSlot={() => this.openEvent()}
          defaultView={Views.MONTH}
          defaultDate={new Date()}
          popup={true}
          dragFromOutsideItem={
            this.state.displayDragItemInCell ? this.dragFromOutsideItem : null
          }
          onDropFromOutside={this.onDropFromOutside}
          handleDragStart={this.handleDragStart}
          eventPropGetter={this.eventStyleGetter}
        />

        <Modal
          onEntered={() => this.textInput.current.focus()}
          size="lg"
          show={this.state.editing}
          onHide={() => this.setState({ editing: false })}
          className="entity-modal"
        >
          <Modal.Header closeButton="true">
            <Modal.Title>
              {this.state.currentEvent.id !== undefined ? 'Edit ' : 'Add '}{' '}
              Event
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6>Event Name</h6>
            <DebounceInput
              className="form-control mb-2"
              inputRef={this.textInput}
              placeholder="Event Name"
              minLength={3}
              debounceTimeout={500}
              onChange={(e) => this.updateEventTitle(e.target.value)}
              value={this.state.currentEvent.title}
            />
            <h6>Event Type</h6>
            <Form.Control
              className="mb-2"
              as="select"
              value={this.state.currentEvent.EventType_DBID}
              onChange={(e) => this.updateEventType(e.target.value)}
            >
              {this.state.eventTypes.map((opt, i) => (
                <option value={opt.EventType_DBID} key={i}>
                  {opt.EventType}
                </option>
              ))}
            </Form.Control>
            <h6>Event Details</h6>
            <DebounceInput
              className="form-control mb-2"
              element="textarea"
              placeholder="Event Details"
              minLength={3}
              debounceTimeout={500}
              onChange={(e) => this.updateEventDescription(e.target.value)}
              value={this.state.currentEvent.EventDetails}
            />
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <Stack className="pb-2 pt-2" direction="horizontal" gap={3}>
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Event Start Time"
                  value={this.state.currentEvent.start}
                  onChange={(newValue) => {
                    let event = this.state.currentEvent;
                    event.start = newValue;
                    if (event.end < newValue) {
                      event.end = newValue;
                    }
                    this.setState({ currentEvent: event });
                    if (event.id) {
                      this.upsertEvent(event);
                    }
                  }}
                  minDateTime={
                    this.state.currentEvent.title === '' ? new Date() : null
                  }
                />
                <DateTimePicker
                  renderInput={(props) => <TextField {...props} />}
                  label="Event End Time"
                  value={this.state.currentEvent.end}
                  onChange={(newValue) => {
                    let event = this.state.currentEvent;
                    event.end = newValue;
                    this.setState({ currentEvent: event });
                    if (event.id) {
                      this.upsertEvent(event);
                    }
                  }}
                  minDateTime={new Date(this.state.currentEvent.start)}
                />
              </Stack>
            </LocalizationProvider>
          </Modal.Body>
          <Modal.Footer>
            {this.state.currentEvent.id ? (
              <>
                {this.state.currentEvent.FrequencyDays > 0 ? (
                  <Button variant="outline-primary">Schedule Next Event</Button>
                ) : null}
                <Hint
                  placement="left"
                  delay={{ show: 250, hide: 400 }}
                  title="Click to delete event"
                >
                  <Trash
                    color="red"
                    className="pointer"
                    onClick={(e) => this.confirmDelete(e)}
                    size={18}
                  />
                </Hint>
              </>
            ) : (
              <>
                <Button
                  variant="outline-primary"
                  onClick={() =>
                    this.setState({ editing: false, currentEvent: {} })
                  }
                >
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  onClick={(e) => {
                    e.currentTarget.disabled = true;
                    this.upsertEvent();
                  }}
                  disabled={
                    this.state.currentEvent.title?.length < 3 ? true : false
                  }
                >
                  Save
                </Button>
              </>
            )}
          </Modal.Footer>
        </Modal>

        {/* delete confirmation */}
        <Modal
          size="lg"
          show={this.state.deleting}
          onHide={() => this.setState({ deleting: false })}
          className="entity-modal"
        >
          <Modal.Header closeButton="true">
            <Modal.Title>
              Confirm deletion of {this.state.currentEvent.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={() => this.setState({ deleting: false })}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={(e) => {
                e.currentTarget.disabled = true;
                this.deleteEvent();
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default Events;
