import React, { Component } from 'react';
import { Trash } from 'react-bootstrap-icons';
import { Hint } from './constants.js';
class ImageComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { hover: false };
  }
  render() {
    let url = this.props.url.split('/')[this.props.url.split('/').length - 1];

    if (
      this.props.url === '' ||
      url === 'null' ||
      url.split('.').length === 0
    ) {
      return;
    } else
      return this.props.item.EntityMetaValue ? (
        <a
          className="img-comp pointer"
          onMouseOver={() => this.setState({ hover: true })}
          onMouseOut={() => this.setState({ hover: false })}
        >
          <img
            src={this.props.url}
            id={this.props.id}
            alt={this.props.id}
            className="preview"
            onClick={() => window.open(this.props.url, '_blank')}
          />
          {!this.props.disabled ? (
            <Hint
              placement="left"
              delay={{ show: 250, hide: 400 }}
              title="Click to delete entry"
            >
              <Trash
                color="red"
                onClick={this.props.delete}
                size={18}
                className={this.state.hover ? 'showImgCncl' : 'hideImgCncl'}
              />
            </Hint>
          ) : null}
        </a>
      ) : null;
  }
}

export default ImageComponent;
