import { useEffect, useState, useRef } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { Autocomplete, TextField } from '@mui/material';

const BadgeVerify = (props) => {
  const textInput = useRef(null);
  const [adding, setAdding] = useState(false);
  const [owner, setOwner] = useState({ UserName: 'No User Selected' });
  const [users, setUsers] = useState(props.users);
  const [badge, setBadge] = useState('');
  const [tempBadge, setTempBadge] = useState(false);

  useEffect(() => {
    setUsers(users);
  }, [props]);

  if (!adding) {
    document.addEventListener('click', function () {
      if (textInput.current) {
        textInput.current.focus();
      }
    });
  }

  const debounce = (callback, wait) => {
    let timer;

    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => callback(...args), wait);
    };
  };

  const readCard = (e) => {
    let val = e.target.value;
    if (!val || val === '') {
      return;
    }
    props.verifyBadge(e.target.value);
    // let newVal = "*".repeat(val.length);
    // textInput.current.value = newVal;
    setBadge(val);

    setTimeout(() => {
      if (textInput.current) {
        textInput.current.value = '';
      }
    }, 500);
    if (!users.some((x) => x.Badge.includes(val))) {
      setAdding(true);
    }
  };

  const addOwnerBadge = () => {
    const current = new Date();

    // calculate time difference to add from then till midnight of that current day
    let diff = (24 - current.getHours()) * 60;
    diff = diff - current.getMinutes();

    const addBadgeJSON = {
      badgeUser: owner,
      badge: badge.toString(),
      badgeType: tempBadge ? 1 : 0,
      timeDiff: diff,
    };
    props.addBadge(addBadgeJSON);
  };

  return (
    <>
      <Modal
        show={props.verifying}
        onHide={() => props.closeModal()}
        className="entity-modal"
        size="lg"
      >
        <Modal.Header closeButton="true">
          <Modal.Title>
            {adding ? 'Add New Badge' : 'Verifying Badge'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <br></br>
          <span hidden={adding}>
            <em>Scan the badge on the reader</em>
          </span>
          <Form.Control
            ref={textInput}
            hidden={adding}
            className="badge-input"
            onChange={debounce((event) => readCard(event), 300)}
            placeholder="Scan the badge on the reader"
            autoFocus
          />
          {adding ? (
            <>
              <h6>Select Owner</h6>
              <Autocomplete
                autoHighlight={true}
                className="type-ahead"
                id="parent-entity"
                options={users}
                getOptionLabel={(option) => option.UserName}
                hiddenlabel="true"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Owner"
                  />
                )}
                onChange={(_event, newOwner) => {
                  setOwner(newOwner);
                }}
              />
              <br />
              <h6>Temporary</h6>
              <Form.Check
                className="binary"
                type="checkbox"
                onChange={(e) => {
                  setTempBadge(e.target.checked);
                }}
              />
            </>
          ) : null}
        </Modal.Body>

        <Modal.Footer>
          <div className="outer">
            <div className="confirm">
              <Button
                variant="outline-danger"
                onClick={() => props.closeModal()}
                className="footer-button"
              >
                Cancel
              </Button>
              <Button
                hidden={!adding}
                variant="outline-primary"
                onClick={() => addOwnerBadge()}
                className="footer-button"
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};
export default BadgeVerify;
