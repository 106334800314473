import {
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { CloudUpload, List, QuestionCircle } from 'react-bootstrap-icons';
import { Autocomplete, TextField } from '@mui/material';
import { toast } from 'react-toastify';
import { handleResize } from './common';
import { Api } from './interface';
import { IsRole } from './validator';

export default function ApiDocs() {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [clients, setClients] = useState([]);
  const [client, setClient] = useState('');
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [currentModal, setCurrentModal] = useState(null);
  const [body, setBody] = useState('');
  const [description, setDescription] = useState('');
  const actionOpen = Boolean(actionAnchorEl);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    handleResize();
  });

  useEffect(() => {
    if (!filterText?.length) {
      setFilteredRows(rows);
    } else {
      setFilteredRows(
        rows.filter((o) => {
          return Object.keys(o).some((k) =>
            o[k].toString().toLowerCase().includes(filterText.toLowerCase())
          );
        })
      );
    }
  }, [filterText]);

  const getData = () => {
    Api({
      sp: 'getPublicAPIs',
      json: {},
    }).then((response) => {
      setRows(response);
      setFilteredRows(response);
      let cols = Object.keys(response[0]).map((x) => ({ id: x }));
      cols.push({ id: 'Actions', align: 'center' });
      setColumns(cols);
    });
    Api({
      sp: 'getAPIClients',
      json: {},
    }).then((response) => {
      setClients([...new Set(response.map((x) => x.ClientName))]);
    });
  };

  const handleContextClick = (event, row) => {
    setActionAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleClose = (event) => {
    const type = event.target.dataset.type;
    if (type === 'Request') {
      setCurrentModal('Request');
    }
    if (type === 'Update') {
      setBody(currentRow.Body);
      setDescription(currentRow.Description);
      setCurrentModal('Update');
    }
    setActionAnchorEl(null);
  };

  const closeModal = () => {
    setCurrentModal(null);
    getData();
  };

  const requestAccess = () => {
    Api({
      sp: 'requestMap',
      json: { sp: currentRow.Procedure, clientName: client },
    }).then(() => {
      toast.success('Access requested');
      closeModal();
    });
  };

  const colorText = (text) => {
    let list = text;
    let rx = new RegExp(/(")/);
    list = text.split(rx);
    list.forEach((o, i, a) => {
      if (a[i] === 'token') {
        a[i] = (
          <span key={i} className="keyword-green">
            {a[i]}
          </span>
        );
      } else if (a[i] === 'client') {
        a[i] = (
          <span key={i} className="keyword-blue">
            {a[i]}
          </span>
        );
      }
    });
    return list;
  };

  const updateProcedure = () => {
    Api({
      sp: 'updateAPIDefinition',
      json: { sp: currentRow.Procedure, body: body, description: description },
    }).then(() => {
      toast.success('Procedure definition updated');
      closeModal();
    });
  };

  const validateBody = (body) => {
    try {
      JSON.parse(body);
    } catch (e) {
      return false;
    }
    return true;
  };

  return (
    <div className="main-container">
      <div className="mt-2 d-flex justify-content-between">
        <h2>Public API Documentation</h2>
      </div>
      <Form.Control
        placeholder="Search"
        onChange={(e) => {
          setFilterText(e.target.value);
        }}
      />
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer
          id="tab-container"
          component={Paper}
          // className={dragging ? "tableContainer no-scroll" : "tableContainer"}
          className="tableContainer"
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            padding="none"
          >
            {/* Column Headers */}
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className="table-header"
                  >
                    {column.id}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {/* Table Content */}
            <TableBody>
              {filteredRows.map((row, i) => {
                return (
                  <TableRow key={i} hover>
                    {columns.map((column) => {
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={
                            new Date(row.Expiration) < new Date()
                              ? 'expired-api'
                              : ''
                          }
                        >
                          {column.id === 'Actions' ? (
                            <List
                              size={24}
                              className="pointer row-menu-icon"
                              onClick={(e) => handleContextClick(e, row)}
                            />
                          ) : column.id === 'Body' ? (
                            colorText(row[column.id])
                          ) : (
                            row[column.id]
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Menu
        id="actions-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={actionAnchorEl}
        open={actionOpen}
        onClose={handleClose}
      >
        <MenuItem data-type="Request" onClick={handleClose} disableRipple>
          <QuestionCircle className="context-icon" />
          Request Access
        </MenuItem>
        {IsRole('Dev') ? (
          <MenuItem data-type="Update" onClick={handleClose} disableRipple>
            <CloudUpload className="context-icon" />
            Update
          </MenuItem>
        ) : null}
      </Menu>
      {currentModal === 'Update' ? (
        <Modal
          show={currentModal === 'Update'}
          onHide={() => closeModal()}
          className="entity-modal"
        >
          <Modal.Header closeButton="true">
            <Modal.Title>Update {currentRow?.Procedure}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-box">
              <Form.Control
                placeholder="Body"
                className="mb-2"
                as="textarea"
                value={body}
                rows={5}
                onChange={(e) => {
                  setBody(e.target.value);
                }}
              />
              <Form.Control
                placeholder="Description"
                as="textarea"
                value={description}
                rows={5}
                onChange={(e) => {
                  setDescription(e.target.value);
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={updateProcedure}
              disabled={!validateBody(body)}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
      {currentModal === 'Request' ? (
        <Modal
          show={currentModal === 'Request'}
          onHide={() => closeModal()}
          className="entity-modal"
        >
          <Modal.Header closeButton="true">
            <Modal.Title>Request {currentRow?.Procedure}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-box">
              <Autocomplete
                autoHighlight={true}
                className="type-ahead"
                id="clients"
                options={clients}
                getOptionLabel={(o) => o}
                hiddenlabel="true"
                value={client}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Client"
                  />
                )}
                onChange={(_event, newValue) => {
                  setClient(newValue);
                }}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={requestAccess}
              disabled={!client}
            >
              Request
            </Button>
          </Modal.Footer>
        </Modal>
      ) : null}
    </div>
  );
}
