import React, { Component } from 'react';
import { Api } from './interface';
import Stack from 'react-bootstrap/Stack';
import { DebounceInput } from 'react-debounce-input';

class EntitySearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
    };
  }

  componentDidMount() {}

  search = (val) => {
    Api({ sp: 'searchEntityMeta', json: { val: val } }).then((response) => {
      let items = [];
      let unique = [
        ...new Map(response.map((item, key) => [item[key], item])).values(),
      ];
      unique.forEach((e) => {
        items.push({
          title: e.EntityName,
          id: e.Entity_DBID,
          body: e.EntityMetaType + ': ' + e.EntityMetaValue,
        });
      });
      this.setState({ items: items });
    });
  };

  highlight(text, highlight) {
    // Split on highlight term and include term into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return (
      <span>
        {' '}
        {parts.map((part, i) => (
          <span
            key={i}
            style={
              part.toLowerCase() === highlight.toLowerCase()
                ? { fontWeight: 'bold' }
                : {}
            }
          >
            {part}
          </span>
        ))}{' '}
      </span>
    );
  }

  render() {
    return (
      <div>
        <h2>Search Entities</h2>
        <Stack className="pb-2" direction="horizontal" gap={3}>
          <DebounceInput
            className="form-control"
            placeholder="Search any entity field using at least 4 characters"
            minLength={1}
            debounceTimeout={500}
            autoFocus
            onChange={(e) => this.search(e.target.value)}
          />
        </Stack>

        <div className="content"></div>
      </div>
    );
  }

  const;
}
export default EntitySearch;
