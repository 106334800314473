import { MuiTelInput } from 'mui-tel-input';
import { Autocomplete, TextField } from '@mui/material';
import React, { Component } from 'react';
import { Stack } from 'react-bootstrap';
import { InfoCircle, Trash, PlusCircle } from 'react-bootstrap-icons';
import CollapseExpand from './collapseExpand';
import Form from 'react-bootstrap/Form';
import FieldInput from './fieldInput';
import ImageComponent from './imageComponent';
import { UploadImages } from './upload-files';
import { shortDate } from './date';
import DatePicker from './datePicker';
import { Hint } from './constants.js';
import { oven } from './common.js';

export default class FieldList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showChildren: [],
      imgServer: props.imgServer,
      items: props.items,
    };
    this.date = new Date();
  }

  bake = (g, c, v) => {
    if (typeof g === 'string') {
      return oven(this.props, false, g, c);
    } else {
      return oven(this.props, true, c, v);
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ items: this.props.items });
    }
  }

  openEmail = (item) => {
    window.open('mailto:' + item.EntityMetaValue);
  };

  openLink = (item) => {
    let link = item.EntityMetaValue;
    if (link.indexOf('https://') === -1 && link.indexOf('http://') === -1) {
      link = 'http://' + link;
    }
    window.open(link);
  };

  renderTree = (tree) => {
    return tree.map((item, i) => (
      <div
        key={item.EntityMetaType_DBID + '_' + i}
        style={{ marginLeft: '50px' }}
      >
        <div className="mb-2">
          <h6 className="tree-head">{item.EntityMetaType}</h6>
          {item.DataType === 'URL' ? (
            <span className="child-link" onClick={() => this.openLink(item)}>
              {item.EntityMetaValue}
            </span>
          ) : item.DataType === 'Email' ? (
            <span className="child-link" onClick={() => this.openEmail(item)}>
              {item.EntityMetaValue}
            </span>
          ) : item.DataType === 'Image' ? (
            <ImageComponent
              disabled={true}
              item={item}
              url={
                this.state.imgServer +
                item.EntityMetaValue +
                '?' +
                this.bake(this.props, 'sas')
              }
              id={item.EntityMetaValue}
            />
          ) : (
            <span className="tree-child">
              {item.EntityMetaValue + (item.UnitShort ? item.UnitShort : '')}
            </span>
          )}
        </div>
        {item.children && this.renderTree(item.children)}
      </div>
    ));
  };

  remove = (item) => {
    this.props.remove(item);
  };

  update = (newValue, item) => {
    this.props.update(newValue || '', item);
    item.EntityMetaValue = newValue || '';
    this.setState({ items: this.props.items });
  };

  Field = (obj) => {
    let item = obj.item;
    if (item.DataType === 'Image') {
      return (
        <ImageComponent
          disabled={this.props.disabled}
          item={item}
          url={
            this.state.imgServer +
            item.EntityMetaValue +
            '?' +
            this.bake(this.props, 'sas')
          }
          id={item.EntityMetaValue}
          delete={() => this.props.update('', item)}
        />
      );
    }
    return (
      <>
        <Stack
          className="d-flex justify-space-between w-100 pb-2 pr-2"
          direction="horizontal"
          gap={3}
        >
          <div className="w-100">
            {item.DataType === 'Group' ||
            item.DataType === 'Multiple Choice' ? (
              this.props.disabled ? (
                <span className="disabled-meta">{item.EntityMetaValue}</span>
              ) : (
                <Autocomplete
                  autoHighlight={true}
                  className="type-ahead"
                  id="meta-potions"
                  value={
                    item.EntityMetaValue?.length
                      ? { EntityMetaOption: item.EntityMetaValue }
                      : { EntityMetaOption: '' }
                  }
                  options={item.options}
                  getOptionLabel={(option) => option.EntityMetaOption}
                  hiddenlabel="true"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select/Type"
                    />
                  )}
                  onChange={(_event, newValue) => {
                    this.update(newValue?.EntityMetaOption, item);
                  }}
                />
              )
            ) : item.DataType === 'Date' ? (
              this.props.disabled ? (
                <span className="disabled-meta">
                  {shortDate(item.EntityMetaValue)}
                </span>
              ) : (
                <DatePicker item={item} update={this.props.update} />
              )
            ) : item.DataType === 'Phone Number' ? (
              this.props.disabled ? (
                <span className="disabled-meta">
                  {shortDate(item.EntityMetaValue)}
                </span>
              ) : (
                <div>
                  <MuiTelInput
                    className="phone-number"
                    focusOnSelectCountry
                    preferredCountries={['US']}
                    value={item.EntityMetaValue ? item.EntityMetaValue : '+1'}
                    onChange={(value, meta) => {
                      item.reason = meta.reason;
                      if (!item.oldVal) {
                        item.oldVal = item.EntityMetaValue;
                      }
                      item.EntityMetaValue = value;

                      this.setState({ items: this.props.items });
                    }}
                    onBlur={(e) => {
                      // only update if the onBlur is triggered by an input
                      // and there is a mismatch between newVal and current
                      if (item.oldVal && item.reason === 'input') {
                        let newVal = item.EntityMetaValue;
                        item.EntityMetaValue = item.oldVal;
                        this.props.update(newVal, item);

                        // delete dynamically added properties
                        delete item.oldVal;
                        delete item.reason;
                      } else if (
                        item.reason === 'country' &&
                        e.target.value === item.EntityMetaValue
                      ) {
                        item.EntityMetaValue = item.oldVal;
                        this.setState({ items: this.props.items });
                      }
                    }}
                  />
                </div>
              )
            ) : item.DataType === 'Binary' ? (
              <>
                <Form.Check
                  className="binary"
                  type="checkbox"
                  checked={parseInt(item.EntityMetaValue ? 1 : 0)}
                  onChange={(e) => {
                    this.updateBinary(e, item);
                  }}
                  disabled={this.props.disabled}
                />
              </>
            ) : item.DataType === 'User Lookup' ? (
              <>
                {this.props.disabled ? (
                  <span className="disabled-meta">{item.EntityMetaValue}</span>
                ) : (
                  <Autocomplete
                    autoHighlight={true}
                    disablePortal
                    className="type-ahead"
                    id="user-lookup"
                    options={this.props.users}
                    value={
                      item.EntityMetaValue
                        ? this.props.users.filter(
                            (x) =>
                              x.OID === item.EntityMetaValue ||
                              x.Idsid === item.EntityMetaValue ||
                              x.UserName === item.EntityMetaValue
                          )[0]
                        : { UserName: '' }
                    }
                    getOptionLabel={(option) => {
                      return option.UserName;
                    }}
                    hiddenlabel="true"
                    onChange={(event, newValue) => {
                      this.update(newValue?.UserName, item);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select/Type"
                      />
                    )}
                  />
                )}
              </>
            ) : (
              <FieldInput
                item={item}
                entity={this.props.entity}
                update={this.props.update}
                disabled={this.props.disabled}
                imgServer={this.state.imgServer}
              />
            )}
          </div>
          {item.children &&
          item.children.length > 0 &&
          item.EntityMetaValue !== null ? (
            <CollapseExpand id={item.EntityMeta_DBID} />
          ) : null}
          {item.AllowMultiple &&
          !this.props.disabled &&
          // remove trash icon if only one entry
          this.props.items.filter(
            (x) => x.EntityMetaType_DBID === item.EntityMetaType_DBID
          ).length > 1 &&
          item.DataType !== 'Image' ? (
            <Trash
              color="red"
              className="pointer"
              onClick={() => this.remove(item)}
              size={18}
            />
          ) : null}
        </Stack>
        {item.children &&
        item.children.length > 0 &&
        item.EntityMetaValue !== null ? (
          <div id={item.EntityMeta_DBID} className="collapse">
            {this.renderTree(item.children)}
          </div>
        ) : null}
      </>
    );
  };

  showTitle = (item, multi) => {
    if (item.AllowMultiple) {
      const index = item.EntityMeta_DBID
        ? multi.findIndex((x) => x.EntityMeta_DBID === item.EntityMeta_DBID)
        : multi.findIndex(
            (x) => x.EntityGroupMeta_DBID === item.EntityGroupMeta_DBID // For the case of Group type
          );
      const isFirst = index === 0;
      return isFirst;
    } else {
      return true;
    }
  };

  render() {
    let types = [];
    return this.state.items.map((item, i) => {
      let multi = [];
      if (item.AllowMultiple) {
        multi = this.state.items.filter(
          (x) => x.EntityMetaType_DBID === item.EntityMetaType_DBID
        );
      }
      let infoIcon;
      infoIcon =
        item.Description && item.Description.length > 0 ? (
          <Hint
            placement="right"
            key={i}
            delay={{ show: 250, hide: 400 }}
            title={item.Description}
          >
            <sup className="infoIcon">
              <InfoCircle color="grey" size={12} />
            </sup>
          </Hint>
        ) : null;
      if (item.DataType === 'Image') {
        if (!types.includes(item.EntityMetaType_DBID)) {
          types.push(item.EntityMetaType_DBID);
          return (
            <div key={i}>
              <div className="d-flex justify-space-between w-100">
                <h6>
                  {item.EntityMetaType}{' '}
                  {item.UnitShort ? '(' + item.UnitShort + ')' : ''}
                  {infoIcon}
                </h6>
              </div>
              {item.AllowMultiple ? (
                multi.map((val, i) => {
                  return <this.Field key={i} item={val} />;
                })
              ) : (
                <this.Field key={i} item={item} />
              )}
              {this.props.disabled ||
              (!item.AllowMultiple && item.EntityMetaValue !== null) ? null : (
                <UploadImages
                  item={item}
                  entity={this.props.entity}
                  update={this.props.update}
                  add={this.props.addEntry}
                  imgServer={this.state.imgServer}
                  sas={this.bake(this.props, 'sas')}
                />
              )}
            </div>
          );
        }
      } else
        return (
          <div key={i} className="mb-2">
            <Stack className="pt-2" direction="horizontal" gap={3}>
              {/* check if field label should be shown */}
              {this.showTitle(item, multi) ? (
                <div className="d-flex justify-space-between w-100">
                  <h6>
                    {item.EntityMetaType}{' '}
                    {item.UnitShort ? '(' + item.UnitShort + ')' : ''}
                    {infoIcon}
                  </h6>
                  {item.AllowMultiple &&
                  item.DataType !== 'Image' &&
                  !multi.some(
                    (x) =>
                      x.EntityMetaValue === '' || x.EntityMetaValue === null
                  ) &&
                  !this.props.disabled ? (
                    <Hint
                      placement="left"
                      delay={{ show: 250, hide: 400 }}
                      title="Click to add entry"
                    >
                      <PlusCircle
                        className="ms-auto pointer"
                        color="blue"
                        onClick={() => this.props.addEntry(item)}
                        size={18}
                      />
                    </Hint>
                  ) : null}
                </div>
              ) : null}
            </Stack>
            <this.Field item={item} />
          </div>
        );
    });
  }

  updateBinary(val, item) {
    this.props.update(val.target.checked ? 1 : 0, item);
  }
}
