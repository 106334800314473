import { toast } from 'react-toastify';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import { Form } from 'react-bootstrap';
import React, { Component } from 'react';
import 'react-sortable-tree/style.css';
import SortableTreeWithoutDndContext from 'react-sortable-tree';
import { Api } from './interface';
import { Link } from 'react-bootstrap-icons';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Hint } from './constants.js';
import NoAccess from './noAccess';
import { IsRole } from './validator';

// constants for the autocomplete checkbox
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      nodes: [],
      links: [],
      showLinks: false,
      isLinking: false,
      searchString: '',
      searchFocusIndex: 0,
      searchFoundCount: null,
      nodeMoved: false,
      treeData: [],
      nodeLinksDict: {},
    };
  }

  createTree = (tree) => {
    tree.forEach((x) => {
      x['title'] = x.EntityName;
      x['subtitle'] = x.Level + ': ' + x.EntityType;
      x['children'] = [];
      x['expanded'] = false;
    });

    this.makeTree(tree);

    // sort alphabetically
    tree.sort((a, b) => (a.Entity > b.Entity ? -1 : 1));

    return tree;
  };

  getParentById = (arr, itemId) =>
    arr.reduce((a, item) => {
      if (a) return a;
      if (item.Entity_DBID === itemId) return item;
      if (item['children']) return this.getParentById(item['children'], itemId);
    }, null);

  makeTree = (tree) => {
    let children = [];
    // eslint-disable-next-line no-unused-vars
    tree.forEach((x, i) => {
      if (x.ParentEntity_DBID !== 0) {
        let parent = this.getParentById(tree, x.ParentEntity_DBID);
        parent.children.push(x);
        children.push(x);
      }
    });

    // remove all children from root
    children.forEach((x) => {
      for (let i = tree.length - 1; i >= 0; i--) {
        if (tree[i].Entity_DBID === x.Entity_DBID) {
          tree.splice(i, 1);
        }
      }
    });
  };

  updateHierarchy = (event, treeData) => {
    this.setState({ isLinking: false });
    let list = this.flattenTree(treeData, 0);
    let item = list.filter((x) => x.id === event.node.Entity_DBID)[0];
    Api({
      sp: 'updateHierarchy',
      json: { entityId: item.id, parentId: item.parentId, items: list },
    }).then(() => {
      this.setState({ treeData: treeData });
    });
  };

  flattenTree = (data, id) => {
    let list = [];
    data.forEach((d) => {
      list.push({ name: d.title, id: d.Entity_DBID, parentId: id });
      if (d.children.length) {
        list.push(...this.flattenTree(d.children, d.Entity_DBID));
      }
    });
    return list;
  };

  // eslint-disable-next-line no-unused-vars
  getHierarchy = () => {
    Api({ sp: 'getEntityHierarchy', json: {} }).then((response) => {
      this.setState({ treeData: this.createTree(response) });
    });
  };
  dropAllowed = (data) => {
    return !data.nextParent || data.node.Sort >= data.nextParent?.Sort;
  };

  displayLinks = (e, node) => {
    // don't pull the links if they've already been pulled down
    if (
      this.state.nodes.length == 0 ||
      (this.state.nodes.length &&
        this.state.nodes[0].ParentEntity_DBID !== node.node.Entity_DBID)
    ) {
      Api({ sp: 'getLinksByEntity', json: { id: node.node.Entity_DBID } }).then(
        (response) => {
          let nodes = node.node.children;
          let nodeLinksDictTemp = {};

          // populate the node-to-links dictionary based on the links pulled from the db
          nodes.forEach((node) => {
            nodeLinksDictTemp[node.Entity_DBID] = [];
          });

          response.forEach((link) => {
            nodeLinksDictTemp[link.start].push(
              nodes.find((node) => node.Entity_DBID === link.end)
            );

            nodeLinksDictTemp[link.end].push(
              nodes.find((node) => node.Entity_DBID === link.start)
            );
          });
          this.setState({
            currentNode: node.node,
            nodeLinksDict: nodeLinksDictTemp,
            links: response,
            nodes: nodes,
            showLinks: true,
          });
        }
      );
    } else {
      this.setState({ showLinks: !this.state.showLinks });
    }
  };

  updateLink = (node1, node2) => {
    let links = this.state.links;

    // if the nodes already have a relationship, delete the links
    if (
      links.filter(
        (x) => x.start === node1.Entity_DBID && x.end === node2.Entity_DBID
      ).length ||
      links.filter(
        (x) => x.end === node1.Entity_DBID && x.start === node2.Entity_DBID
      ).length
    ) {
      links = links.filter(
        (x) =>
          !(x.start === node1.Entity_DBID && x.end === node2.Entity_DBID) &&
          !(x.end === node1.Entity_DBID && x.start === node2.Entity_DBID)
      );
    } else {
      // otherwise, add a new link
      links.push({
        start: node1.Entity_DBID,
        end: node2.Entity_DBID,
      });
    }
    this.updateLinkAPI({
      links: links,
      link: { start: node1, end: node2 },
    });
  };

  updateLinkAPI = (linkObj) => {
    Api({
      sp: 'toggleLink',
      json: {
        start: linkObj.link.start.Entity_DBID,
        end: linkObj.link.end.Entity_DBID,
      },
    }).then((response) => {
      let links = linkObj.links;
      if (response.length) {
        links.push(response[0]);
      } else {
        links = links.filter(
          (x) =>
            !(
              x.start === linkObj.link.start.Entity_DBID &&
              x.end === linkObj.link.end.Entity_DBID
            ) &&
            !(
              x.end === linkObj.link.start.Entity_DBID &&
              x.start === linkObj.link.end.Entity_DBID
            )
        );
      }
      this.setState({ links: links });
      toast.success(
        `Updated link between '${linkObj.link.start.EntityName}' & '${linkObj.link.end.EntityName}'`
      );
    });
  };

  componentDidMount = () => {
    this.getHierarchy();
  };

  render = () => {
    const { searchString, searchFocusIndex, searchFoundCount } = this.state;

    // Case insensitive search of `node.title`
    const customSearchMethod = ({ node, searchQuery }) =>
      searchQuery &&
      node.title.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1;

    const selectPrevMatch = () =>
      this.setState({
        searchFocusIndex:
          searchFocusIndex !== null
            ? (searchFoundCount + searchFocusIndex - 1) % searchFoundCount
            : searchFoundCount - 1,
      });

    const selectNextMatch = () =>
      this.setState({
        searchFocusIndex:
          searchFocusIndex !== null
            ? (searchFocusIndex + 1) % searchFoundCount
            : 0,
      });
    return IsRole(
      ['Admin', 'Equipment Manager', 'Material Manager', 'Dev'],
      this.props.roles
    ) ? (
      <div className="main-container mt-2">
        {/* Page Title */}
        <h2>Hierarchy</h2>

        {/* Search Bar */}
        <form
          className="hierarchy-search"
          onSubmit={(event) => {
            event.preventDefault();
          }}
        >
          <Form.Control
            type="text"
            placeholder="Search"
            style={{ fontSize: '1rem' }}
            value={searchString}
            onChange={(event) =>
              this.setState({ searchString: event.target.value })
            }
            autoFocus
          />

          <IconButton
            type="button"
            disabled={!searchFoundCount}
            onClick={selectPrevMatch}
          >
            <ArrowBackIosNewIcon />
          </IconButton>

          <IconButton
            type="submit"
            disabled={!searchFoundCount}
            onClick={selectNextMatch}
          >
            <ArrowForwardIosIcon />
          </IconButton>

          <span>
            &nbsp;
            {searchFoundCount > 0 ? searchFocusIndex + 1 : 0}
            &nbsp;/&nbsp;
            {searchFoundCount || 0}
          </span>
        </form>

        <div className="hierarchy-view">
          <div>
            <div className="hierarchy-container">
              <SortableTreeWithoutDndContext
                canDrag={false}
                treeData={this.state.treeData}
                canDrop={false} //(treeData) => this.dropAllowed(treeData)}
                searchMethod={customSearchMethod}
                searchQuery={searchString}
                searchFocusOffset={searchFocusIndex}
                onMoveNode={(event) =>
                  this.updateHierarchy(event, event.treeData)
                }
                searchFinishCallback={(matches) =>
                  this.setState({
                    searchFoundCount: matches.length,
                    searchFocusIndex:
                      matches.length > 0
                        ? searchFocusIndex % matches.length
                        : 0,
                  })
                }
                onChange={(treeData) => {
                  this.setState({ treeData });
                }}
                generateNodeProps={(extendedNode) => ({
                  title: extendedNode.node.title,
                  buttons:
                    extendedNode.node.children.length > 1
                      ? [
                          // eslint-disable-next-line react/jsx-key
                          <Hint
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            title="Click to view links for children"
                          >
                            <Link
                              className="pointer m-2"
                              color="blue"
                              onClick={(e) =>
                                this.displayLinks(e, extendedNode)
                              }
                              size={22}
                            />
                          </Hint>,
                        ]
                      : [],
                })}
              />
            </div>
          </div>
          <div className="links-panel">
            <h4>
              {this.state.showLinks ? this.state.currentNode.EntityName : ''}
            </h4>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className={'hierarchy-table-header-entities'}>
                      Entities
                    </TableCell>
                    <TableCell className={'hierarchy-table-header-links'}>
                      Links
                    </TableCell>
                  </TableRow>
                </TableHead>
                {this.state.showLinks ? (
                  <TableBody>
                    {this.state.nodes.map((row) => (
                      <TableRow
                        key={row.Entity_DBID}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ width: 10 }}
                        >
                          {row.EntityName}
                        </TableCell>
                        <TableCell>
                          <Autocomplete
                            autoHighlight={true}
                            multiple
                            size="small"
                            options={this.state.nodes.filter(
                              (node) => node.Entity_DBID !== row.Entity_DBID
                            )}
                            disableCloseOnSelect
                            getOptionLabel={(option) => option.title}
                            value={this.state.nodeLinksDict[row.Entity_DBID]}
                            renderOption={(props, option, { selected }) => (
                              <li {...props}>
                                <Checkbox
                                  icon={icon}
                                  checkedIcon={checkedIcon}
                                  style={{ marginRight: 8 }}
                                  checked={selected}
                                />
                                {option.title}
                              </li>
                            )}
                            onChange={(event, values, reason, detail) => {
                              let nodeLinksDictTemp = this.state.nodeLinksDict;
                              nodeLinksDictTemp[row.Entity_DBID] = values;
                              let value = detail.option;
                              let index = nodeLinksDictTemp[
                                value.Entity_DBID
                              ].findIndex(
                                (x) => x.Entity_DBID == row.Entity_DBID
                              );

                              if (reason === 'removeOption') {
                                // remove item from dictionary list and trigger link delete
                                nodeLinksDictTemp[value.Entity_DBID].splice(
                                  index,
                                  index >= 0 ? 1 : 0
                                );
                                this.updateLink(row, value);
                              } else if (reason === 'selectOption') {
                                // add to dictionary if it doesn't exist and trigger link add
                                if (index === -1)
                                  nodeLinksDictTemp[value.Entity_DBID].push(
                                    row
                                  );
                                this.updateLink(row, value);
                              }

                              // update the node links dictionary
                              this.setState({
                                nodeLinksDict: nodeLinksDictTemp,
                              });
                            }}
                            // onBlur={}
                            renderInput={(params) => (
                              <TextField {...params} label="Linked Nodes" />
                            )}
                          />
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                ) : (
                  // create an empty body if no links are selected
                  <TableBody>
                    <TableRow
                      key={0}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell></TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    ) : (
      <NoAccess />
    );
  };
}
