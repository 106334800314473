import React, { Component } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { Api, Sas } from './interface';
import Stack from 'react-bootstrap/Stack';
import { ButtonGroup } from 'react-bootstrap';
// import { Files, Trash } from "react-bootstrap-icons";
import FieldList from './fieldList';
import { IsRole, IsDuplicate } from './validator';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import debounce from 'lodash/debounce';
import {
  Autocomplete,
  Box,
  Chip,
  TableSortLabel,
  TextField,
} from '@mui/material';
import { ROWSPERPAGE } from './constants';
import { toast } from 'react-toastify';
import { Trash, Filter } from 'react-bootstrap-icons';
import { getComparator, handleResize, toastMsg, oven } from './common';
import { datePickerToShortDate } from './date';
import { validateType } from './validator';
import { Hint } from './constants.js';
import EntityBulkUpload from './entityBulkUpload';
import NoAccess from './noAccess';

class MetaGroups extends Component {
  constructor(props) {
    super(props);
    this.tableCellHover = [
      {
        '&:hover': {
          backgroundColor: '#BDBDBD',
        },
      },
    ];

    this.textInput = React.createRef();
    this.bottomRef = React.createRef();
    this.selectedEntityType;
    // debounce method to fill dropdown list
    this.getOptionsDelayed = debounce(this.getOptionsDelayed.bind(this), 700);
    this.state = {
      imgServer: this.bake(true, 'imgServer'),
      savedFields: this.bake('fields') ?? [],
      savedFilters: this.bake('filters') ?? [],
      selectedFilters: this.bake('filters') ?? [],
      rowsPerPage: this.bake('rowsPerPage')
        ? Number(this.bake('rowsPerPage'))
        : 15,
      orderBy: this.bake('orderBy') ?? 'EntityMetaValue',
      order: this.bake('order') ?? 'asc',
      searchText: this.bake('searchText') ?? '',

      items: [],
      rows: [],
      filteredRows: [],
      metaTypes: [],
      templates: [],
      template: [],
      newFieldId: -1,
      currentItem: {},
      currentTitle: '',
      newMetaValue: '',
      newEntityTypeId: -1,
      newEntityTypes: [],
      newMetaTypeId: -1,
      selectedMetaType: [], // adding it in the state as it gets its initial value after componentDidMount gets called
      entityTypes: [],
      currentFields: [],
      groups: [],
      rawTemplate: [],
      isOpen: false,
      editing: false,
      deleting: false,
      currentMetaTypes: [],
      sas: '',
      page: 0,
      applyFilter: false,
      filterText: '',
      filterColumn: [],
      filterOpts: [],
      filterSelected: [],
      currentGroupFilterText: '',
      columns: [
        { id: 'EntityMetaValue', label: 'Value', minWidth: 100 },
        { id: 'EntityMetaType', label: 'Field', minWidth: 100 },
        { id: 'EntityType', label: 'Template Type', minWidth: 100 },
      ],
      radios: [{ name: 'Vertical', icon: <Filter size={24} /> }],
      advanced: true,
      advancedLayout: undefined,
      isOpenSearch: false,
      valueList: [],

      deleteCol: {
        id: 'Delete',
        value: null,
        label: 'Delete',
        align: 'center',
        isFixed: true,
      },

      fixedColumns: [],
      vals: [],
      selectedFields: [],

      // TODO: Check if you can remove these unused state vars & corresponding code
      filterTerm: '',
      uploaded: [],
      duplicates: [],
      spinner: false,
    };
  }

  bake = (g, c, v) => {
    if (typeof g === 'string') {
      return oven({ level: 'groups' }, false, g, c);
    } else {
      return oven({ level: 'groups' }, true, c, v);
    }
  };

  componentDidMount() {
    Sas().then((response) => {
      this.setState({ sas: response.SAS_STRING });
    });

    this.loadInitialValues();
  }

  componentDidUpdate() {
    handleResize();
  }

  setColumns = (cols) => {
    if (IsRole(['Equipment Manager', 'Material Manager', 'Admin'])) {
      cols.push(this.state.deleteCol);
    }
    return cols;
  };

  renameGroup = (val) => {
    val = val.trim();
    let oldVal = this.state.currentItem.EntityMetaValue;
    if (val === oldVal) {
      this.setState({ renaming: false });
      return;
    }
    if (!IsDuplicate('Group', val, this.state.groups, oldVal)) {
      Api({
        sp: 'renameMetaGroup',
        json: {
          groupId: this.state.currentItem.EntityGroup_DBID,
          val: val,
        },
      }).then(() => {
        let updatedCurrentItem = this.state.currentItem;
        updatedCurrentItem.EntityMetaValue = val;
        let rows = this.state.rows.map((x) =>
          x.EntityGroup_DBID === updatedCurrentItem.EntityGroup_DBID
            ? {
                ...x,
                EntityMetaValue: val,
              }
            : x
        );
        this.setState({
          currentItem: updatedCurrentItem,
          rows: rows,
          groups: rows,
        });
        toast.success(oldVal + ' renamed to ' + val);
        this.endRenaming();
      });
    } else {
      this.endRenaming();
    }
  };

  startRenaming = () => {
    this.setState({ renaming: true });
  };

  endRenaming = () => {
    this.setState({ renaming: false });
  };

  updateLayout = (name) => {
    this.setState({
      advancedLayout: name === this.state.advancedLayout ? undefined : name,
      isOpenSearch: name === 'Modal',
    });
  };

  showModal = () => {
    this.selectedEntityType = this.state.entityTypes[0];
    this.setState({
      newEntityTypeId: this.state.entityTypes[0].EntityType_DBID,
    });
    Api({ sp: 'getMetaGroups', json: {} }).then((response) => {
      this.setState({
        metaTypes: response,
        newMetaTypeId: response[0].EntityMetaType_DBID,
        isOpen: true,
        newMetaValue: [],
        newEntityTypes: [],
        selectedMetaType: [],
      });
    });
  };

  hideModal = () => {
    this.setState({ isOpen: false });
  };

  hideBulkModal = () => {
    this.setState({ bulkUpload: false, uploaded: [], duplicates: [] });
  };

  startEdit = () => {
    this.setState({ editing: true });
  };

  endEdit = () => {
    setTimeout(
      () =>
        this.setState({
          editing: false,
          template: [],
          currentGroupFilterText: '',
        }),
      100
    );
  };

  loadInitialValues = () => {
    Api({
      sp: 'getMetaTypesGroups',
      json: {},
    }).then((response) => {
      this.setState({ fields: response });
    });

    Api({
      sp: 'getUsers',
      json: {},
    }).then((response) => {
      this.setState({ users: response });
    });

    Api({ sp: 'getEntityTypes', json: {} }).then((response) => {
      this.setState({
        entityTypes: response,
        newEntityTypeId: response[0].EntityType_DBID,
      });
      this.selectedEntityType = response[0];
    });

    Api({ sp: 'getEntityGroups', json: {} }).then((response) => {
      response.forEach((x) => {
        // add options
        x.options = [];
        // convert entity type to array
        x['EntityType'] = x.EntityType?.split(',');
        // convert entity type id to array of ints
        x['EntityType_DBID'] = x.EntityType_DBID?.split(',').map((s) =>
          Number(s)
        );
      });
      this.setState({
        groups: response,
        groupParents: response,
        rows: response,
        filteredRows: response,
        isOpen: false,
        editing: false,
      });

      Api({
        sp: 'getFixedColumnsByLevel',
        json: { level: 'Group' },
      }).then((clmns) => {
        let cols = [];
        clmns.forEach((c) => {
          cols.push({
            id: c.ColumnName ?? c.ColumnHeader,
            value: c.EntityMetaType_DBID,
            label: c.ColumnHeader,
            isFixed: true,
          });
        });
        // add fields saved to cookie
        this.state.savedFields.forEach((f) => cols.push(f));
        cols = this.setColumns(cols);
        this.setState({
          columns: cols,
          fixedColumns: cols.filter((c) => c.isFixed),
          selectedFields: cols,
        });

        // this.setFields(cols, response);
        this.setFilters(this.state.savedFilters, { rows: response });
      });

      handleResize();

      this.setState({
        advancedLayout:
          this.state.savedFilters?.length || this.state.savedFields?.length
            ? 'Vertical'
            : undefined,
      });
    });
  };

  addHashEntry(dict, key, id, vals) {
    dict[key] = {};
    dict[key]['id'] = id;
    dict[key]['name'] = key;
    dict[key]['vals'] = vals;
  }

  mergeFilters(filters) {
    const output = Object.values(
      filters.reduce((acc, cur) => {
        acc[cur.key] = acc[cur.key] || {
          key: cur.key,
          value: [],
        };
        acc[cur.key].value.push(cur.value);
        return acc;
      }, {})
    );
    return output;
  }

  setFilters = (filters, filterObj) => {
    let rows = Object.prototype.hasOwnProperty.call(filterObj, 'rows')
      ? filterObj.rows
      : this.state.rows;

    let filterText = Object.prototype.hasOwnProperty.call(filterObj, 'text')
      ? filterObj.text
      : this.state.searchText;

    let mergedFilters = this.mergeFilters(filters);

    let filteredRows = rows.filter((f) => {
      const vals = Object.entries(f)
        .filter((e) =>
          this.state.columns.some((c) => (c.id ?? c.label) === e[0])
        )
        .map((m) => `${m[1]}`.toLowerCase());
      return (
        vals.some((x) => x.includes(filterText.toLowerCase())) ||
        filterText === ''
      );
    });

    if (mergedFilters.length) {
      filteredRows = rows
        .filter((row) => {
          let match = !mergedFilters.length;
          let excluded = false;
          if (!match) {
            mergedFilters.forEach((filter) => {
              if (
                filter.value.includes('(Blank)') &&
                (!row[filter.key] ||
                  row[filter.key].toString().trim().length === 0)
              ) {
                row.matched = true;
                match = true;
              } else {
                // convert value to array if string
                let rowVal = Array.isArray(row[filter.key])
                  ? row[filter.key]
                  : [row[filter.key]];
                if (rowVal.some((r) => filter.value.includes(r))) {
                  row.matched = true;
                  match = true;
                } else {
                  excluded = true;
                }
              }
            });
          }
          return excluded ? false : match;
        })
        .filter((f) => {
          const vals = Object.entries(f)
            .filter((e) =>
              this.state.columns.some((c) => (c.id ?? c.label) === e[0])
            )
            .map((m) => `${m[1]}`.toLowerCase());

          return (
            vals.some((x) => x.includes(filterText.toLowerCase())) ||
            filterText === ''
          );
        });
    }

    this.bake('filters', filters);
    this.setState({
      rows: rows,
      filteredRows: filteredRows,
      selectedFilters: filters,
      savedFilters: filters,
      searchText: filterText,
      page: 0,
    });
  };

  addGroup = () => {
    if (
      !IsDuplicate('Group', this.state.newMetaValue.trim(), this.state.groups)
    ) {
      Api({
        sp: 'addEntityGroup',
        json: {
          entityTypeIds: JSON.stringify(
            this.state.newEntityTypes.map((t) => t.EntityType_DBID)
          ),
          metaTypeId: this.state.newMetaTypeId,
          val: this.state.newMetaValue,
        },
      }).then((res) => {
        toast.success(this.state.newMetaValue + ' created successfully');

        res.forEach((x) => {
          // add options
          x.options = [];
          // convert entity type to array
          x['EntityType'] = x.EntityType.split(',');
          // convert entity type id to array of ints
          x['EntityType_DBID'] = x.EntityType_DBID.split(',').map((s) =>
            Number(s)
          );
        });
        this.setState({
          isOpen: false,
          editing: false,
        });

        let rows = [...this.state.rows, ...res];

        this.setFilters(this.state.selectedFilters, {
          rows: rows,
        });

        this.setState({ rows: rows, groups: rows });

        handleResize();
      });
    }
  };

  cardClick = (item) => {
    Api({
      sp: 'getGroupTemplate',
      json: {
        groupId: item.EntityGroup_DBID,
      },
    }).then((template) => {
      Api({
        sp: 'getMetaOptionsByGroup',
        json: { groupId: item.EntityGroup_DBID },
      }).then((options) => {
        template.forEach((x) => {
          x.options = x.options || [];
          x.values = x.values || [];
          options.forEach((o) => {
            if (x.EntityMetaType_DBID === o.EntityMetaType_DBID) {
              x.options.push(o);
            }
          });
        });

        // filter down the template fields by removing duplicates
        // using EntityMetaType_DBID for reference
        template = template.filter(
          (v, i, a) =>
            a.findIndex(
              (v2) => v2.EntityMetaType_DBID === v.EntityMetaType_DBID
            ) === i || v.AllowMultiple
        );

        //this.createValueArrays(template);
        this.setState({
          template: template,
          filteredTemplate: template,
          editing: true,
          currentItem: item,
        });
      });
    });
  };

  updateMeta = (val, item) => {
    val =
      item.DataType === 'Date' || item.DataType === 'Binary' ? val : val.trim();
    // do not save if value didn't change or value is blank/null and previous is blank/null
    if (
      item.EntityMetaValue === val ||
      (item.EntityMetaValue === null && val === '') ||
      (item.EntityMetaValue === '' && val === null)
    ) {
      return;
    }

    if (
      item.DataType === 'Date' &&
      datePickerToShortDate(item.EntityMetaValue) === datePickerToShortDate(val)
    ) {
      return; // new and old date values are the same for the meta type
    }

    if (validateType(val, item)) {
      val = item.DataType === 'Binary' ? (val === 0 ? '' : val) : val;
      if (item.DataType === 'Image' && val !== '') {
        item.EntityGroupMeta_DBID = -1;
      }

      Api({
        sp: 'updateGroupChild',
        json: {
          groupId: this.state.currentItem.EntityGroup_DBID,
          fieldId: item.EntityMetaType_DBID,
          id: item.EntityGroupMeta_DBID,
          val: val,
        },
      }).then(() => {
        let stateTemplate = this.state.template;
        stateTemplate.forEach((element) => {
          if (element.EntityMetaType_DBID === item.EntityMetaType_DBID) {
            element.EntityMetaValue = val;
          }
        });
        this.setState({
          template: stateTemplate,
          filteredTemplate: stateTemplate,
        });
        if (item.DataType === 'Date' && val !== null && val !== '') {
          val = datePickerToShortDate(val);
        }
        this.updateTable(item, val);

        toast.success(
          item.EntityMetaType + ' updated to ' + toastMsg(val, item)
        );
      });
    }
  };

  updateTable = (item, val) => {
    let rows = this.state.rows;
    let filteredRows = this.state.filteredRows;

    rows.forEach((r) => {
      if (r.EntityGroup_DBID === this.state.currentItem.EntityGroup_DBID) {
        r[item.EntityMetaType] = [val];
      }
    });

    filteredRows.forEach((r) => {
      if (r.EntityGroup_DBID === this.state.currentItem.EntityGroup_DBID) {
        r[item.EntityMetaType] = [val];
      }
    });

    this.setState({ rows: rows, filteredRows: filteredRows });
  };

  handleClose = () => {
    this.loadInitialValues();
  };

  filterChildren(filter) {
    let filterTermLower = filter.toLowerCase();
    let newItems = this.state.template.filter(
      (f) =>
        f.EntityMetaType.toLowerCase().includes(filterTermLower) ||
        (f.EntityMetaValue &&
          f.EntityMetaValue.toLowerCase().includes(filterTermLower)) ||
        filter === ''
    );
    this.setState({
      filteredTemplate: newItems,
      currentGroupFilterText: filter,
    });
  }

  remove = (item) => {
    let template = this.state.template.filter(
      (x) => x.EntityGroupMeta_DBID !== item.EntityGroupMeta_DBID
    );

    let filterTermLower = this.state.searchText.toLowerCase();

    let newItems = template.filter(
      (f) =>
        f.EntityMetaType.toLowerCase().includes(filterTermLower) ||
        (f.EntityMetaValue &&
          f.EntityMetaValue.toLowerCase().includes(filterTermLower)) ||
        this.state.searchText === ''
    );

    this.setState({ template: template, filteredTemplate: newItems });
    this.updateMeta('', item);
  };

  addEntry = (item) => {
    let newItem = {};
    newItem = Object.assign(newItem, item);
    newItem.EntityGroupMeta_DBID = -1;
    newItem.EntityMetaValue = '';
    delete newItem.values;
    let template = this.state.template;
    let newItems = template.filter(
      (f) =>
        f.EntityMetaType.toLowerCase().includes(
          this.state.currentGroupFilterText.toLowerCase()
        ) ||
        (f.EntityMetaValue &&
          f.EntityMetaValue.toLowerCase().includes(
            this.state.currentGroupFilterText.toLowerCase()
          )) ||
        this.state.currentGroupFilterText === ''
    );
    template.push(newItem); // adding it after filter applies as we need the blank entry irrespective of the filter
    newItems.push(newItem);
    newItems.sort((a, b) => (a.Sort > b.Sort ? 1 : a.Sort < b.Sort ? -1 : 0));
    this.setState({ template: template, filteredTemplate: newItems });
    // if item is at the bottom of the field list and a row is added, scroll to the bottom
    if (
      item.Sort === Math.max(...this.state.filteredTemplate.map((o) => o.Sort))
    ) {
      setTimeout(() => {
        this.bottomRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 0);
    }
  };

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';

    // Save selected sort column & order to cookies
    this.bake('orderBy');
    this.bake('order', isAsc ? 'desc' : 'asc');

    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    // Save selected rows per page to cookies
    this.bake('rowsPerPage', parseInt(event.target.value, 10));

    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  confirmDelete = (e, row) => {
    e.stopPropagation();
    this.setState({ currentItem: row, deleting: true });
  };

  deleteGroup = () => {
    Api({
      sp: 'deleteEntityGroup',
      json: {
        groupId: this.state.currentItem.EntityGroup_DBID,
      },
    }).then(() => {
      let rows = this.state.rows.filter(
        (x) => x.EntityGroup_DBID !== this.state.currentItem.EntityGroup_DBID
      );
      let filteredRows = this.state.filteredRows.filter(
        (x) => x.EntityGroup_DBID !== this.state.currentItem.EntityGroup_DBID
      );
      toast.success(
        this.state.currentItem.EntityMetaType +
          ': ' +
          this.state.currentItem.EntityMetaValue +
          ' deleted successfully'
      );
      this.setState({
        rows: rows,
        groups: rows,
        filteredRows: filteredRows,
        deleting: false,
      });
      this.selectedEntityType = this.state.entityTypes[0];
    });
  };

  handleTypeChange = (selectedOptions) => {
    this.setState({ newEntityTypes: selectedOptions });
  };

  handleTypeUpdate = (opts) => {
    if (!this.state.currentItem.EntityType) {
      this.endEdit();
    }
    Api({
      sp: 'updateGroupTypes',
      json: {
        groupId: this.state.currentItem.EntityGroup_DBID,
        entityTypeIds: JSON.stringify(opts.map((t) => t.EntityType_DBID)),
      },
    }).then(() => {
      let item = this.state.currentItem;
      item.EntityType = opts.map((o) => o.EntityType);
      item.EntityType_DBID = opts.map((o) => o.EntityType_DBID);
      this.setState({
        currentItem: item,
      });
      toast.success(item.EntityMetaType + ' type updated');
    });
  };

  handleNewValueChange = (val) => {
    this.setState({ newMetaValue: val });
  };

  isSaveButtonDisabled() {
    return (
      this.state.newMetaValue.length < 3 ||
      this.state.newEntityTypes.length < 1 ||
      !this.state.selectedMetaType
    );
  }
  setClmns = (fields) => {
    let columns = [...this.state.fixedColumns.filter((x) => x.id !== 'Delete')];
    fields
      .filter((x) => !x.isFixed)
      .forEach((field) => {
        columns.push({
          id: field.label,
          label: field.label,
          value: field.value,
        });
      });

    columns = this.setColumns(columns);
    // remove duplicates before setting fields in UI
    columns = columns.filter(
      (v, i, a) => a.findIndex((v2) => v2.label === v.label) === i
    );
    this.setState({ columns: columns, selectedFields: columns });
  };

  bulkUpload = (data, keys, selectedMetaType, newEntityTypes) => {
    this.setState({ spinner: false });
    Api({
      sp: 'groupBulkUpload',
      json: {
        finalData: data,
        keys: keys,
        groupType: selectedMetaType.EntityMetaType_DBID,
        typeIds: JSON.stringify(
          newEntityTypes.map((type) => type.EntityType_DBID)
        ),
      },
    })
      .then((response) => {
        let uploadedRows = response.filter((res) => res.Dup_Row === 0);
        let dups = response.filter((res) => res.Dup_Row === 1);
        if (uploadedRows.length > 0) {
          this.setState({
            uploaded: uploadedRows,
            spinner: false,
          });
          this.loadInitialValues();
        } else {
          this.setState({
            duplicates: dups,
            spinner: false,
          });
        }
      })
      .catch((e) => {
        console.log('Error: ' + e);
        this.setState({ spinner: false });
        toast.error('An error occured while trying to process your request.');
      });
  };

  // identify which column filter is being applied on
  selectFilter = (column) => {
    this.setState({ applyFilter: true, filterColumn: column });
  };

  // fetch dropdown options based on selected column
  getOptionsDelayed = (filterText) => {
    filterText = filterText?.toLowerCase();

    // get unique values of the filter column
    let filterFromList = new Set(
      this.state.rows.map((item) => item[this.state.filterColumn.id]).flat()
    );

    // create list of objects {<filterType>:[<Value>]}
    //e.g.; {EntityMetaType: "Model"}
    filterFromList = Array.from(filterFromList).map((item) => {
      return { [this.state.filterColumn.id]: item };
    });

    if (filterText.length) {
      this.setState({
        // search for filter text
        filterOpts: filterFromList.filter((item) =>
          item[this.state.filterColumn.id]?.toLowerCase().includes(filterText)
        ),
      });
    }
  };

  filterRows = () => {
    let filteredRows = this.state.rows;

    // Object.keys fetches the filter types i.e., (EntitymetaValue, EntityType, EntityMetaType, etc.)
    // e.g. filterSelected: [EntityMetaType:["Model", "Company"], "EntityType:["Amplifier"]]
    Object.keys(this.state.filterSelected).forEach((filterType) => {
      // if any filterType has selected values
      if (this.state.filterSelected[filterType]?.length) {
        filteredRows = filteredRows.filter((item) => {
          let rowItem = item[filterType];
          // single group may belong to multiple EntityTypes,
          // e.g.., some Company may be used for Amplifier, Oscilloscope, etc.
          // if any filter is applied on EntityType, confirm if it's array
          // and search if any of the selected filter apply to the entitytypes for that group
          return Array.isArray(rowItem)
            ? this.state.filterSelected[filterType].some((x) =>
                rowItem.includes(x)
              )
            : this.state.filterSelected[filterType].includes(item[filterType]);
        });
      }
    });

    this.setState({ filteredRows: filteredRows });
  };

  closeFilter = () => {
    // clear state variable for filter on modal close
    this.setState({ applyFilter: false, filterOpts: [], filterColumn: [] });
  };

  // update the corresponding filter values when selected
  updateFilterFields = () => {
    let filterSelected = this.state.filterSelected;
    // if new filter type, create  a new list
    let filterList = filterSelected[this.state.filterColumn.id] ?? [];

    // add the selected option to the filter
    filterList.push(this.state.currentOption[this.state.filterColumn.id]);

    // each filter value must occus only once in the filter list
    filterSelected[this.state.filterColumn.id] = Array.from(
      new Set(filterList)
    );

    this.setState({
      filterSelected: filterSelected,
    });

    // apply filter to the rows
    this.filterRows();
  };

  // if any value os deselected
  deleteFilterValue = (v) => {
    let filterSelected = this.state.filterSelected;

    //fetch the selected option fo the current filter and remove the deselected value
    filterSelected[this.state.filterColumn.id] = filterSelected[
      this.state.filterColumn.id
    ]?.filter((item) => item !== v);

    this.setState({
      filterSelected: filterSelected,
    });

    // apply filter
    this.filterRows();
  };

  updateCurrentOption = (e, o) => {
    this.setState({ currentOption: o });
  };

  render() {
    return IsRole(
      ['Admin', 'Equipment Manager', 'Material Manager', 'Dev', 'Planner'],
      this.props.roles
    ) ? (
      <div className="main-container">
        <div className="mt-2 d-flex justify-content-between">
          {/* Page Title */}
          <h2>Groups</h2>

          {/* Add Group Button */}
          {IsRole([
            'Equipment Manager',
            'Material Manager',
            'Planner',
            'Admin',
          ]) ? (
            <ButtonGroup className="mb-2">
              <Button
                variant="primary"
                className="ms-auto text-nowrap addEntity"
                onClick={this.showModal}
              >
                Add Group
              </Button>
              <Button
                variant="primary"
                className="ms-auto text-nowrap bulkInsertBtn"
                onClick={() => this.setState({ bulkUpload: true })}
              >
                Bulk Upload
              </Button>
            </ButtonGroup>
          ) : null}
        </div>

        <Stack className="pb-2" direction="horizontal" gap={3}>
          {/* Search Bar */}
          <Form.Control
            value={this.state.searchText}
            type="text"
            placeholder="Search"
            onChange={(e) => {
              this.setFilters(this.state.selectedFilters, {
                text: e.target.value.toLowerCase(),
              });

              // Save search text to cookies & state
              this.bake('searchText', e.target.value);

              this.setState({ searchText: e.target.value });
            }}
            autoFocus
          />
        </Stack>

        {/* Material Table */}
        <Paper sx={{ width: '100%', overflow: 'hidden' }}>
          <TableContainer
            component={Paper}
            className="tableContainer"
            id="tab-container"
          >
            <Table stickyHeader aria-label="sticky table">
              {/* Column Headers */}
              <TableHead>
                <TableRow>
                  {this.state.columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sortDirection={
                        this.state.orderBy === column.id ?? column.label
                          ? this.state.order
                          : false
                      }
                      className="table-header"
                    >
                      {/* Non-sortable & sortable column headers */}
                      {column.id === 'Delete' ? (
                        <span>{column.label}</span>
                      ) : (
                        <>
                          {/*  sort icon for each column  */}
                          <TableSortLabel
                            active={
                              this.state.orderBy === column.id ?? column.label
                            }
                            direction={
                              this.state.orderBy === column.id ||
                              this.state.orderBy === column.label
                                ? this.state.order
                                : 'asc'
                            }
                            onClick={(e) =>
                              this.handleRequestSort(
                                e,
                                column.id ?? column.label
                              )
                            }
                          >
                            <span className="pr-2">{column.label}</span>
                          </TableSortLabel>
                          {/*  filter icon for each column  */}
                          <Filter
                            size={24}
                            className={'pointer header-filter-icon-active ml-2'}
                            onClick={() => this.selectFilter(column)}
                          />
                        </>
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>

              {/* Table Content */}
              <TableBody>
                {this.state.filteredRows
                  .slice()
                  .sort(getComparator(this.state.order, this.state.orderBy))
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                  .map((row, i) => {
                    return (
                      <TableRow
                        className="pointer"
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={i}
                        onClick={() => this.cardClick(row)}
                      >
                        {this.state.columns.map((column) => {
                          const value = row[column.id ?? column.label];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {column.id === 'Delete' ? (
                                <Trash
                                  color="red"
                                  size={18}
                                  onClick={(e) => this.confirmDelete(e, row)}
                                />
                              ) : Array.isArray(value) ? (
                                value.length > 1 ? (
                                  value.map((v, i) => {
                                    return (
                                      <span
                                        key={i}
                                        className="search-meta-value"
                                      >
                                        {v}
                                      </span>
                                    );
                                  })
                                ) : (
                                  value
                                )
                              ) : column.format && typeof value === 'number' ? (
                                column.format(value)
                              ) : (
                                value
                              )}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>

          <TablePagination
            labelRowsPerPage="Rows:"
            rowsPerPageOptions={ROWSPERPAGE}
            component="div"
            count={this.state.filteredRows.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>

        <Modal show={this.state.isOpen} onHide={this.hideModal}>
          <Modal.Header>
            <Modal.Title>Add Group</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-2">
              Select Type:
              <Autocomplete
                autoHighlight={true}
                size="small"
                multiple
                filterSelectedOptions
                className="type-ahead"
                limitTags={3}
                hiddenlabel="true"
                id="field-filter"
                onChange={(event, newValue) => {
                  this.handleTypeChange(newValue);
                }}
                options={this.state.entityTypes}
                isOptionEqualToValue={(option, value) =>
                  option.EntityType_DBID === value.EntityType_DBID
                }
                getOptionLabel={(option) => option.EntityType}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      size="small"
                      key={index}
                      label={option.EntityType}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                style={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    style={{ width: '100%' }}
                    placeholder="Template Type"
                  />
                )}
              />
            </div>
            <div className="mb-2">
              Select Field:
              <Autocomplete
                autoHighlight={true}
                className="type-ahead"
                id="meta-type"
                options={this.state.metaTypes}
                getOptionLabel={(option) => option.EntityMetaType}
                hiddenlabel="true"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select/Type"
                  />
                )}
                onChange={(_event, selected) => {
                  this.setState({
                    newMetaTypeId: selected.EntityMetaType_DBID,
                    selectedMetaType: selected,
                  });
                }}
              />
            </div>
            Enter value:
            <Form.Control
              type="text"
              placeholder="Value"
              onKeyUp={(e) => this.handleNewValueChange(e.target.value)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="outline-primary" onClick={this.hideModal}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={(e) => {
                e.currentTarget.disabled = true;
                this.addGroup();
              }}
              disabled={this.isSaveButtonDisabled()}
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          onEntered={() => this.textInput.current.focus()}
          size="lg"
          show={this.state.editing}
          onHide={this.endEdit}
        >
          <Modal.Header closeButton="true">
            <Modal.Title>
              {this.state.currentItem.EntityMetaType}:{' '}
              {IsRole([
                'Equipment Manager',
                'Material Manager',
                'Planner',
                'Admin',
              ]) ? (
                this.state.renaming ? (
                  <div className="renameInput">
                    <Form.Control
                      type="text"
                      className="inputBoxText"
                      autoFocus
                      placeholder="Group Name"
                      defaultValue={this.state.currentItem.EntityMetaValue}
                      onBlur={(e) => this.renameGroup(e.target.value)}
                    />
                  </div>
                ) : (
                  <Hint
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    title="Click to rename group"
                  >
                    <span
                      className="pointer child-link"
                      onClick={() => this.startRenaming()}
                    >
                      {this.state.currentItem.EntityMetaValue}
                    </span>
                  </Hint>
                )
              ) : (
                this.state.currentItem.EntityMetaValue
              )}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Autocomplete
              autoHighlight={true}
              size="small"
              multiple
              filterSelectedOptions
              className="type-ahead"
              disableClearable
              limitTags={3}
              hiddenlabel="true"
              id="field-filter"
              onChange={(event, newValue) => {
                this.handleTypeUpdate(newValue);
              }}
              value={this.state.currentItem?.EntityType?.map((t, i) => ({
                EntityType: t,
                EntityType_DBID: this.state.currentItem?.EntityType_DBID[i],
              }))}
              options={this.state.entityTypes}
              isOptionEqualToValue={(option, value) =>
                option.EntityType_DBID === value.EntityType_DBID
              }
              getOptionLabel={(option) => option.EntityType}
              renderTags={(tagValue, getTagProps) =>
                tagValue?.map((option, index) => (
                  <Chip
                    size="small"
                    key={index}
                    label={option?.EntityType}
                    {...getTagProps({ index })}
                    disabled={
                      this.state.currentItem?.EntityType?.length < 2 ||
                      !this.state.currentItem
                    }
                  />
                ))
              }
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  style={{ width: '100%' }}
                  placeholder="Template Type"
                  onKeyDown={(event) => {
                    if (
                      event.key === 'Backspace' &&
                      this.state.currentItem?.EntityType?.length < 2
                    ) {
                      event.stopPropagation();
                    }
                  }}
                />
              )}
            />
            <br />
            <Form.Control
              ref={this.textInput}
              type="text"
              placeholder="Filter"
              value={this.state.currentGroupFilterText}
              onChange={(e) => this.filterChildren(e.target.value)}
              autoFocus
            />
            <br />
            <div className="fieldList-container">
              <FieldList
                items={this.state.filteredTemplate}
                update={this.updateMeta}
                remove={this.remove}
                addEntry={this.addEntry}
                disabled={
                  !IsRole([
                    'Equipment Manager',
                    'Material Manager',
                    'Planner',
                    'Admin',
                  ])
                }
                users={this.state.users}
                imgServer={this.state.imgServer}
                sas={this.state.sas}
              />
              <div ref={this.bottomRef}></div>
            </div>
          </Modal.Body>
        </Modal>
        {/* filter modal to identify the colum(filter type) and select values (filter values for that filter type) */}
        <Modal
          show={this.state.applyFilter}
          onHide={() => this.closeFilter()}
          className="entity-modal"
        >
          <Modal.Header closeButton="true">
            <Modal.Title>
              Filter{' '}
              {this.state.filterColumn?.label ?? this.state.filterColumn?.id}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-2">Select values to filter column.</div>
            <Autocomplete
              size="lg"
              multiple
              filterSelectedOptions
              autoHighlight={true}
              id="filter-options"
              options={this.state.filterOpts}
              onHighlightChange={(e, o) => this.updateCurrentOption(e, o)}
              noOptionsText={'Start typing to search'}
              getOptionLabel={(option) => option[this.state.filterColumn.id]}
              onChange={(event, newValue) =>
                this.updateFilterFields(event, newValue)
              }
              onInputChange={(e, newInputValue) => {
                this.setState({ filterText: newInputValue });
                this.getOptionsDelayed(newInputValue);
              }}
              renderTags={() => null}
              style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField
                  autoFocus
                  {...params}
                  label="Filter"
                  inputRef={(input) => {
                    this.textInput = input;
                  }}
                />
              )}
            />
            {/* display the selected values*/}
            <Box
              mt={1}
              className={'modal-box'}
              sx={{
                '& > :not(:last-child)': { mr: 1 },
                '& > *': { mr: 1, mt: 2 },
              }}
            >
              {this.state.filterSelected[this.state.filterColumn.id]?.map(
                (v) => (
                  <Chip
                    key={v}
                    label={v}
                    onDelete={() => this.deleteFilterValue(v)}
                  />
                )
              )}
            </Box>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" onClick={() => this.closeFilter()}>
              {'Close'}
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          show={this.state.deleting}
          onHide={() => this.setState({ deleting: false })}
          className="entity-modal"
        >
          <Modal.Header closeButton="true">
            <Modal.Title>
              Confirm deletion of{' '}
              {this.state.currentItem.EntityMetaType +
                ': ' +
                this.state.currentItem.EntityMetaValue}
            </Modal.Title>
          </Modal.Header>
          <Modal.Footer>
            <Button
              variant="outline-primary"
              onClick={() =>
                this.setState({ deleting: false, currentItem: {} })
              }
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={(e) => {
                e.currentTarget.disabled = true;
                this.deleteGroup();
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>

        {this.state.bulkUpload ? (
          <EntityBulkUpload
            level="Group"
            rows={this.state.rows}
            createBulk={this.state.bulkUpload}
            closeModal={this.hideBulkModal}
            saveData={this.bulkUpload}
            savedData={this.state.uploaded}
            duplicates={this.state.duplicates}
            spinner={this.state.spinner}
          ></EntityBulkUpload>
        ) : null}
      </div>
    ) : (
      <NoAccess />
    );
  }

  const;
}
export default MetaGroups;
