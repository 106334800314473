import { useEffect, useState } from 'react';
import Brand from '../elm-text3.png';
import { Api } from './interface';
import { oven } from './common';

const ReleaseNotes = () => {
  const [releaseNotes, setReleaseNotes] = useState([]);
  const [headers, setHeaders] = useState([]);
  const [version, setVersion] = useState('');

  const bake = (g, c, v) => {
    return oven({}, true, c, v);
  };

  useEffect(() => {
    Api({
      sp: 'getLists',
      json: {},
    }).then((lists) => {
      let sortedHeaders = lists
        .filter((x) => x.ListType === 'releaseNote' && x.ListHeader)
        .map((x) => x.ListHeader)
        .sort()
        .reverse();

      setHeaders([...new Set(sortedHeaders)]);
      setReleaseNotes(lists.filter((x) => x.ListType === 'releaseNote'));

      // Derive Elm version from latest release note header
      setVersion(sortedHeaders[0].match(/^v(\d+\.\d+\.\d+)/)[1]);

      bake('userVersion', 'v' + sortedHeaders[0].match(/^v(\d+\.\d+\.\d+)/)[1]);
    });
  }, []);

  return (
    <div>
      <div className="container">
        <div className="row">
          <div className="col"></div>

          {/* Title */}
          <div className="col-8 d-flex justify-content-center align-items-center">
            <img
              width="150px"
              height="47px"
              className="img-responsive logo-text mx-2"
              src={Brand}
              alt="logo"
            />
            <div className="welcome-text">Release Notes</div>
          </div>

          {/* Current Version */}
          <div className="col">
            {version && <h4 className="text-end mt-3">Version: {version}</h4>}
          </div>
        </div>
      </div>

      {/* Version Header & Release Notes */}
      {releaseNotes && (
        <div className="d-flex justify-content-center release-note-container mt-2">
          <dl className="release-note">
            {headers.map((header, i) => (
              <figure key={i}>
                <dt>{header}</dt>
                {releaseNotes
                  .filter((x) => x.ListHeader === header)
                  .map((item, j) => (
                    <dd key={j}>{item.ListItem}</dd>
                  ))}
              </figure>
            ))}
          </dl>
        </div>
      )}
    </div>
  );
};

export default ReleaseNotes;
