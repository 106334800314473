import {
  Divider,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Button, ButtonGroup, Form } from 'react-bootstrap';
import {
  ArrowRepeat,
  CaretDownFill,
  Diagram3,
  Key,
  List,
  PersonPlus,
  SlashCircle,
  Trash,
} from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { handleResize } from './common';
import { shortDtm } from './date';
import { Api } from './interface';
import TokenAdd from './tokenAdd';
import NoAccess from './noAccess';
import { IsRole } from './validator';
export default function ApiManagement() {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [actionAnchorEl, setActionAnchorEl] = useState(null);
  const [contextType, setContextType] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [filterText, setFilterText] = useState('');
  const open = Boolean(anchorEl);
  const actionOpen = Boolean(actionAnchorEl);

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    handleResize();
  });

  useEffect(() => {
    if (!filterText?.length) {
      setFilteredRows(rows);
    } else {
      setFilteredRows(
        rows.filter((o) => {
          return Object.keys(o).some((k) =>
            o[k].toString().toLowerCase().includes(filterText.toLowerCase())
          );
        })
      );
    }
  }, [filterText]);

  const getData = () => {
    Api({
      sp: 'getTokens',
      json: {},
    }).then((response) => {
      response.forEach((r) => {
        r.Expiration = shortDtm(r.Expiration);
        r['Last Call'] = shortDtm(r['Last Call']);
      });
      setRows(response);
      setFilteredRows(response);
      let cols = Object.keys(response[0]).map((x) => ({ id: x }));
      cols.push({ id: 'Actions', align: 'center' });
      setColumns(cols);
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleContextClick = (event, row) => {
    setActionAnchorEl(event.currentTarget);
    setCurrentRow(row);
  };

  const handleClose = (event) => {
    const type = event.target.dataset.type;
    setContextType(type);
    if (type === 'Refresh') {
      refreshToken();
    }
    if (type === 'Revoke') {
      revokeToken();
    }
    if (type === 'Delete') {
      deleteToken();
    }
    setAnchorEl(null);
    setActionAnchorEl(null);
  };

  const closeModal = () => {
    setContextType(null);
    getData();
  };

  const refreshToken = () => {
    Api({
      sp: 'refreshToken',
      json: { token: currentRow.Token },
    }).then(() => {
      toast.success('Token refreshed');
      getData();
    });
  };

  const revokeToken = () => {
    Api({
      sp: 'revokeToken',
      json: { token: currentRow.Token },
    }).then(() => {
      toast.success('Token revoked');
      getData();
    });
  };

  const deleteToken = () => {
    Api({
      sp: 'deleteToken',
      json: { token: currentRow.Token },
    }).then(() => {
      toast.success('Token deleted');
      getData();
    });
  };

  return IsRole(['Dev'], this.props.roles) ? (
    <div className="main-container">
      <div className="mt-2 d-flex justify-content-between">
        <h2>API Management</h2>
        <ButtonGroup>
          <div>
            <Button id="add-button" variant="primary" onClick={handleClick}>
              Add
              <CaretDownFill className="button-end-icon" />
            </Button>
            <Menu
              id="add-menu"
              MenuListProps={{
                'aria-labelledby': 'demo-customized-button',
              }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
            >
              <MenuItem data-type="Client" onClick={handleClose} disableRipple>
                <PersonPlus className="context-icon" />
                Client
              </MenuItem>
              <MenuItem data-type="Token" onClick={handleClose} disableRipple>
                <Key className="context-icon" />
                Token
              </MenuItem>
              <MenuItem data-type="Map" onClick={handleClose} disableRipple>
                <Diagram3 className="context-icon" />
                Map
              </MenuItem>
            </Menu>
          </div>
        </ButtonGroup>
      </div>
      <Form.Control
        placeholder="Search"
        onChange={(e) => {
          setFilterText(e.target.value);
        }}
      />
      <Paper sx={{ width: '100%', overflow: 'hidden' }}>
        <TableContainer
          id="tab-container"
          component={Paper}
          // className={dragging ? "tableContainer no-scroll" : "tableContainer"}
          className="tableContainer"
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            size="small"
            padding="none"
          >
            {/* Column Headers */}
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    className="table-header"
                  >
                    {column.id}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>

            {/* Table Content */}
            <TableBody>
              {filteredRows.map((row, i) => {
                return (
                  <TableRow key={i} hover>
                    {columns.map((column) => {
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={
                            new Date(row.Expiration) < new Date()
                              ? 'expired-api'
                              : ''
                          }
                        >
                          {column.id === 'Actions' ? (
                            <List
                              size={24}
                              className="pointer row-menu-icon"
                              onClick={(e) => handleContextClick(e, row)}
                            />
                          ) : (
                            row[column.id]
                          )}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Menu
        id="actions-menu"
        MenuListProps={{
          'aria-labelledby': 'demo-customized-button',
        }}
        anchorEl={actionAnchorEl}
        open={actionOpen}
        onClose={handleClose}
      >
        <MenuItem data-type="Refresh" onClick={handleClose} disableRipple>
          <ArrowRepeat className="context-icon" />
          Refresh
        </MenuItem>
        <MenuItem data-type="Revoke" onClick={handleClose} disableRipple>
          <SlashCircle className="context-icon" />
          Revoke
        </MenuItem>
        <Divider />
        <MenuItem data-type="Delete" onClick={handleClose} disableRipple>
          <Trash className="context-icon red-option" />
          Delete
        </MenuItem>
      </Menu>
      {['Client', 'Token', 'Map'].includes(contextType) ? (
        <TokenAdd
          type={contextType}
          open={['Client', 'Token', 'Map'].includes(contextType)}
          close={closeModal}
        />
      ) : null}
    </div>
  ) : (
    <NoAccess />
  );
}
