import { Menu, MenuItem } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  BarChart,
  BoxSeam,
  Chat,
  ClipboardCheck,
  Eye,
  Files,
  Pencil,
  PersonDash,
  PersonPlus,
  Trash,
  CartPlus,
  CurrencyDollar,
  Recycle,
  Person,
  Envelope,
  PiggyBank,
  Send,
  Box2,
} from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import EntityClone from './entityClone';
import EntityArchive from './entityArchive';
import EntityEdit from './entityEdit';
import EntityHistory from './entityHistory';
import EntityMove from './entityMove';
import EntityOwnerChange from './entityOwnerChange';
import EntityStatus from './entityStatus';
import GiveIt from './giveIt';
import { NestedMenuItem } from 'mui-nested-menu';
import { IsRole } from './validator';
import { bake } from './common';
import PutAwayOrder from './putAwayOrder';

export default function ActionContext(props) {
  const [giveAction, setGiveAction] = useState(undefined);
  const [isEOL, setIsEOL] = useState(false);
  const [mixedStatus, setMixedStatus] = useState(false);

  const eolList = ['Reclaimed', 'Scrapped', 'Shipped', 'Archived'];

  const handleOpen = (modal, type) => {
    setGiveAction(type);
    props.handleActionContextClose(false, modal);
  };

  const closeModal = (refresh) => {
    props.handleActionContextClose(refresh, null);
  };

  const getRequestDisabled = () => {
    if (props.selectedRows.length === 1) {
      return props.currentRow.Available == 0;
    }
    return false;
  };
  const geekDisabled = () => {
    let rows =
      props.selectedRows.length >= 1 ? props.selectedRows : [props.currentRow];
    let type = rows[0]?.Type;
    // all items should be owned by Material Storage
    let ownerCheck = rows.find(
      (row) =>
        !('EntityOwner' in row) || row.EntityOwnerName[0] !== 'Material Storage'
    )
      ? false
      : true;

    //all items need to be in geek+ location (need to update the location from Material Storage to the new geek location when available in ELM)
    let locationCheck = rows.find(
      (row) =>
        !row.Location ||
        !row.Location.join().trim().includes('Material Storage')
    )
      ? false
      : true;
    let field =
      type === 'SSD'
        ? ['Serial Number', 'Build ID']
        : type === 'Component'
        ? ['Component Lot ID', 'Quantity']
        : null;
    // for SSDs BuildID and Serial Number are required
    //for components Component Name and Quantity are required
    let validFieldCheck = rows.find(
      (row) =>
        field.some((val) => Object.keys(row).indexOf(val) === -1) ||
        (props.matType === 'Component' &&
          (row['Component Name']?.[0] !== rows[0]['Component Name'] ||
            row['Quantity'] < 1))
    )
      ? false
      : true;

    //all units should be In Inventory
    let statusCheck = rows.find(
      (row) =>
        !('EntityStatus' in row) || row.EntityStatus[0] !== 'In Inventory'
    )
      ? false
      : true;
    return !(ownerCheck && locationCheck && validFieldCheck && statusCheck);
  };

  const ptoDisabled = () => {
    // Material coming to material Storage via bulk upload (In Transit SSDs) and material already residing at MS

    let rows =
      props.selectedRows.length >= 1 ? props.selectedRows : [props.currentRow];

    let foundPTO = rows.find(
      (row) => row['Geek PTO ID'] && row['Geek PTO ID'][0] !== undefined
    ); // Already at Geek or In-transit towards geekstorage

    if (foundPTO) {
      return true;
    } else {
      let found = rows.find(
        (row) =>
          row.EntityOwnerName[0] !== 'Material Storage' &&
          row.Status !== 'In Transit'
      ); // neither owned by material storage nor intransit towards material storage

      if (found) {
        return true;
      } else {
        return false;
      }
    }
  };
  const giveDisabled = () => {
    let rows =
      props.selectedRows.length >= 1 ? props.selectedRows : [props.currentRow];
    let owner = rows[0]?.EntityOwner;
    // location array is always there
    // location needs to be same only till Site to be eligible for give it
    // i.e., Geo -> Site, e.g., AMR -> Folsom
    let location = rows[0]?.Location.slice(0, 2).join().trim();
    let type = rows[0]?.Type;
    let componentName = rows[0]['Component Name']
      ? rows[0]['Component Name'][0]
      : null;

    let field =
      type === 'SSD'
        ? ['Serial Number']
        : type === 'Component'
        ? ['Component Lot ID', 'Quantity']
        : null;

    if (owner && location) {
      let found = rows.find(
        (row) =>
          !('EntityOwner' in row) ||
          row.EntityOwner[0] !== owner[0] ||
          !('EntityStatus' in row) ||
          row.EntityStatus[0] !== 'In Inventory' ||
          field.some((val) => Object.keys(row).indexOf(val) === -1) ||
          (props.matType === 'Component' &&
            (row['Component Name']?.[0] !== componentName ||
              row['Quantity'] < 1)) ||
          !row.Location ||
          row.Location.slice(0, 2).join().trim() !== location ||
          row['Borrower']
      );

      // Only allow Material Storage to do Give It from Material Storage
      let classStoreCheck =
        props.isClassStoreMember === false &&
        rows.some((row) => {
          return row.EntityOwnerName[0] === 'Material Storage';
        });

      if (found || classStoreCheck) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const archiveDisabled = () => {
    let rows =
      props.selectedRows.length >= 1 ? props.selectedRows : [props.currentRow];
    // disable if already archived, i.e., Status is "Archived"
    if (props.level === 'Material') {
      if (IsRole(['Admin', 'Material Manager'])) {
        let found = rows.find(
          (row) =>
            !('EntityStatus' in row) || row.EntityStatus[0] === 'Archived'
        );
        return found;
      } else {
        let user = bake('user');
        let found = rows.find(
          (row) =>
            !('EntityOwnerName' in row) ||
            row.EntityOwnerName[0] !== user.name ||
            !('EntityStatus' in row) ||
            row.EntityStatus[0] === 'Archived'
        );

        return found;
      }
    } else if (props.level === '') {
      // Equipment

      if (IsRole(['Admin', 'Equipment Manager'])) {
        let found = rows.find(
          (row) =>
            !('EntityStatus' in row) || row.EntityStatus[0] === 'Archived'
        );
        return found;
      } else {
        let user = bake('user');
        let found = rows.find(
          (row) =>
            !('EntityOwnerName' in row) ||
            row.EntityOwnerName[0] !== user.name ||
            !('EntityStatus' in row) ||
            row.EntityStatus[0] === 'Archived'
        );
        return found;
      }
    } else {
      return true;
    }
  };

  const loanDisabled = () => {
    let rows =
      props.selectedRows.length >= 1 ? props.selectedRows : [props.currentRow];
    let owner = rows[0]?.EntityOwner;
    let location = rows[0]?.Location.slice(0, 2).join().trim();

    if (owner && location) {
      let found = rows.find(
        (row) =>
          !('EntityOwner' in row) ||
          row.EntityOwner[0] !== owner[0] ||
          !('EntityStatus' in row) ||
          row.EntityStatus[0] !== 'In Inventory' ||
          !row.Location ||
          row.Location.slice(0, 2).join().trim() !== location ||
          !row['Borrower']
      );
      if (found) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (!props.grouped && props.currentRow.EntityStatus) {
      let eol = props.selectedRows.length
        ? props.selectedRows.some((x) => eolList.includes(x.EntityStatus[0]))
        : eolList.includes(props.currentRow.EntityStatus[0]);
      setIsEOL(eol);
    }

    if (
      props.selectedRows.length >= 1 &&
      props.selectedRows[0].EntityStatus &&
      !props.selectedRows.every(
        (x) =>
          x.EntityStatus &&
          x.EntityStatus[0] === props.selectedRows[0].EntityStatus[0]
      )
    ) {
      setMixedStatus(true);
    }
  }, []);

  const gotDisabled = () => {
    let rows =
      props.selectedRows.length >= 1 ? props.selectedRows : [props.currentRow];
    let owner = rows[0]?.EntityOwner;
    let componentName = rows[0]['Component Name']
      ? rows[0]['Component Name'][0]
      : null;
    if (owner) {
      let found = rows.find(
        (row) =>
          row?.EntityOwner[0] !== owner[0] ||
          (props.matType === 'Component' &&
            (row['Component Name']?.[0] !== componentName ||
              row['Quantity'] < 1 ||
              rows?.length > 1)) ||
          row['Borrower']
      );
      if (found) {
        return true;
      } else {
        return false;
      }
    } else return true;
  };

  return (
    <div>
      {props.contextAnchor !== null ? (
        <Menu
          id="basic-menu"
          anchorEl={props.contextAnchor}
          open={props.contextAnchor !== null}
          onClose={() => closeModal()}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          {!props.grouped &&
          !props.archiveMode &&
          !['Material'].includes(props.level) ? (
            <MenuItem
              onClick={() => handleOpen('EntityStatus')}
              disabled={mixedStatus}
            >
              <ClipboardCheck
                className="context-icon"
                data-modal={'EntityStatus'}
                onClick={handleOpen}
              />
              {props.selectedRows.length > 0 ? 'Selected Status' : 'Status'}
            </MenuItem>
          ) : null}
          {!props.grouped && !props.archiveMode ? (
            !props.selectedRows.length > 0 ? (
              <MenuItem onClick={() => handleOpen('EntityEdit')}>
                {props.canEdit ? (
                  <>
                    <Pencil className="context-icon" /> Edit
                  </>
                ) : (
                  <>
                    <Eye className="context-icon" /> Details
                  </>
                )}
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => handleOpen('EntityEdit')}
                disabled={isEOL}
              >
                {props.canEdit ? (
                  <>
                    <Pencil className="context-icon" /> Edit Selected
                  </>
                ) : null}
              </MenuItem>
            )
          ) : null}
          {(props.selectedRows.length === 0 ||
            (props.selectedRows.length === 1 &&
              props.currentRow?.Entity_DBID ===
                props.selectedRows[0].Entity_DBID)) &&
          !props.grouped ? (
            <MenuItem onClick={() => handleOpen('Dashboard')}>
              <Link
                className="menu-link"
                to={'/dash?id=' + props.currentRow?.Entity_DBID}
              >
                <BarChart className="context-icon" /> Dashboard
              </Link>
            </MenuItem>
          ) : null}
          {!props.grouped && !(props.level === 'Geo') && !props.archiveMode ? (
            <MenuItem onClick={() => handleOpen('EntityMove')} disabled={isEOL}>
              <BoxSeam className="context-icon" />{' '}
              {props.selectedRows.length > 0 ? 'Move Selected' : 'Move'}
            </MenuItem>
          ) : null}
          {!props.grouped && !props.level && !props.archiveMode ? (
            <MenuItem
              onClick={() => handleOpen('EntityOwnerChange')}
              disabled={isEOL}
            >
              <Person className="context-icon" /> {'Change Owner'}
            </MenuItem>
          ) : null}
          {(props.selectedRows.length === 0 ||
            (props.selectedRows.length === 1 &&
              props.currentRow?.Entity_DBID ===
                props.selectedRows[0].Entity_DBID)) &&
          !props.grouped &&
          !props.archiveMode &&
          props.level !== 'Material' ? (
            <MenuItem
              onClick={() => handleOpen('EntityClone')}
              disabled={!props.canEdit || isEOL}
            >
              <Files className="context-icon" /> Clone
            </MenuItem>
          ) : null}

          {/* Nested Give It */}
          {props.level === 'Material' &&
          !props.grouped &&
          !props.archiveMode ? (
            <NestedMenuItem
              leftIcon={<PersonDash />}
              className="nested-menu-item"
              label={
                props.selectedRows.length > 1 ? 'Give Selected' : 'Give It'
              }
              parentMenuOpen={
                props.contextAnchor !== null && !isEOL && !giveDisabled()
              }
              disabled={giveDisabled() || isEOL}
            >
              <MenuItem
                onClick={() => handleOpen('GiveIt', 'Give')}
                disabled={giveDisabled() || isEOL}
              >
                <Person className="context-icon" /> Internal
              </MenuItem>
              <MenuItem
                onClick={() => handleOpen('GiveIt', 'Ship')}
                disabled={giveDisabled() || isEOL}
              >
                <Envelope className="context-icon" /> External
              </MenuItem>
              {props.isClassStoreMember ? (
                <MenuItem
                  onClick={() => handleOpen('GiveIt', 'GeekPickup')}
                  disabled={geekDisabled() || isEOL}
                >
                  <Send className="context-icon" /> Pickup From Geek+
                </MenuItem>
              ) : null}
              <MenuItem
                onClick={() => handleOpen('GiveIt', 'Loan')}
                disabled={giveDisabled() || isEOL}
              >
                <PiggyBank className="context-icon" /> Loan
              </MenuItem>
              <MenuItem
                onClick={() => handleOpen('GiveIt', 'Reclaim')}
                disabled={giveDisabled() || isEOL}
              >
                <CurrencyDollar className="context-icon" /> Reclaim
              </MenuItem>
              <MenuItem
                onClick={() => handleOpen('GiveIt', 'Scrap')}
                disabled={giveDisabled() || isEOL}
              >
                <Recycle className="context-icon" /> Scrap
              </MenuItem>
            </NestedMenuItem>
          ) : null}

          {props.level === 'Material' &&
          !props.grouped &&
          !props.archiveMode ? (
            <MenuItem
              onClick={() => handleOpen('GiveIt', 'Got')}
              disabled={gotDisabled() || isEOL}
            >
              <PersonPlus className="context-icon" /> Got{' '}
              {props.selectedRows.length > 1 ? 'Selected' : 'It'}
            </MenuItem>
          ) : null}
          {IsRole(['Admin', 'Material Manager']) &&
          props.level === 'Material' &&
          !props.grouped &&
          !props.archiveMode ? (
            <MenuItem
              onClick={() => handleOpen('PTO')}
              disabled={ptoDisabled() || isEOL}
            >
              <Box2 className="context-icon" /> Create PTO
            </MenuItem>
          ) : null}
          {props.level === 'Material' && !props.grouped && loanDisabled() ? (
            <MenuItem
              onClick={() => handleOpen('GiveIt', 'Return Loan')}
              show={loanDisabled() || isEOL}
            >
              <PiggyBank className="context-icon" /> Return Loan
            </MenuItem>
          ) : null}

          {props.level === 'Material' && props.grouped ? (
            <MenuItem
              onClick={() => handleOpen('RequestCreate')}
              disabled={getRequestDisabled()}
            >
              <CartPlus className="context-icon" /> Add to Request
            </MenuItem>
          ) : null}
          {(props.selectedRows.length === 0 ||
            (props.selectedRows.length === 1 &&
              props.currentRow?.Entity_DBID ===
                props.selectedRows[0].Entity_DBID)) &&
          !props.grouped ? (
            <MenuItem onClick={() => handleOpen('EntityHistory')}>
              <Chat className="context-icon" /> History
            </MenuItem>
          ) : null}
          {/* do not delete epo(s) */}

          {!props.grouped && !props.archiveMode ? (
            <MenuItem
              onClick={() => handleOpen('EntityArchive')}
              disabled={archiveDisabled()}
            >
              <Trash color="red" className="context-icon" />{' '}
              {props.selectedRows.length > 0 ? 'Archive Selected' : 'Archive'}
            </MenuItem>
          ) : null}
        </Menu>
      ) : null}

      {props.currentModal === 'EntityClone' ? (
        <EntityClone
          level={props.level}
          item={props.currentRow}
          closeModal={props.closeModal}
          open={props.currentModal === 'EntityClone'}
          close={closeModal}
        ></EntityClone>
      ) : null}

      {props.currentModal === 'EntityArchive' ? (
        <EntityArchive
          level={props.level}
          matType={props.matType}
          open={props.currentModal === 'EntityArchive'}
          items={
            props.selectedRows.length > 0
              ? props.selectedRows
              : [props.currentRow]
          }
          closeModal={props.closeModal}
          close={closeModal}
        ></EntityArchive>
      ) : null}

      {props.currentModal === 'EntityHistory' ? (
        <EntityHistory
          level={props.level}
          item={props.currentRow}
          open={props.currentModal === 'EntityHistory'}
          close={closeModal}
          archiveMode={props.archiveMode}
        ></EntityHistory>
      ) : null}
      {props.currentModal === 'EntityStatus' ? (
        <EntityStatus
          level={props.level}
          items={
            props.selectedRows.length > 0
              ? props.selectedRows
              : [props.currentRow]
          }
          open={props.currentModal === 'EntityStatus'}
          close={closeModal}
        ></EntityStatus>
      ) : null}
      {props.currentModal === 'EntityMove' ? (
        <EntityMove
          level={props.level}
          items={
            props.selectedRows.length > 0
              ? props.selectedRows
              : [props.currentRow]
          }
          open={props.currentModal === 'EntityMove'}
          close={closeModal}
        ></EntityMove>
      ) : null}
      {props.currentModal === 'EntityOwnerChange' ? (
        <EntityOwnerChange
          level={props.level}
          items={
            props.selectedRows.length > 0
              ? props.selectedRows
              : [props.currentRow]
          }
          open={props.currentModal === 'EntityOwnerChange'}
          close={closeModal}
        ></EntityOwnerChange>
      ) : null}
      {props.currentRow && props.currentModal === 'EntityEdit' ? (
        <EntityEdit
          level={props.level}
          items={
            props.selectedRows.length > 0
              ? props.selectedRows
              : [props.currentRow]
          }
          imgServer={props.imgServer}
          open={props.currentModal === 'EntityEdit'}
          canEdit={props.canEdit}
          close={closeModal}
        ></EntityEdit>
      ) : null}
      {props.currentModal === 'GiveIt' ? (
        <GiveIt
          show={props.currentModal === 'GiveIt'}
          close={(refresh) => closeModal(refresh)}
          list={
            props.selectedRows.length > 0
              ? props.selectedRows
              : [props.currentRow]
          }
          type={giveAction}
          matType={props.matType}
          isClassStoreMember={props.isClassStoreMember}
        ></GiveIt>
      ) : null}
      {props.currentModal === 'PTO' ? (
        <PutAwayOrder
          title="Give"
          show={props.currentModal === 'PTO'}
          close={(refresh) => closeModal(refresh)}
          list={
            props.selectedRows.length > 0
              ? props.selectedRows
              : [props.currentRow]
          }
        ></PutAwayOrder>
      ) : null}
    </div>
  );
}
