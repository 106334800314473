import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { Api } from './interface';

export default function HeaderHelp(props) {
  const [list, setList] = useState([]);
  const [headers, setHeaders] = useState([]);

  useEffect(() => {
    Api({
      sp: 'getHeaderHelp',
      json: {},
    }).then((response) => {
      setList(response);
      setHeaders([...new Set(response.map((x) => x.ListHeader))]);
    });
  }, []);

  return (
    <Modal
      show={props.open}
      onHide={() => props.close()}
      className="entity-modal"
      size="xl"
    >
      <Modal.Header closeButton="true">
        <Modal.Title>Header Menu Help</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-box">
          <dl className="help-list">
            {headers.map((header, i) => (
              <figure key={i}>
                <dt>{header}</dt>
                {list
                  .filter((x) => x.ListHeader === header)
                  .map((item, j) => (
                    <dd className="help-bullet" key={j}>
                      {item.ListItem}
                    </dd>
                  ))}
              </figure>
            ))}
          </dl>
        </div>
      </Modal.Body>
    </Modal>
  );
}
