import * as d3 from 'd3';
import { useEffect } from 'react';
const PieGraph = (props) => {
  useEffect(() => {
    if (props.height && props.width) {
      renderGraph(props.data);
    }
  }, [props.data]);

  const renderGraph = (data) => {
    // set the dimensions and margins of the graph
    d3.select('#myGraph').selectAll('*').remove();
    let width = props.width,
      height = props.height,
      margin = 5,
      z = d3.scaleOrdinal().domain(Object.keys(data)).range(d3.schemeDark2);

    // The radius of the pieplot is half the width or half the height (smallest one). I subtract a bit of margin.

    let radius = Math.min(width, height) / 2 - margin;
    height = height - margin;
    width = width - margin;
    height = height - margin;
    // append the svg object to the div called 'my_dataviz'
    let svg = d3
      .select('#myGraph')
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', '0 0 ' + width + ' ' + height)
      .append('g')
      .attr('transform', 'translate(' + width / 2 + ',' + height / 2 + ')');

    let pie = d3
      .pie()
      //   .sort(null) // Do not sort group by size
      .value(function (d) {
        return d.value;
      });
    let data_ready = pie(data);
    // The arc generator
    let arc = d3
      .arc()
      .innerRadius(radius * 0.5) // This is the size of the donut hole
      .outerRadius(radius * 0.8);

    // Another arc that won't be drawn. Just for labels positioning
    let outerArc = d3
      .arc()
      .innerRadius(radius * 0.9)
      .outerRadius(radius * 0.9);

    // Build the pie chart: Basically, each part of the pie is a path that we build using the arc function.
    svg
      .selectAll('allSlices')
      .data(data_ready)
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', function (d) {
        return z(d);
      })
      .attr('stroke', 'white')
      .style('stroke-width', '2px')
      .style('opacity', 0.7)
      .transition()
      .delay(function (d, i) {
        return i * 500;
      })
      .duration(1000)
      .attrTween('d', function (d) {
        var i = d3.interpolate(d.startAngle + 0.1, d.endAngle);
        return function (t) {
          d.endAngle = i(t);
          return arc(d);
        };
      });

    // Add the polylines between chart and labels:
    svg
      .selectAll('allPolylines')
      .data(data_ready)
      .enter()
      .append('polyline')
      .attr('stroke', function (d) {
        return z(d);
      })
      .style('fill', 'none')
      .attr('stroke-width', 4)
      .attr('points', function (d) {
        let posA = arc.centroid(d); // line insertion in the slice
        let posB = outerArc.centroid(d); // line break: we use the other arc generator that has been built only for that
        let posC = outerArc.centroid(d); // Label position = almost the same as posB
        let midangle = d.startAngle + (d.endAngle - d.startAngle) / 2; // we need the angle to see if the X position will be at the extreme right or extreme left
        posC[0] = radius * 0.6 * (midangle < Math.PI ? 1 : -1); // multiply by 1 or -1 to put it on the right or on the left
        return [posA, posB, posC];
      });

    // Add the polylines between chart and labels:
    svg
      .selectAll('allLabels')
      .data(data_ready)
      .enter()
      .append('text')
      .text(function (d) {
        return d.data.name + ': ' + d.data.value + '%';
      })
      .attr('transform', function (d) {
        let pos = outerArc.centroid(d);
        let midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        pos[0] = radius * 0.7 * (midangle < Math.PI ? 1 : -1);
        return 'translate(' + pos + ')';
      })
      .style('text-anchor', function (d) {
        let midangle = d.startAngle + (d.endAngle - d.startAngle) / 2;
        return midangle < Math.PI ? 'start' : 'end';
      });
  };
  return <div id="myGraph"></div>;
};

export default PieGraph;
