import React from 'react';
import { useEffect, useState } from 'react';
import { Api } from './interface';
import Modal from 'react-bootstrap/Modal';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { Hint } from './constants';
import { Button, Form, ModalBody, ModalFooter } from 'react-bootstrap';
import { PlusLg, Trash } from 'react-bootstrap-icons';
import { bake } from './common';
import { GeekApi } from './interface';
import { toast } from 'react-toastify';
import { CheckAll } from 'react-bootstrap-icons';
import { IsRole } from './validator';

export default function PutAwayOrder(props) {
  const [users, setUsers] = useState([]);
  const [list, setList] = useState(props.list);
  const [currentOwner, setCurrentOwner] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const [locations, setLocations] = useState([]);
  const [buildIds, setBuildIds] = useState([]);
  const [selectedBuildIds, setSelectedBuildIds] = useState([]);
  const [remainingBuildIds, setRemainingBuildIds] = useState([]);
  const [allSrNos, setAllSrNos] = useState([]);
  const [selectedSrNos, setSelectedSrNos] = useState([]);
  const [remainingSrNos, setRemainingSrNos] = useState([]);
  const [skuList, setskuList] = useState([]);
  const [comment, setComment] = useState(null);
  const [initRequestComment, setInitRequestComment] = useState(null);
  const [newBoxFlag, setNewBoxFlag] = useState(true);
  const [disableSave, setDisableSave] = useState(true);
  const [initSkuList, setInitSkuList] = useState(true);
  const [updateFlag, setUpdateFlag] = useState(null);

  const [trId, setTrId] = useState(undefined);
  const [errorModal, setErrorModal] = useState(false);
  const [userAction, setUserAction] = useState(null);
  const [editable, setEditable] = useState(false);
  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    if (props.list) {
      let rows = props.list?.length ? props.list : [props.currentRow];
      setCurrentDetails(rows);
      setList(rows);
      setEditable(true);
      console.log('locations' + locations);
      // generate the boxID and SRNo distribution on initial page load
      setskuList(generateSku_list(list));
    }
  }, [props.list]); // when modal is opened from SSD page

  useEffect(() => {
    if (props.currentRow) {
      let row = props.currentRow;
      setCurrentDetails([props.currentRow]);
      setInitRequestComment(row.Comments);
      setComment(row.Comments);
      setEditable(row.Status === 'Open' ? true : false);

      if (!IsRole(['Admin', 'Material Manager']) || row.Status === 'Give It') {
        setReadOnly(true);
      } else {
        setReadOnly(false);
      }

      if (row && row.Transaction_DBID) {
        setTrId(row.Transaction_DBID);

        Api({
          sp: 'getTransactionListDataGeekTemp',
          json: {
            transactionId: row.Transaction_DBID,
          },
        }).then((response) => {
          setskuList(initializeSkus(response));
        });
      }
    }
  }, [props.currentRow]); // when modal is opened from Transacation Page

  const setCurrentDetails = (rows) => {
    Api({
      sp: 'getUsers',
      json: {},
    }).then((users) => {
      setCurrentOwner(
        users.find(
          (user) => user.User_DBID === parseInt(rows[0]?.EntityOwner[0]) ?? '-1'
        )
      );
      setUsers(users);

      Api({
        sp: 'getNonMaterialPaths',
        json: {},
      }).then((parents) => {
        setLocations(parents);
        let entityPath = rows[0]?.Path;
        let found = rows.find((row) => !row?.Path || row?.Path !== entityPath);

        setCurrentLocation(
          found
            ? { Path: 'Multiple Locations' }
            : parents.find((parent) => {
                if (parent && parent?.Path)
                  return parent.Path === entityPath?.trim();
              })
        );
      });
    });
  };

  const close = (refresh) => {
    props.close(refresh);
  };

  const handleBuildIdChange = (buildId, i) => {
    let skus = [...skuList];
    let all = [...buildIds];

    skus[i].buildId = buildId;
    skus[i].ent = [];
    skus[i].obj = [];

    let selected = [];
    for (const sku of skus) {
      selected.push(sku.buildId);
    }
    const remaining = all.filter((id) => !selected.includes(id));
    setRemainingBuildIds(remaining);

    updateSrNos(skus);
    setskuList(skus);
  };

  const updateSrNos = (skus) => {
    let selectList = [];
    let all = [...allSrNos];

    for (const sku of skus) {
      selectList.push(...sku['obj']);
    }

    const remaining = all.filter(
      (obj1) => !selectList.some((obj2) => obj1.id === obj2.id)
    );

    setSelectedSrNos(selectList);
    setRemainingSrNos(remaining);
    updateBoxFlag(skus);
    updateSaveButtonFlag(skus);
    if (updateFlag === null) {
      setUpdateFlag(1);
    }
  };

  const handleSrNoChange = (list, i, res, srNo) => {
    console.log(res, srNo);
    let skus = [...skuList];
    skus[i].obj = [...list]; // update sku.ent and the list of remaining ids based on res here

    updateSrNos(skus);
    setskuList(skus);
  };

  const addAll = (i, sku) => {
    let list = remainingSrNos.filter((sr) => sr.buildId === sku.buildId);
    let skus = [...skuList];
    skus[i].obj = [...skus[i].obj, ...list];
    updateSrNos(skus);
    setskuList(skus);
  };

  const disableAddAll = (sku) => {
    let list = remainingSrNos.filter((sr) => sr.buildId === sku.buildId);

    if (list.length > 0) {
      return false;
    } else {
      return true;
    }
  };

  const initializeSkus = (rows) => {
    let skus = [];
    let objs = [];
    let buildIds = [];

    outerLoop: for (const row of rows) {
      let found = 0;
      for (const sku of skus) {
        if (sku['boxId'] == row['Box_Num']) {
          sku['obj'].push({
            buildId: row['Build ID'],
            srNo: row['Serial Number'],
            id: row['Entity_DBID'],
          });
          objs.push({
            buildId: row['Build ID'],
            srNo: row['Serial Number'],
            id: row['Entity_DBID'],
          });
          found = 1;
          continue outerLoop;
        }
      }
      if (!found) {
        let newSku = {
          boxId: row['Box_Num'],
          buildId: row['Build ID'],
          obj: [
            {
              buildId: row['Build ID'],
              srNo: row['Serial Number'],
              id: row['Entity_DBID'],
            },
          ],
          isNew: 0,
        };

        buildIds.push(row['Build ID']);
        objs.push({
          buildId: row['Build ID'],
          srNo: row['Serial Number'],
          id: row['Entity_DBID'],
        });
        skus = [...skus, newSku];
      }
    }

    setBuildIds(buildIds);
    setSelectedBuildIds(buildIds);
    setRemainingBuildIds([]);
    setAllSrNos(objs);

    setSelectedSrNos(objs);
    setRemainingSrNos([]);

    skus.sort((a, b) => a.boxId - b.boxId);

    updateSaveButtonFlag(skus);
    setInitSkuList([...skus]);
    return skus;
  };
  const generateSku_list = (rows) => {
    let skus = [];
    let objs = [];
    let buildIds = [];
    outerLoop: for (const row of rows) {
      let found = 0;
      for (const sku of skus) {
        if (sku['buildId'] == row['Build ID'][0]) {
          sku['obj'].push({
            buildId: row['Build ID'][0],
            srNo: row['Serial Number'][0],
            id: row['Entity_DBID'],
          });
          objs.push({
            buildId: row['Build ID'][0],
            srNo: row['Serial Number'][0],
            id: row['Entity_DBID'],
          });
          found = 1;
          continue outerLoop;
        }
      }
      if (!found) {
        let newSku = {
          boxId: skus.length + 1,
          buildId: row['Build ID'][0],
          ent: [row['Entity_DBID']],
          obj: [
            {
              buildId: row['Build ID'][0],
              srNo: row['Serial Number'][0],
              id: row['Entity_DBID'],
            },
          ],
          isNew: 0,
        };

        buildIds.push(row['Build ID'][0]);
        objs.push({
          buildId: row['Build ID'][0],
          srNo: row['Serial Number'][0],
          id: row['Entity_DBID'],
        });
        skus = [...skus, newSku];
      }
    }
    setBuildIds(buildIds);
    setSelectedBuildIds(buildIds);
    setRemainingBuildIds([]);
    setAllSrNos(objs);
    console.log('remainig build' + JSON.stringify(remainingBuildIds));
    console.log('selected build' + JSON.stringify(selectedBuildIds));

    setSelectedSrNos(objs);
    setRemainingSrNos([]);
    skus.sort((a, b) => a.boxId - b.boxId);
    updateSaveButtonFlag(skus);
    setInitSkuList([...skus]);
    return skus;
  };

  const addBox = () => {
    let skus = [...skuList];
    skus.push({ boxId: skus.length + 1, buildId: '', ent: [], obj: [] });
    updateBoxFlag(skus);
    setskuList(skus);
  };

  const removeBox = (i) => {
    let skus = [...skuList];
    skus.splice(i, 1);
    skus = updateBoxNums(skus);
    updateSrNos(skus);
    updateBoxFlag(skus);
    setskuList(skus);
  };

  const updateBoxNums = (skus) => {
    let i = 1;
    for (const sku of skus) {
      sku['boxId'] = i;
      i++;
    }
    return skus;
  };

  const updateSkus = (skus) => {
    for (const sku of skus) {
      sku['ent'] = sku['obj'].map((s) => s.id);
    }

    if (initSkuList.length < skus.length) {
      let diff = skus.length - initSkuList.length;
      let j = 1;
      for (let i = diff; i > 0; i--) {
        skus[skus.length - j].isNew = 1;
        j = 1;
      }
    }
    return skus;
  };

  const printLabels = () => {
    // code for printing labels
  };

  const savePTO = () => {
    setUserAction('save');

    if (remainingSrNos.length > 0) {
      setReadOnly(true);
      setErrorModal(true);
    } else {
      performSave();
    }
  };

  const performSave = (sendOut = false) => {
    let newOwner = undefined;
    newOwner = users.find((user) => user.UserName === 'Material Storage');

    let skus = updateSkus([...skuList]);

    let finalJSON = {
      currentOwnerId: currentOwner.User_DBID,
      currentParentId: currentLocation.Entity_DBID,
      ownerId: newOwner.User_DBID,
      parentId: parseInt(bake('materialStorageId'), 10),
      matType: 'SSD',
      ids: selectedSrNos.map((srNo) => srNo.id),
      total: selectedSrNos.length,
      comment: initRequestComment === comment ? null : comment,
      sku_list: skus,
      sendToGeek: sendOut,
      skipBoxUpdate: sendOut && props.currentRow?.Status.includes('Error'), // when PTO is in Erro state
      isGeek: 1,
      geekTrId: trId, // TODO: rename geekTrId to trId in param
      updateFlag: updateFlag,
    };

    let message = trId === undefined ? ' created' : ' updated';
    GeekApi('ptocre', finalJSON)
      .then((response) => {
        toast.success(
          'Give It ' + String(response[0].id).padStart(8, 0) + message
        );
        close(true);
      })
      .catch((error) => {
        toast.error(error.message);
        close(true);
      });
  };

  const createPTO = () => {
    setUserAction('create');
    if (remainingSrNos.length > 0) {
      setReadOnly(true);
      setErrorModal(true);
    } else {
      performSave(true);
    }
  };

  const cancelPTO = () => {};

  const handleComments = (val) => {
    setComment(val);
  };

  const updateBoxFlag = (recentSkus) => {
    let skus = recentSkus.length !== 0 ? recentSkus : [...skuList];
    let flag = skus.some((sku) => {
      return sku.obj.length === 0;
    });
    setNewBoxFlag(!flag);
  };

  const updateSaveButtonFlag = (recentSkus) => {
    let skus = recentSkus.length !== 0 ? recentSkus : [...skuList];

    setDisableSave(
      skus.some((sku) => {
        return sku.obj.length === 0;
      })
    );
  };
  const clearRemainingSrs = () => {
    setReadOnly(false);
    setErrorModal(false);
    setRemainingSrNos([]);
    if (userAction === 'save') {
      performSave();
    } else {
      performSave(true);
    }
  };
  const workOnRemainingSrs = () => {
    setReadOnly(false);
    setErrorModal(false);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => close(true)}
        className={errorModal ? 'entity-modal fade-pto' : 'entity-modal'}
        size="lg"
        backdrop="static"
      >
        <Modal.Header closeButton={errorModal ? false : true}>
          <Modal.Title>
            {props.title} {trId ? '' + trId : ''}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="mb-2">
            <div>
              <b>Current Owner: </b> {currentOwner?.UserName}
            </div>
            <div>
              <b>Current Location: </b> {currentLocation?.Path}
            </div>
          </div>
          <div className="box-container">
            <Hint
              placement="top"
              delay={{ show: 250, hide: 400 }}
              title="Add Box"
            >
              <Button
                className="icon-btn"
                type="radio"
                variant="outline-primary"
                onClick={() => addBox()}
                disabled={!editable || !newBoxFlag || readOnly}
              >
                <PlusLg className="export" size={20} />
              </Button>
            </Hint>
          </div>
          <div>
            <table className="pto-tab">
              <thead className="pto-tab-head">
                <tr>
                  <th className="boxno-pto">BoxNo</th>
                  <th className="buildid-pto">BuildID</th>
                  <th className="srno-pto">SerialNo(s)</th>
                  <th className="action-all"></th>
                  <th className="action-pto"></th>
                </tr>
              </thead>
              <tbody className="pto-tab-body">
                {skuList.map((sku, i) => {
                  return (
                    <tr key={i}>
                      <td className="boxno-pto">
                        {' '}
                        <div>
                          {/* {trId} */}
                          {String(sku.boxId).padStart(3, '0')}
                        </div>
                      </td>
                      <td className="buildid-pto">
                        <div>
                          <Autocomplete
                            autoHighlight={true}
                            className="type-ahead"
                            id="parent-entity"
                            options={buildIds}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder="Select..."
                              />
                            )}
                            disabled={!editable || readOnly}
                            onChange={(_event, list, res, buildId) => {
                              handleBuildIdChange(list, i, res, buildId);
                            }}
                            value={sku.buildId || undefined}
                          />
                        </div>
                      </td>
                      <td className="srno-pto">
                        <div>
                          <Autocomplete
                            multiple
                            autoHighlight={true}
                            className="type-ahead"
                            disabled={!editable || readOnly}
                            options={remainingSrNos.filter(
                              (sr) => sr.buildId === sku.buildId
                            )}
                            getOptionLabel={(option) => option.srNo}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="outlined"
                                placeholder={sku.obj.length + ' Selected'}
                              />
                            )}
                            onChange={(_event, list, res, srNo) => {
                              handleSrNoChange(list, i, res, srNo);
                            }}
                            value={sku.obj || []}
                          />
                        </div>
                      </td>
                      <td className="action-all">
                        <Hint
                          placement="top"
                          delay={{
                            show: 250,
                            hide: 400,
                          }}
                          title="Select All"
                        >
                          <span>
                            <Button
                              className="icon-btn add-btn"
                              type="radio"
                              variant="outline-primary"
                              onClick={() => {
                                addAll(i, sku);
                              }}
                              disabled={disableAddAll(sku) || readOnly}
                            >
                              <CheckAll className="export" size={15} />
                            </Button>
                          </span>
                        </Hint>
                      </td>
                      <td className="action-pto">
                        <div>
                          {skuList.length > 1 ? (
                            <Trash
                              color="red"
                              className="pointer"
                              onClick={() => removeBox(i)}
                              disabled={!editable || readOnly}
                              size={18}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          <div className="mb-2">
            <h6>Comments</h6>
            <Form.Control
              className="w-100 overflow-hidden"
              as="textarea"
              rows="1"
              placeholder="Type..."
              onChange={(e) => handleComments(e.target.value)}
              value={comment}
              disabled={readOnly}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="outer">
            <div className="print">
              <Button onClick={() => printLabels()} disabled={readOnly}>
                Print Label(s)
              </Button>
            </div>
            <div className="confirm">
              <Button
                onClick={() => savePTO()}
                disabled={disableSave || readOnly}
              >
                Save
              </Button>
              <Button
                onClick={() => createPTO()}
                disabled={disableSave || readOnly}
              >
                Confirm
              </Button>
              <Button onClick={() => cancelPTO()} disabled={readOnly}>
                Cancel
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>

      {errorModal ? (
        <Modal show={errorModal} onHide={() => close(true)} backdrop="static">
          <ModalBody>
            <p>Are you sure you dont want to save remaining Serial Numbers?</p>
            <ul>
              {remainingSrNos.map((srN, i) => {
                return <li key={i}>{srN.srNo}</li>;
              })}
            </ul>
          </ModalBody>
          <ModalFooter>
            <Button onClick={() => clearRemainingSrs()}>Yes</Button>
            <Button onClick={() => workOnRemainingSrs()}>No</Button>
          </ModalFooter>
        </Modal>
      ) : null}
    </>
  );
}
