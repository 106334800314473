import React from 'react';
import { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { Hint } from './constants';
import Autocomplete from '@mui/material/Autocomplete';
import { Form, Button } from 'react-bootstrap';
import { Api, Cosmos, SendEmail } from './interface';
import { oven } from './common';
import { CheckAll } from 'react-bootstrap-icons';
import Modal from 'react-bootstrap/Modal';
import { IsRole } from './validator';
import { toast } from 'react-toastify';
import DatePicker from './datePicker';
import { datePickerToShortDate } from './date';

export default function TransactionModal(props) {
  const [locations, setLocations] = useState([]);
  const [users, setUsers] = useState([]);
  const [list, setList] = useState(props.list);
  const [summaryData, setSummaryData] = useState(null);
  const [headers, setHeaders] = useState(props.headers);
  const [comment, setComment] = useState(null);
  const [initRequestComment, setInitRequestComment] = useState(null);

  const [body, setBody] = useState(props.body);
  const [newOwner, setNewOwner] = useState({ UserName: '' });
  const [initRequestOwner, setInitRequestOwner] = useState(null);
  const [currentOwner, setCurrentOwner] = useState(null);
  const [currentLocation, setCurrentLocation] = useState([]);
  const [parent, setParent] = useState({ Path: '' });
  const [returnByDate, setReturnByDate] = useState(
    props.currentRow
      ? datePickerToShortDate(props.currentRow?.LoanDueDate)
      : new Date()
  );
  const [additionalFields, setAdditionalFields] = useState([]);

  const [entityList, setEntityList] = useState([]);
  const [selectedEntities, setSelectedEntities] = useState([]);
  const [filteredEntities, setFilteredEntities] = useState([]);

  const [editableFields, setEditableFields] = useState([]);

  const eolList = ['Reclaim', 'Scrap', 'Ship', 'Complete'];

  const bake = (g, c, v) => {
    if (typeof g === 'string') {
      return oven(props, true, g, c);
    } else {
      return oven(props, true, c, v);
    }
  };

  const [shippingFields, setShippingFields] = useState([
    { Field: 'company', Label: 'Company', FieldMeta: 'CompanyName' },
    { Field: 'contact', Label: 'Contact Name', FieldMeta: 'ContactName' },
    { Field: 'email', Label: 'Contact Email', FieldMeta: 'Email' },
    { Field: 'address', Label: 'Address', FieldMeta: 'Address' },
    {
      Field: 'shippingNumber',
      Label: 'Shipping Number',
      FieldMeta: 'ShippingNumber',
    },
  ]);

  const handleOwnerChange = (val) => {
    let newBody = body;
    newBody = { ...newBody, newOwner: val };

    setNewOwner(val);
    setBody(newBody);
    props.updateBody(newBody);
    if (
      val?.User_DBID &&
      initRequestOwner &&
      initRequestOwner?.User_DBID !== val?.User_DBID
    ) {
      submit(newBody);
    }
  };

  const handleLocationChange = (val) => {
    let newBody = body;
    newBody = { ...newBody, parent: val };
    setParent(val);
    setBody(newBody);
    props.updateBody(newBody);
  };

  const updateShippingField = (val, updateField) => {
    let newShippingFields = shippingFields;
    newShippingFields = newShippingFields.map((field) => {
      return field.Field === updateField.Field
        ? { ...field, Value: val }
        : field;
    });
    setShippingFields(newShippingFields);
  };

  const handleShippingFieldChange = (val, field) => {
    let newBody = body;
    newBody = { ...newBody, [field.Field]: val };
    updateShippingField(val, field);
    setBody(newBody);
    props.updateBody(newBody);
  };

  const handleComments = (val, update) => {
    let newBody = body;
    newBody = { ...newBody, comment: val };
    setComment(val);
    setBody(newBody);
    props.updateBody(newBody);

    if (update && initRequestComment && initRequestComment !== val) {
      setComment(val.trim());
      submit(newBody);
    }
  };

  useEffect(() => {
    let newBody = props.body;
    let rows = props.list?.length ? props.list : [props.currentRow];
    Api({
      sp: 'getUsers',
      json: {},
    }).then((users) => {
      setCurrentOwner(
        users.find(
          (user) => user.User_DBID === parseInt(rows[0]?.EntityOwner[0]) ?? '-1'
        )
      );

      // Remove Material Storage as new owner on Got It for regular users
      let nonClassStoreUsers = users.filter(
        (u) => u.UserName !== 'Material Storage'
      );

      setUsers(
        props.type === 'Give' ||
          (props.type === 'Got' && IsRole('Material Storage'))
          ? users
          : nonClassStoreUsers
      );

      let newOwner = undefined;
      if (props.type === 'Reclaim' || props.type === 'Scrap') {
        newOwner = users.find((user) => user.UserName === 'Material Storage');
      }
      if (props.type === 'Ship') {
        newOwner = users.find((user) => user.UserName === 'External User');
      }

      if (props.type === 'Return Loan') {
        newOwner = users.find(
          (user) => user.User_DBID === parseInt(rows[0]['EntityOwner'][0])
        );
      }

      Api({
        sp: 'getNonMaterialPaths',
        json: {},
      }).then((parents) => {
        setLocations(parents);
        let entityPath = rows[0]?.Path;
        let found = rows.find((row) => !row?.Path || row?.Path !== entityPath);
        setCurrentLocation(
          found
            ? { Path: 'Multiple Locations' }
            : props.type === 'Loan'
            ? parents?.find((parent) => {
                if (parent && parent?.Path)
                  return parent.Path === props.summaryData?.[0].Path.trim();
              })
            : parents.find((parent) => {
                if (parent && parent?.Path)
                  return parent.Path === entityPath?.trim();
              })
        );
        let parent = undefined;
        if (props.type === 'Reclaim' || props.type === 'Scrap') {
          parent = parents.find(
            (parent) =>
              parent.Entity_DBID === parseInt(bake('materialStorageId'), 10)
          );
        }
        // for Loan transactions, set new location
        if (props.type === 'Loan') {
          parent = parents.find(
            (parent) =>
              parent.Path === props.summaryData?.[0][`New Location`]?.trim()
          );
        }
        if (props.type === 'Ship') {
          parent = parents.find(
            (parent) =>
              parent.Entity_DBID === parseInt(bake('externalSiteId'), 10)
          );
        }
        if (props.txnType === 'GotIt') {
          newOwner = users.find(
            (item) => item.UserName === props.currentRow['New Owner']
          );
          if (props.body.status === 'Ship') {
            let newShippingFields = shippingFields;
            newShippingFields = newShippingFields.map((field) => {
              return {
                ...field,
                Value: props.body[field.FieldMeta],
              };
            });
            setShippingFields(newShippingFields);
          }
          setInitRequestOwner(newOwner);
          setInitRequestComment(props.currentRow['Comments']);
          setComment(props.currentRow['Comments']);
        }
        setParent(parent);
        setNewOwner(newOwner);
        newBody = { ...newBody, parent: parent };
        newBody = { ...newBody, newOwner: newOwner };
        setBody(newBody);
      });
    });
    if (props.txnType === 'GotIt') {
      if (rows[0].Status !== 'Complete') {
        Api({
          sp: 'getTransactionListDataGeekTemp',
          json: {
            transactionId: parseInt(props.currentRow['Transaction_DBID'], 10),
          },
        }).then((response) => {
          response.forEach((r) => {
            r.id = r['Build ID'] + ' - (' + r['Serial Number'] + ')';
          });
          response = response.sort((a, b) => {
            return a.id > b.id ? 1 : -1;
          });
          setEntityList(response);
          setFilteredEntities(response);
        });
      }
    }
    setList(rows);
    setSummaryData(props.summaryData);
    setAdditionalFields(props.body['additionalFields']);
    setHeaders(props.headers);
  }, [props.list]);

  useEffect(() => {
    setBody(props.body);
    setNewOwner(props.body['newOwner']);
  }, [props]);

  const selectAll = (field) => {
    setFilteredEntities([]);
    setSelectedEntities(entityList);

    let newBody = {
      ...body,
      additionalFields: additionalFields.map((f) => {
        return f.fieldName === field.fieldName
          ? { ...f, selectedValues: entityList }
          : f;
      }),
    };
    setBody(newBody);
    props.updateBody(newBody);
  };

  const updateAdditionalField = (field, newValue) => {
    let newFields = [];

    setFilteredEntities(
      entityList.filter(
        (obj) => !newValue.map((e) => e.Entity_DBID).includes(obj.Entity_DBID)
      )
    );
    setSelectedEntities(newValue);

    newFields = additionalFields.map((f) => {
      return f.fieldName === field.fieldName
        ? { ...f, selectedValues: newValue }
        : f;
    });
    let newBody = { ...body, additionalFields: newFields };
    setBody(newBody);
    props.updateBody(newBody);
  };

  const confirmDisabled = () => {
    if (props.txnType === 'GiveIt') {
      if (['Got', 'Loan'].includes(props.type)) {
        return !newOwner || !parent;
      }

      if (!newOwner) {
        return true;
      } else if (props.matType === 'Component') {
        return !body.totalVal || body.totalVal < 1;
      } else return false;
    }

    // only Material Storage can accept EOL materials
    if (!IsRole('Material Storage') && eolList.includes(body.status)) {
      return true;
    }
    // External Shipment required fields
    if (props.type === 'Ship') {
      let shipCheck = false;
      shippingFields.forEach((field) => {
        if (
          field.Field !== 'company' &&
          (!field?.Value || field?.Value === '')
        ) {
          shipCheck = true;
        }
      });
      return shipCheck;
    }

    // check if any of the additional fields have been updated, if yes, then true
    let additionalFieldsCheck = additionalFields?.find((field) =>
      field.fieldType === 'list' ? field.selectedValues?.length > 0 : field
    )
      ? true
      : false;

    let updatedInitFieldsFlag =
      initRequestComment !== comment ||
      initRequestOwner?.User_DBID !== newOwner?.User_DBID;

    if (!newOwner) {
      return true;
    } else {
      //all fields are present - owner, location, additional fields
      if ((additionalFields && additionalFieldsCheck) || parent) {
        return !(additionalFieldsCheck && parent);
        //if no entity is selected for "got it", it is partial update, thus, initial fields must be updated
      } else if (!additionalFieldsCheck) return !updatedInitFieldsFlag;
      else return true;
    }
  };

  const gotActionDisabled = () => {
    let additionalFieldsCheck = additionalFields?.find((field) =>
      field.fieldType === 'list' ? selectedEntities?.length > 0 : field
    )
      ? true
      : false;

    let loanOwnerCheck = bake('user')['oid'] === currentOwner?.OID;

    if (!additionalFields) {
      return !newOwner || !parent;
    } else
      return (
        !newOwner ||
        !parent ||
        !(additionalFields && additionalFieldsCheck) ||
        (props.type === 'Loan' ? !loanOwnerCheck : false)
      );
  };

  const submit = (newBody) => {
    let spBody = {};
    let updateOwner = newOwner;
    let updateComment = comment;
    let partialUpdateFlag = true;
    //for partial update neBody will be an object with values
    //for complete transaction, newBidy will have action "Accepted" or "Returned"
    let action = typeof newBody === 'string' ? newBody : '';
    // for partial update of transactions, newBody will have updated owner/comments
    if (newBody && typeof newBody !== 'string') {
      updateOwner = newBody.newOwner;
      updateComment = newBody.comment;
    }
    //jsonBody initial parameters
    let jsonBody = {
      ownerId:
        initRequestOwner?.User_DBID !== updateOwner.User_DBID
          ? updateOwner.User_DBID
          : '',
      parentId: parent ? parent.Entity_DBID : '',
      initRequestOwner: initRequestOwner ?? currentOwner,
      comment: initRequestComment === updateComment ? '' : updateComment,
      matType: props.matType,
    };
    //if the transaction is "Ship", add shipping fields in the jsonBody
    shippingFields.forEach((field) => {
      jsonBody = { ...jsonBody, [field.Field]: field.Value };
    });
    //what is the transactin type, if the transaction is Internal Give it
    if (props.txnType === 'GiveIt') {
      let transactionType = props.type === 'Give' ? 'Give It' : props.type;
      jsonBody = {
        ...jsonBody,
        //if transaction is component, it will have editable field of quantity
        //if the transaction is SSD, it will have the list of selected SSDs
        ids:
          editableFields?.length > 0
            ? editableFields
                ?.filter((field) => field.val > 0)
                ?.map((x) => x.Entity_DBID)
            : list.map((x) => x.Entity_DBID),
        //in case of Grab It
        type: props.type === 'Got' ? null : transactionType,
        //for components transaction, only select lots with quantity > 0 provided by the user
        editableFields: editableFields?.filter((field) => field.val > 0),
        //for components the total quantity will be sum of quantity selected by user for each lot
        //for SSD transactions, the total quantity will be the number of SSDs selected
        totalVal: body.totalVal ?? list.length,
        returnbydate:
          props.type === 'Loan' ? datePickerToShortDate(returnByDate) : null,
      };
      spBody = {
        sp:
          props.type === 'Got'
            ? //no transaction is created/tracked in case of Grab It
              'updateTransactionGotIt'
            : 'addTransactionGiveIt',
        jsonBody: jsonBody,
      };
      //If the Got It done from Transactions Page
    } else if (props.txnType === 'GotIt') {
      jsonBody = {
        ...jsonBody,
        action: action,
        transactionId: body.transactionId,
        giveType:
          body.status === 'Give It'
            ? 'In Inventory'
            : body.status === 'Reclaim'
            ? 'Reclaimed'
            : body.status === 'Scrap'
            ? 'Scrapped'
            : null,
      };
      if (parent || newBody === 'Returned') {
        partialUpdateFlag = false;
      }

      jsonBody = {
        ...jsonBody,
        //when Give it is cretaed for components, a new child lot is created
        //when performing a got it on components transaction, that child lot needs to be accepted/returned
        //in case of SSDs, the list of drives selected need to be accepted/returned
        selectedEntities: summaryData?.[0]?.ChildEntity_DBID
          ? [{ Entity_DBID: summaryData?.[0]?.ChildEntity_DBID }]
          : selectedEntities,
      };

      spBody = {
        sp: 'updateTransaction',
        jsonBody: jsonBody,
        partialUpdateFlag: partialUpdateFlag,
      };
    }

    Api({
      sp: spBody.sp,
      json: spBody.jsonBody,
    }).then((response) => {
      if (props.txnType === 'GiveIt') {
        const transactionType = spBody.jsonBody.type;
        const transactionId = response[0]?.Transaction_DBID ?? 'Grab It';
        const newEntityId = response[0]?.NewEntity_DBID ?? undefined;
        const newLotQuantity = response[0]?.Total ?? undefined;

        if (props.type === 'Got') {
          let previousLocation = currentLocation?.Path;
          toast.success('Got It completed');

          SendEmail('instantEmailGrabIt', {
            emailSubject: 'Got It Completed',
            previousOwnerId: spBody.jsonBody.initRequestOwner.User_DBID,
            newOwnerId: newOwner.User_DBID,
            previousLocation: previousLocation, // Tough to get this cleanly, sort of a hack
            newLocation: parent.Path,
            comment: comment ?? 'None',
            entityIds: spBody.jsonBody.ids,
          });
        } else {
          toast.success(
            transactionType +
              ' ' +
              transactionId.toString().padStart(8, '0') +
              ' created'
          );

          SendEmail('instantEmailGiveGotRequest', {
            emailSubject:
              transactionType + ' ' + transactionId.toString().padStart(8, '0'),
            transactionId: transactionId,
            status: 'In Inventory → ' + transactionType,
            action: action,
          });

          // Add transaction lineage information to Cosmos (Component Only)
          if (['Component'].includes(props.matType)) {
            Cosmos({
              cosmosApi: 'addLineageDocument',
              containerId: 'entityLineage',
              partitonKey: spBody.jsonBody.matType,
              lineageDoc: {
                transactionDBID: transactionId,
                entityDBID: newEntityId,
                lotQuantity: newLotQuantity,
                parentSplit: spBody.jsonBody.editableFields.map((obj) => ({
                  entityDBID: obj.Entity_DBID,
                  splitQuantity: obj.val,
                })),
              },
            });
          }
        }
      }

      if (props.txnType === 'GotIt') {
        toast.success('Give request updated');
        const transactionId = body.transactionId;
        let appendedStatus = spBody.partialUpdateFlag ? '' : ' → Complete';

        let emailJSON = {
          emailSubject: 'Got It ' + transactionId.toString().padStart(8, '0'),
          transactionId: transactionId,
          status: body.status + appendedStatus,
          action: action,
          selectedEntities: selectedEntities?.length ? selectedEntities : [],
        };
        additionalFields?.forEach((field) => {
          emailJSON = {
            ...emailJSON,
            [field.fieldParam]: field.selectedValues?.length,
          };
        });
        SendEmail('instantEmailGiveGotRequest', emailJSON);
      }

      let updatedList = entityList?.filter(
        (obj) =>
          !selectedEntities?.map((e) => e.Entity_DBID).includes(obj.Entity_DBID)
      );

      setEntityList(updatedList);
      setFilteredEntities(updatedList);
      setSelectedEntities([]);

      if (
        !updatedList ||
        !updatedList?.length ||
        updatedList?.[0]?.ChildEntity_DBID
      ) {
        close(true);
      }

      setInitRequestComment(updateComment);
      setInitRequestOwner(updateOwner);
    });
  };

  const updateEditField = (val, header, item) => {
    if (parseInt(val) > parseInt(item[header.label])) {
      toast.error('Value cannot be higher than available');
      return;
    } else {
      val = isNaN(parseInt(val)) ? 0 : parseInt(val);

      let newBody = body;
      let newEditableFields = editableFields;
      let entityField = newEditableFields?.filter(
        (field) => field.Entity_DBID === item.Entity_DBID
      )[0];
      if (entityField) {
        newEditableFields.filter(
          (field) => field.Entity_DBID === item.Entity_DBID
        )[0].val = val;
      } else {
        entityField = { Entity_DBID: item.Entity_DBID, val: val };
        newEditableFields.push(entityField);
      }

      setEditableFields(newEditableFields);
      let totalVal = 0;
      newEditableFields.forEach((id) => {
        totalVal = totalVal + id.val;
      });
      newBody = {
        ...newBody,
        editableFields: newEditableFields,
        totalVal: totalVal,
      };

      setBody(newBody);
      props.updateBody(newBody);
    }
  };

  const close = (refresh) => {
    setSummaryData(null);
    props.close(refresh);
  };

  return (
    <Modal
      show={props.show}
      onHide={() => close(true)}
      className="entity-modal"
      size="lg"
    >
      <Modal.Header closeButton="true">
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <div className="mb-2">
          <div>
            <b>Current Owner: </b> {currentOwner?.UserName}
          </div>
          <div>
            <b>Current Location: </b> {currentLocation?.Path}
          </div>
          {summaryData?.[0]?.['Component Name'] ? (
            <div>
              <b>Component Name: </b> {summaryData[0]['Component Name']}
            </div>
          ) : null}
          {summaryData?.[0]?.['ChildEntity_DBID'] ? (
            <div>
              <b>New Transaction Lot ID: </b>{' '}
              {'LOT' +
                String(summaryData[0]['ChildEntity_DBID']).padStart(8, 0)}
            </div>
          ) : null}
        </div>
        <div className="give-table">
          <table className="table give">
            <thead>
              <tr>
                {headers?.map((header, i) => {
                  return !header.show ? null : <th key={i}>{header.label}</th>;
                })}
              </tr>
            </thead>
            <tbody>
              {summaryData?.map((item, id) => (
                <tr key={id}>
                  {headers?.map((header, i) => {
                    return !header.show ? null : !header.edit ? (
                      <td key={i}>{item[header.field]}</td>
                    ) : (
                      <td
                        key={i}
                        style={{
                          height: '70px',
                        }}
                      >
                        <Form.Control
                          key={i}
                          className="w-100 overflow-hidden"
                          as="textarea"
                          rows="1"
                          value={
                            editableFields?.filter(
                              (x) => x.Entity_DBID === item.Entity_DBID
                            )[0]?.val || 0
                          }
                          onChange={(e) =>
                            updateEditField(e.target.value, header, item)
                          }
                        />
                        <p className="maxQty">
                          Current {header.label}: {item[header.label]}
                        </p>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {props.type !== 'Ship' ? (
          body.status === 'Complete' ? null : (
            <>
              <div className="mb-2">
                <h6>
                  {props.type === 'Loan' ? 'Borrower' : 'Select New Owner'}
                </h6>
                <Autocomplete
                  autoHighlight={true}
                  className="type-ahead"
                  id="entity-type"
                  options={users}
                  getOptionLabel={(option) => option.UserName}
                  hiddenlabel="true"
                  disabled={
                    eolList.includes(props.type) || props.type === 'Return Loan'
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Owner"
                      disabled={
                        eolList.includes(props.type) ||
                        props.type === 'Return Loan'
                      }
                    />
                  )}
                  onChange={(_event, newType) => {
                    handleOwnerChange(newType);
                  }}
                  value={newOwner}
                  readOnly={
                    eolList.includes(props.type) || props.type === 'Return Loan'
                  }
                />
              </div>
              {props.type === 'Loan' ? (
                <div className="mb-2">
                  <h6>Loan Due Date</h6>
                  <DatePicker
                    renderInput={(props) => <TextField {...props} />}
                    label="Event Start Time"
                    value={returnByDate ?? new Date()}
                    update={(val) => setReturnByDate(val)}
                    minDateTime={new Date()}
                  />
                </div>
              ) : null}
              {props.txnType === 'GotIt' ||
              ['Got', 'Loan'].includes(props.type) ? (
                <div className="mb-2">
                  <h6>Select New Location</h6>
                  <Autocomplete
                    autoHighlight={true}
                    className="type-ahead"
                    id="parent-entity"
                    options={locations}
                    getOptionLabel={(option) => option.Path ?? ''}
                    hiddenlabel="true"
                    disabled={eolList.includes(props.type)}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        placeholder="Select Location"
                        disabled={eolList.includes(props.type)}
                      />
                    )}
                    onChange={(_event, newParent) => {
                      handleLocationChange(newParent);
                    }}
                    value={parent}
                    readOnly={eolList.includes(props.type)}
                  />
                </div>
              ) : null}
            </>
          )
        ) : (
          <>
            {shippingFields.map((field, i) => {
              return (
                <div className="mb-2" key={i}>
                  <h6>{field.Label}</h6>
                  <Form.Control
                    className="w-100 overflow-hidden"
                    as="textarea"
                    rows="1"
                    onChange={(e) => updateShippingField(e.target.value, field)}
                    onBlur={(e) => {
                      handleShippingFieldChange(e.target.value, field);
                    }}
                    value={field.Value || body[field.FieldMeta] || ''}
                  />
                </div>
              );
            })}
          </>
        )}
        {additionalFields?.length
          ? additionalFields?.map((field, i) => {
              return field.fieldType === 'list' ? (
                <div key={i}>
                  <h6>{field.fieldName}</h6>
                  {body.status !== 'Complete' ? (
                    <div className="accept-list mb-2">
                      <Autocomplete
                        autoHighlight={true}
                        multiple
                        className="type-ahead"
                        id="entity-type"
                        options={filteredEntities}
                        getOptionLabel={(option) => option.id}
                        hiddenlabel="true"
                        disabled={body.status === 'Complete'}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Select"
                            disabled={body.status === 'Complete'}
                          />
                        )}
                        onChange={(_event, newType) => {
                          updateAdditionalField(field, newType);
                        }}
                        value={selectedEntities || []}
                        includeInputInList={true}
                      />
                    </div>
                  ) : (
                    <div className="accept-list-ro mb-2">
                      <br />
                      {field.selectedValues?.map((v, i) => {
                        return (
                          <span key={i} className="search-meta-value">
                            {'' + v[field.fieldMeta]} <br />
                          </span>
                        );
                      })}
                    </div>
                  )}
                  {body.status === 'Complete' ? null : (
                    <div className="check-all">
                      <Hint
                        placement="top"
                        delay={{ show: 250, hide: 400 }}
                        title="Select All"
                      >
                        <span>
                          <Button
                            className="icon-btn"
                            type="radio"
                            variant="outline-primary"
                            onClick={() => {
                              selectAll(field);
                            }}
                            disabled={false}
                          >
                            <CheckAll className="export" size={15} />
                          </Button>
                        </span>
                      </Hint>
                    </div>
                  )}
                </div>
              ) : null;
            })
          : null}
        <div className="mb-2">
          <h6>Comments</h6>
          <Form.Control
            className="w-100 overflow-hidden"
            as="textarea"
            rows="1"
            placeholder="Type..."
            // need keyup for tab/enter detection, blur for save, and change for value control on local element
            onChange={(e) => handleComments(e.target.value)}
            onBlur={(e) => handleComments(e.target.value, true)}
            disabled={body.status === 'Complete'}
            value={comment || body.comment || ''}
          />
        </div>
        <Modal.Footer>
          {body.status === 'Complete' ? null : (
            <div className="outer">
              <div className="confirm">
                {props.txnType === 'GotIt' ? (
                  <>
                    <Button
                      variant="outline-danger"
                      onClick={(e) => {
                        e.currentTarget.disabled = true;
                        submit('Returned');
                      }}
                      disabled={gotActionDisabled()}
                    >
                      Return
                    </Button>

                    <Button
                      variant="outline-primary"
                      onClick={(e) => {
                        e.currentTarget.disabled = true;
                        submit('Accepted');
                      }}
                      disabled={gotActionDisabled()}
                    >
                      Accept
                    </Button>
                  </>
                ) : (
                  <>
                    <Button variant="outline-primary" onClick={() => close()}>
                      Cancel
                    </Button>

                    <Button
                      variant="primary"
                      onClick={(e) => {
                        e.currentTarget.disabled = true;
                        submit('Give It');
                      }}
                      disabled={confirmDisabled()}
                    >
                      Confirm
                    </Button>
                  </>
                )}
              </div>
            </div>
          )}
        </Modal.Footer>
      </Modal.Body>
    </Modal>
  );
}
