import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Api } from './interface';
import { Button } from 'react-bootstrap';
import { Download } from 'react-bootstrap-icons';
import { Hint } from './constants.js';
import { getDateTime } from './date';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { shortDtm } from './date';

export default function TransactionDetails(props) {
  const [history, setHistory] = useState([]);
  const [details, setDetails] = useState([]);
  const [summary, setSummary] = useState([]);

  const [list] = useState([
    'Current Owner',
    'New Owner',
    'Current Location',
    'Comments',
    'Total',
  ]);

  const [itemCols] = useState(['Build ID', 'Serial Number']);
  const [qtyCols, setQtyCols] = useState([]);

  useEffect(() => {
    Api({
      sp: 'getTransactionHistory',
      json: { id: parseInt(props.row.Transaction_DBID) },
    }).then((response) => {
      response.forEach((r) => {
        r['Timestamp'] = shortDtm(r['Timestamp']);
      });
      setHistory(response);
    });
    Api({
      sp: 'getTransactionDetails',
      json: { id: parseInt(props.row.Transaction_DBID) },
    }).then((response) => {
      setQtyCols([response[0]['Field'], 'Quantity']);
      setDetails(response);
      setSummary(sumDetails(response));
    });
  }, []);

  const sumDetails = (inputs) =>
    Object.values(
      inputs.reduce((a, { Field, FieldValue, Quantity }) => {
        const key = `${Field}|${FieldValue}`;
        return {
          ...a,
          [key]: {
            Field,
            FieldValue,
            Quantity: ((a[key] && a[key].Quantity) || 0) + Quantity,
          },
        };
        // eslint-disable-next-line prettier/prettier
      }, {})
    );

  const downloadDetailsCSV = () => {
    {
      let csvContent = 'Transaction,' + props.row.Transaction_DBID + ',';
      csvContent +=
        'Current Owner,' +
        String(props.row['Current Owner'] ?? '').replace(',', ' ') +
        '\n';
      csvContent += 'Current Location,' + props.row['Current Location'] + ',';
      csvContent +=
        'New Owner,' +
        String(props.row['New Owner'] ?? '').replace(',', ' ') +
        '\n';
      csvContent += '\n\n\n';

      csvContent += itemCols.join(',') + '\n';
      details.forEach((row) => {
        let csvRow = [row['FieldValue'], row['Serial Number']];
        csvContent += csvRow.join(',') + '\n';
      });
      console.log(csvContent, props);
      var blob = new Blob([csvContent], {
        type: 'text/csv;charset=utf-8,',
      });

      // Programatically create a link and click it:
      var a = document.createElement('a');
      a.href = URL.createObjectURL(blob);
      let fileName =
        'Transaction_Summary' + props.row.Transaction_DBID + getDateTime();
      a.download = `${fileName}.csv`;
      a.click();
    }
  };
  return (
    <Modal
      show={props.show}
      onHide={() => props.closeModal()}
      dialogClassName="modal-60w"
    >
      <Modal.Header closeButton="true">
        <Modal.Title>
          {props.row.Transaction_DBID} Transaction Details
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-between">
          <div className="modal-box">
            <h5>Summary</h5>
            <ul className="list-unstyled">
              {list.map((l, i) => {
                return (
                  <li key={i}>
                    <h6>{l}</h6>
                    <li className="mb-2">{props.row[l]}</li>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className="detail-modal-box">
            <div className="detail-modal-box">
              <h5>Quantities</h5>
              <div>
                <Paper
                  sx={{ width: '100%' }}
                  className="transaction-detail-history"
                >
                  <TableContainer
                    component={Paper}
                    className="tableContainer"
                    id="tab-container"
                  >
                    <Table stickyHeader aria-label="sticky table">
                      {/* Column Headers */}
                      <TableHead>
                        <TableRow>
                          {qtyCols.map((column, i) => (
                            <TableCell
                              key={i}
                              align={'left'}
                              padding={'normal'}
                            >
                              <span className={'col-header table-header pr-2'}>
                                {column}
                              </span>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>

                      {/* Table Content */}
                      <TableBody>
                        {summary.map((detail, i) => {
                          return (
                            <TableRow
                              //   hover
                              role="checkbox"
                              tabIndex={-1}
                              key={i}
                            >
                              <TableCell align={'left'}>
                                {detail['FieldValue']}
                              </TableCell>
                              <TableCell align={'left'}>
                                {detail['Quantity']}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Paper>
              </div>
            </div>
            <br />
            {details.length && details[0]['Serial Number'] ? (
              <div className="detail-modal-box">
                <div className="item-header">
                  <h5>Items</h5>
                  <Hint
                    placement="top"
                    delay={{ show: 250, hide: 400 }}
                    title="Export to Excel"
                  >
                    <Button
                      className="icon-btn"
                      type="radio"
                      variant="outline-primary"
                      onClick={() => downloadDetailsCSV()}
                    >
                      <Download className="export" size={20} />
                    </Button>
                  </Hint>
                </div>

                <div>
                  <Paper
                    sx={{ width: '100%' }}
                    className="transaction-detail-history"
                  >
                    <TableContainer
                      component={Paper}
                      className="tableContainer"
                      id="tab-container"
                    >
                      <Table stickyHeader aria-label="sticky table">
                        {/* Column Headers */}
                        <TableHead>
                          <TableRow>
                            {itemCols.map((column, i) => (
                              <TableCell
                                key={i}
                                align={'left'}
                                padding={'normal'}
                              >
                                <span
                                  className={'col-header table-header pr-2'}
                                >
                                  {column}
                                </span>
                              </TableCell>
                            ))}
                          </TableRow>
                        </TableHead>

                        {/* Table Content */}
                        <TableBody>
                          {details.map((detail, i) => {
                            return (
                              <TableRow
                                // hover
                                role="checkbox"
                                tabIndex={-1}
                                key={i}
                              >
                                <TableCell align={'left'}>
                                  {detail['FieldValue']}
                                </TableCell>
                                <TableCell align={'left'}>
                                  {detail['Serial Number']}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        {history.length ? (
          <div className="history-modal-box">
            <h5>History</h5>
            <Paper
              sx={{ width: '100%' }}
              className="transaction-detail-history"
            >
              <TableContainer
                component={Paper}
                className="tableContainer"
                id="tab-container"
              >
                <Table stickyHeader aria-label="sticky table">
                  {/* Column Headers */}
                  <TableHead>
                    <TableRow>
                      {Object.keys(history[0])
                        .map((x) => ({ id: x }))
                        .map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.numeric ? 'right' : 'left'}
                            padding={column.disablePadding ? 'none' : 'normal'}
                          >
                            <span className={'col-header table-header pr-2'}>
                              {column.id}
                            </span>
                          </TableCell>
                        ))}
                    </TableRow>
                  </TableHead>

                  {/* Table Content */}
                  <TableBody>
                    {history.map((row, id) => {
                      return (
                        <TableRow role="checkbox" tabIndex={-1} key={id}>
                          {Object.keys(history[0])
                            .map((x) => ({ id: x }))
                            .map((column) => {
                              const value = row[column.id];
                              return column.id === 'Location' ? (
                                <TableCell key={column.id} align={column.align}>
                                  {value?.split(' > ').map((v, i) => {
                                    return (
                                      <span
                                        key={i}
                                        className="search-meta-value"
                                      >
                                        {'' + v}
                                      </span>
                                    );
                                  })}
                                </TableCell>
                              ) : (
                                <TableCell key={column.id} align={column.align}>
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </TableCell>
                              );
                            })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </div>
        ) : null}
      </Modal.Body>
    </Modal>
  );
}
