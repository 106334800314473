import React from 'react';
import { useEffect, useState } from 'react';
import { Api, GeekApi } from './interface';
import Modal from 'react-bootstrap/Modal';
import { Button } from 'react-bootstrap';

import { toast } from 'react-toastify';

export default function GeekPickUpOrder(props) {
  const [transaction, setTransaction] = useState(props.transaction);
  const [transactionData, setTransactionData] = useState([]);

  const [headers, setHeaders] = useState(props.headers);
  const [totalUnits, setTotalUnits] = useState();
  const [selectedEntities, setSelectedEntities] = useState(
    props.selectedEntities
  );
  useEffect(() => {
    setTransaction(props.transaction);
    setHeaders(props.headers);

    let txn = transaction ?? props.transaction;
    if (txn) {
      Api({
        sp: 'getTransactionRequestData',
        json: { transactionId: parseInt(txn['Transaction_DBID'], 10) },
      }).then((response) => {
        setTransactionData(response);
        setTotalUnits(
          response.reduce((total, item) => total + item['Reserved'], 0)
        );
      });
    } else if (props.selectedEntities?.length) {
      setSelectedEntities(props.selectedEntities);
      setTotalUnits(selectedEntities?.length);
    }
  }, [props.Transaction, props.selectedEntities]);

  const geekCancelPickUp = () => {
    let order_list = [];
    // aligning data with G+ API
    order_list.push({
      out_order_code: transaction?.['Transaction_DBID'],
    });
    // send geekObj to interact with PKOCNL endpoint in swt-server
    let geekObj = {
      order_list: order_list,
    };
    GeekApi('pkocnl', geekObj)
      .then(() => {
        toast.success(transaction?.['Transaction_DBID'] + ' Pickup Cancelled');
        close(true);
      })
      .catch(() => toast.error('Unexpected Error'));
  };

  const geekPickUp = () => {
    if (props.geekPickupCancelFlag) {
      geekCancelPickUp();
    } else {
      let sku_list = [];
      transactionData.forEach((data) =>
        sku_list.push({
          sku_code: data['Identifier'],
          amount: data['Reserved'],
        })
      );
      let geekObj = {
        out_order_code: transaction['Transaction_DBID'],
        sku_list: sku_list,
      };
      //send the geekObj to geekpickup endpoint
      GeekApi('pkocre', geekObj)
        .then(() => {
          //todo: handle error based on status
          toast.success(totalUnits + ' sent for Pickup from Geek');
          close(true);
        })
        .catch(() => toast.error('Unexpected Error'));
    }
  };

  const close = (refresh) => {
    props.closeModal(refresh);
  };

  return (
    <>
      <Modal
        show={props.show}
        onHide={() => close(true)}
        className="entity-modal"
        size="lg"
      >
        <Modal.Header closeButton="true">
          <Modal.Title>
            Pickup {props.geekPickupCancelFlag ? ' Cancel ' : null} from Geek
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="mb-2">
            <div>
              <b>Total Quantity: </b> {totalUnits}
            </div>
            {transaction?.['Transaction_DBID'] ? (
              <div>
                <b>Transaction ID: </b> {transaction?.['Transaction_DBID']}
              </div>
            ) : null}
          </div>
          <div className="give-table">
            <table className="table give">
              <thead>
                <tr>
                  {headers?.map((header, i) => {
                    return !header.show ? null : (
                      <th key={i}>{header.label}</th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {transactionData?.map((item, id) => (
                  <tr key={id}>
                    <td>{item['Identifier']}</td>
                    <td>{item['Reserved']}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="outer">
            <div className="confirm">
              <Button onClick={() => geekPickUp()}>Confirm</Button>
              <Button onClick={close}>Cancel</Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
