import React, { Component } from 'react';
//import Button from "react-bootstrap/Button";
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { Button } from 'react-bootstrap';
import { Api, Cosmos } from './interface';
import { toast } from 'react-toastify';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

class EntityCreate extends Component {
  constructor(props) {
    super(props);
    // const cookies = new Cookies();
    this.tableCellHover = [
      {
        '&:hover': {
          backgroundColor: '#BDBDBD',
        },
      },
    ];

    this.textInput = React.createRef();
    this.typeInput = React.createRef();
    this.commentInput = React.createRef();
    this.historyRef = React.createRef();
    this.selectedEntityType;
    this.state = {
      user: JSON.parse(localStorage.getItem('user')),
      entities: [],
      users: [],
      parentEntityList: [],
      items: [],
      filteredItems: [],
      entityTypes: [],
      template: [],
      filteredTemplate: [],
      statuses: [],
      metaOptions: [],
      fieldArray: [],
      currentEntity: {},
      currentEntityId: -1,
      isOpen: false,
      editing: false,
      cloning: false,
      creating: this.props.creating,
      deleting: false,
      statusEdit: -1,
      commenting: false,
      showHistory: true,
      comment: '',
      currentGroupChildren: [],
      currentHistory: [],
      filteredHistory: [],
      currentFilterText: '',
      filterTerm: '',
      filteredRows: [],
      rawTemplate: [],
      newEntityName: '',
      newEntityTotalQuantity: '',
      newEntityCurrentQuantity: '',
      newProjName: '',
      owner: null,
      entityOwner: null,
      allValidQty: true,
      sourceColumns: [
        { id: 'EntityName', label: 'Source Name', minWidth: 300 },
        { id: 'Quantity', label: 'Quantity', minWidth: 100, align: 'left' },
      ],
      lotId: null,
      componentQty: null,
      newEntityParent: null,
      srNo: null,
      fields: [],
    };
  }

  freeValues() {
    this.setState({
      newEPOMat: null,
      newProjName: '',
      owner: null,
      newEPOQty: '',
      entityOwner: null,
      lotId: null,
      componentQty: null,
      newEntityParent: null,
      srNo: null,
      parentEntityList: [],
      newEntityType: null,
      fields: [],
    });
  }

  componentDidMount() {
    this.freeValues();

    Api({
      sp: 'getEntityTypes',
      json: {},
    }).then((types) => {
      types = types.filter((x) => {
        return this.props.level.length
          ? x.Level === this.props.level
          : ![
              'Material',
              'Location',
              'Site',
              'Geo',
              'Lab',
              'Building',
              'Floor',
            ].includes(x.Level);
      });

      if (
        this.props.type ||
        ['Geo', 'Lab', 'Building', 'Floor'].includes(this.props.level)
      ) {
        let newEntiyType = null;

        if (types.length === 1) {
          newEntiyType = types[0];
        } else {
          newEntiyType = types.find(
            (type) => type.EntityType === this.props.type
          );
        }
        this.setState({ newEntityType: newEntiyType });

        this.updateParentList(newEntiyType);

        Api({
          sp: 'getEntityCreateFieldsByType',
          json: { typeId: newEntiyType.EntityType_DBID },
        }).then((response) => {
          this.setState({ fields: response });
        });
      }

      Api({
        sp: 'getUsers',
        json: {},
      }).then((users) => {
        this.setState({
          users: users,
          entityTypes: types,
        });

        if (
          !this.props.type &&
          !['Geo', 'Lab', 'Building', 'Floor'].includes(this.props.level)
        ) {
          // material type
          this.setState({ newEntityType: null });
        }

        if (this.state.user) {
          let OID = this.state.user.oid;
          let user = users.find((user) => user.OID === OID);
          this.setState({ entityOwner: user });
        }
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({
        creating: this.props.creating,
        renaming: this.props.renaming,
      });
    }
  }

  updateParentList = (item) => {
    if (!item) {
      return;
    }
    Api({
      sp: 'getNonMaterialPaths',
      json: { id: item.EntityType_DBID },
    }).then((parents) => {
      if (this.props.level === 'Geo') {
        parents = [
          {
            Entity_DBID: 0,
            EntityName: 'No Parent',
            EntityType_DBID: 0,
            Path: 'No Parent',
          },
          ...parents,
        ];
      }
      this.setState({
        parentEntityList: [...parents],
        newEntityType: item,
        newEntityParent: null,
      });
    });
  };

  addEntity = (edit, target) => {
    let fields = this.state.fields.filter((f) => f.IsUnique === 1);
    if (fields.length > 0) {
      Api({
        sp: 'getDuplicateEntity',
        json: {
          fields: fields,
        },
      }).then((r) => {
        if (r.length > 0) {
          let obj = r[0];
          toast.error(
            obj.EntityName +
              ' has the same ' +
              obj.MetaValue +
              ' for ' +
              obj.EntityMetaType
          );
          target.disabled = false;
        } else {
          this.createEntity(edit);
        }
      });
    } else {
      this.createEntity(edit);
    }
  };

  createEntity = (edit) => {
    let user = this.state.entityOwner;
    if (!user) {
      // we can remove this as owner is a mandatory field now
      let OID = this.state.user.oid;
      user = this.state.users.find((user) => user.OID === OID);
    }
    Api({
      sp: 'addEntity',
      json: {
        name: this.state.newEntityName,
        typeId: this.state.newEntityType.EntityType_DBID,
        parentId: this.state.newEntityParent?.Entity_DBID ?? 0,
        owner: user?.User_DBID,
        matFlag: this.props.type ? 1 : 0,
        fields: this.state.fields,
      },
    }).then((response) => {
      if (response[0].error) {
        toast.error(response[0].error);
      } else {
        toast.success(response[0].EntityName + ' successfully created');
        this.props.closeModal(edit ? response[0] : null);
      }

      // Add new entity to entityLineage container in Cosmos
      if (['Component'].includes(this.props.type)) {
        Cosmos({
          cosmosApi: 'addDocuments',
          containerId: 'entityLineage',
          documentArr: [
            {
              entityDBID: response[0].Entity_DBID,
              lotQuantity: response[0].Quantity,
              parents: [],
              createdDate: new Date(),
              pk: this.props.type.toLowerCase(),
            },
          ],
        });
      }
    });
  };

  updateType = (type) => {
    this.setState({
      newEntityType: type,
      parentEntityList: [],
      newEntityParent: null,
    });
    if (type) {
      this.updateParentList(type);
    }
  };

  updatelotId = (text) => {
    this.setState({ lotId: text });
  };

  updateCompQty = (val) => {
    this.setState({ componentQty: val });
  };
  updatesrNo = (val) => {
    this.setState({ srNo: val });
  };

  updatesFields = (val, i) => {
    let fields = this.state.fields;

    fields[i].EntityMetaValue = val;
    this.setState({ fields: fields });
  };

  isSaveButtonDisabled() {
    return (
      // name length too short
      (!['Material'].includes(this.props.level) &&
        this.state.newEntityName.length < 3) ||
      // missing parent
      !this.state.newEntityParent ||
      !this.state.entityOwner ||
      (this.state.fields.length > 0 &&
        this.state.fields.filter((f) => f.EntityMetaValue === '').length > 0)
    );
  }

  render = () => {
    return (
      <Modal
        onEntered={() =>
          ['Material'].includes(this.props.level) ||
          ['Geo', 'Lab', 'Building', 'Floor'].includes(this.props.level)
            ? ''
            : this.typeInput.current.focus()
        }
        show={this.state.creating}
        onHide={() => this.props.closeModal()}
      >
        <Modal.Header>
          <Modal.Title>
            Add{' '}
            {this.props.level.length
              ? this.props.type
                ? this.props.type
                : this.props.level
              : 'Equipment'}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!['Material'].includes(this.props.level) ? (
            <div className="mb-2">
              Name
              <Form.Control
                ref={this.textInput}
                type="text"
                placeholder="Entity Name"
                onChange={(e) =>
                  this.setState({ newEntityName: e.target.value.trim() })
                }
              />
            </div>
          ) : (
            ''
          )}

          {this.props.level !== 'Material' &&
          !['Geo', 'Lab', 'Building', 'Floor'].includes(this.props.level) ? (
            <>
              <div className="mb-2">
                Type
                <Autocomplete
                  autoHighlight={true}
                  className="type-ahead"
                  ref={this.typeInput}
                  id="entity-type"
                  options={this.state.entityTypes}
                  getOptionLabel={(option) => option.EntityType}
                  hiddenlabel="true"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Type"
                    />
                  )}
                  onChange={(_event, newType) => {
                    this.updateType(newType);
                  }}
                />
              </div>
            </>
          ) : null}

          {this.state.fields.length > 0
            ? this.state.fields.map((field, i) => {
                return (
                  <div className="mb-2" key={i}>
                    {field.EntityMetaType}
                    <Form.Control
                      type="text"
                      placeholder={field.EntityMetaType}
                      value={field.EntityMetaValue}
                      onChange={(e) =>
                        this.updatesFields(e.target.value.trim(), i)
                      }
                    />
                  </div>
                );
              })
            : ''}

          <div className="mb-2">
            Location
            <Autocomplete
              autoHighlight={true}
              className="type-ahead"
              id="parent-entity"
              options={this.state.parentEntityList}
              getOptionLabel={(option) => (option ? option.Path : '')}
              hiddenlabel="true"
              value={
                this.state.newEntityType ? this.state.newEntityParent : null
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Location"
                />
              )}
              disabled={!this.state.newEntityType}
              onChange={(_event, newParent) => {
                this.setState({ newEntityParent: newParent });
              }}
            />
          </div>
          <div className="mb-2">
            Owner
            <Autocomplete
              autoHighlight={true}
              className="type-ahead"
              id="entity-owner"
              value={this.state.entityOwner}
              options={this.state.users}
              getOptionLabel={(option) => option.UserName}
              hiddenlabel="true"
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Owner"
                />
              )}
              onChange={(_event, newOwner) => {
                this.setState({ entityOwner: newOwner });
              }}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-primary"
            onClick={() => this.props.closeModal()}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.currentTarget.disabled = true;
              this.addEntity(false, e.currentTarget);
            }}
            disabled={this.isSaveButtonDisabled()}
          >
            Save
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              e.currentTarget.disabled = true;
              this.addEntity(true);
            }}
            disabled={this.isSaveButtonDisabled()}
          >
            Save And Edit
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };
}
export default EntityCreate;
