import { useEffect, useState } from 'react';
import { Api } from './interface';

export default function ApiTest() {
  const [page, setPage] = useState(0);
  const [response, setResponse] = useState([]);

  useEffect(() => {
    setPage(0);
    Api({
      sp: 'getPageByLevel',
      json: {
        level: 'Material',
        page: page,
        rows: 100,
        text: '',
        orderBy: 'EntityName',
        desc: 1,
        cols: [6, 124],
        filters: [],
      },
    }).then((response) => {
      setResponse(response);
    });
  }, []);

  return (
    <>
      {response.map((x, i) => {
        <div key={i}>{x.EntityName}</div>;
      })}
    </>
  );
}
