import { Divider, Menu, MenuItem } from '@mui/material';
import {
  Filter,
  LayoutThreeColumns,
  // QuestionCircle,
  ArrowCounterclockwise,
  SlashCircle,
} from 'react-bootstrap-icons';
import ColumnFilter from './columnFilter';
import ColumnManager from './columnManager';
import HeaderHelp from './headerHelp';

export default function HeaderContext(props) {
  const handleOpen = (modal) => {
    props.openModal(modal);
    props.handleHeaderContextClose(false, modal);
  };

  const closeModal = (selected, column) => {
    // check if columns are different
    const mgr =
      props.columns
        .filter((x) => x.id !== 'Menu')
        .map((x) => x.id)
        .join() === selected.map((x) => x.EntityMetaType).join();
    // check if filters are different
    const filter =
      props.filters.map((x) => x.DBID + '_' + x.value).join() ===
      selected.map((x) => x.DBID + '_' + x.EntityMetaValue).join();

    if (props.currentModal === 'ColumnFilter') {
      props.closeColumnFilter(selected, column);
    } else if (props.currentModal === 'ColumnManager') {
      props.updateColumns(selected);
    }

    // refresh if columns or filters change
    let refresh =
      (props.currentModal === 'ColumnFilter' && !filter) ||
      (props.currentModal === 'ColumnManager' && !mgr);
    props.handleHeaderContextClose(refresh, null);
  };

  const clearFilters = () => {
    props.closeColumnFilter([], props.currentColumn);
    props.handleHeaderContextClose(true, null);
  };

  const resetColumns = () => {
    props.resetColumns();
    props.handleHeaderContextClose(true, null);
  };

  return (
    <div>
      <Menu
        id="header-menu"
        anchorEl={props.headerContextAnchor}
        open={props.headerContextAnchor !== null}
        onClose={() => props.handleHeaderContextClose()}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
        className={'header-context'}
        disableAutoFocusItem
      >
        <MenuItem onClick={() => handleOpen('ColumnFilter')}>
          <Filter className="context-icon" />
          Filters
        </MenuItem>
        <MenuItem onClick={() => handleOpen('ColumnManager')}>
          <LayoutThreeColumns className="context-icon" />
          Columns
        </MenuItem>
        <Divider />
        <MenuItem onClick={clearFilters}>
          <SlashCircle className="context-icon red-option" />
          Clear Filters
        </MenuItem>
        <MenuItem onClick={resetColumns}>
          <ArrowCounterclockwise
            className="context-icon red-option"
            size={18}
          />
          Reset Columns
        </MenuItem>
      </Menu>
      {props.currentModal === 'ColumnFilter' ? (
        <ColumnFilter
          close={closeModal}
          open={props.currentModal === 'ColumnFilter'}
          level={props.level}
          column={props.currentColumn}
          selected={props.filters}
        />
      ) : null}
      {props.currentModal === 'ColumnManager' ? (
        <ColumnManager
          close={closeModal}
          open={props.currentModal === 'ColumnManager'}
          column={props.currentColumn}
          level={props.level}
          columns={props.columns}
          update={props.updateColumns}
          type={props.type}
        />
      ) : null}
      {props.currentModal === 'HeaderHelp' ? (
        <HeaderHelp
          close={closeModal}
          open={props.currentModal === 'HeaderHelp'}
        />
      ) : null}
    </div>
  );
}
