// JavaScript source code
import { toast } from 'react-toastify';
import React, { Component } from 'react';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

class DatePicker extends Component {
  constructor(props) {
    super(props);
    this.changed = false;

    // the date is outside the state since the setState function is async and
    // does not complete before the onBlur() is triggered
    this.date = props.item?.EntityMetaValue ?? props.value;
    this.state = {
      item: props.item,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.setState({ item: this.props.item });
    }
  }

  render() {
    return (
      <div className="datePicker">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DesktopDatePicker
            value={this.date ?? null}
            onChange={(e) => this.dateChange(e)}
            onClose={() => this.saveDate()}
            renderInput={(params) => (
              <TextField
                {...params}
                onBlur={() => {
                  this.saveDate();
                }}
              />
            )}
          />
        </LocalizationProvider>
      </div>
    );
  }

  dateChange(e) {
    // create new date
    e = e ? new Date(e.$M + 1 + '/' + e.$D + '/' + e.$y) : e;
    // only trigger the change flag if the prev date is different from the entered one
    if (this.date !== e) {
      this.date = e;
      this.changed = true;
    }
  }

  saveDate() {
    if (this.changed) {
      if (this.date === null) {
        // if we clear out the date, update it with an empty string
        this.props.update('', this.state.item);
        this.changed = false;
      } else {
        // check if the date is a real date or not and if it falls within certain ranges
        if (
          !(
            this.date &&
            Object.prototype.toString.call(this.date) === '[object Date]' &&
            !isNaN(this.date)
          )
        ) {
          toast.error(
            'Invalid date. Please enter a valid date with the format XX/XX/XXXX'
          );
        } else if (this.date.getFullYear() < 1900) {
          toast.error('The date year must be greater than 1900');
        } else if (this.date.getFullYear() > 2099) {
          toast.error('The date year must be less than 2099');
        } else {
          this.props.update(this.date, this.state.item);
          this.changed = false;
        }
      }
    }
  }
}
export default DatePicker;
