import React, { useState } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { TableCell, TableSortLabel } from '@mui/material';
import { Gear } from 'react-bootstrap-icons';
import HeaderContext from './headerContext';

export const SortableHeader = (props) => {
  const sortable = useSortable({
    id: props.column.id,
    disabled: props.column.id === 'Menu',
    // transition: {
    //   duration: 2000, // milliseconds
    //   easing: "cubic-bezier(0.25, 1, 0.5, 1)",
    // },
    // strategy: horizontalListSortingStrategy,
  });
  const { attributes, listeners, setNodeRef, transform, transition } = sortable;
  const [contextAnchor, setContextAnchor] = useState(null);
  const [hover, setHover] = useState(false);
  const [headerModalOpen, setHeaderModalOpen] = useState(false);
  const [modal, setModal] = useState(null);

  const {
    // eslint-disable-next-line no-unused-vars
    orderBy,
    // eslint-disable-next-line no-unused-vars
    groupedColumns,
    // eslint-disable-next-line no-unused-vars
    headerContextAnchor,
    // eslint-disable-next-line no-unused-vars
    updateColumns,
    // eslint-disable-next-line no-unused-vars
    updateGroups,
    // eslint-disable-next-line no-unused-vars
    getPage,
    // eslint-disable-next-line no-unused-vars
    closeColumnFilter,
    // eslint-disable-next-line no-unused-vars
    currentModal,
    // eslint-disable-next-line no-unused-vars
    sort,
    ...thProps
  } = props;

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    whiteSpace: 'normal',
    wordWrap: 'break-word',
    // backgroundColor: "transparent",
  };

  const getHeaderStyle = () => {
    let className = 'col-header pr-2';
    const filtered = props.filters?.some((x) => x.key === props.column.id);
    const grouped = props.groupedColumns?.some((x) => x.id === props.column.id);
    // const dragging = !props.order;
    className = filtered ? (className += ' filtered-header') : className;
    className = grouped ? (className += ' grouped-header') : className;
    // className = dragging ? (className += " dragging-header") : className;
    return className;
  };

  const headerStyles = getHeaderStyle();

  const headerClick = (event) => {
    if (props.column.id === 'Menu') {
      return;
    }
    if (
      event.target.closest('.header-menu-icon') ||
      event.target.closest('.header-menu-icon-active')
    ) {
      setContextAnchor(event.target);
    } else if (
      event.target.closest('.col-header') ||
      event.target.closest('.MuiTableSortLabel-icon')
    ) {
      props.sort(props.column);
    }
  };

  const openModal = (modal) => {
    setHeaderModalOpen(modal ? true : false);
  };

  const handleHeaderContextClose = (refresh, modal) => {
    setModal(modal);
    setContextAnchor(null);
    if (refresh) {
      props.getPage({});
    }
  };

  return (
    <>
      <TableCell
        ref={setNodeRef}
        style={style}
        {...thProps}
        {...attributes}
        {...listeners}
        onClick={(e) => headerClick(e)}
        onMouseEnter={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
        key={props.column.id}
        className={
          props.index === props.columns.length - 1
            ? 'sticky-last-col search-table-header search-table-header-effect'
            : 'search-table-header search-table-header-effect'
        }
        align={props.column.align}
        sortDirection={props.orderBy === props.column.id ? props.order : false}
      >
        {/* Non-sortable & sortable column headers */}
        <span
          className={
            props.column.id === 'Menu'
              ? 'search-table-header-span d-flex justify-content-center'
              : 'search-table-header-span d-flex justify-content-between'
          }
        >
          <span>
            {props.column.id === 'Menu' ? (
              <span className={'col-header pr-2'}>{props.column.label}</span>
            ) : (
              <TableSortLabel
                active={props.orderBy === props.column.id}
                direction={
                  props.orderBy === props.column.id
                    ? props.order ?? 'asc'
                    : 'asc'
                }
                className={'header-sort-icon'}
              >
                <span className={headerStyles}>{props.column.label}</span>
              </TableSortLabel>
            )}
          </span>
          <span>
            {props.column.id !== 'Menu' && props.grouped === 'false' ? (
              <Gear
                size={16}
                className={
                  contextAnchor || hover
                    ? 'pointer header-menu-icon-active ml-2'
                    : 'pointer header-menu-icon ml-2'
                }
              />
            ) : null}
          </span>
        </span>
      </TableCell>

      {contextAnchor || headerModalOpen ? (
        <HeaderContext
          level={props.level}
          currentColumn={props.column}
          columns={props.columns}
          currentModal={modal}
          groupedColumns={props.groupedColumns}
          headerContextAnchor={contextAnchor}
          filters={props.filters}
          updateColumns={props.updateColumns}
          updateGroups={props.updateGroups}
          handleHeaderContextClose={handleHeaderContextClose}
          closeColumnFilter={props.closeColumnFilter}
          resetColumns={props.resetColumns}
          openModal={openModal}
          type={props.type}
        ></HeaderContext>
      ) : null}
    </>
  );
};
