import { getDateTime } from './date';
import { Api } from './interface';
import Excel from 'exceljs';
import * as FileSaver from 'file-saver';
import { groupSelectError } from './common';
import { validateData } from './validator';
import { bake } from './common';

let invalidMeta = [];
let locations = [];
let users = [];

// prepare excel for template downoad
const templateDownloadBulkEdit = (
  fields,
  identifyingFields,
  selectedEntityTypes,
  selectedIdentifierFields,
  selectedToUpdateFields,
  parentLocations,
  allUsers
) => {
  let dbColumns = [];

  locations = parentLocations;
  users = allUsers;

  // fetch grouped values to provide validaion in excel
  Api({
    sp: 'getMetaOptionsByTypeBulkUpdate',
    json: { types: selectedEntityTypes },
  }).then((response) => {
    // fetch the existing valid values for the selected identifier fields
    // users should select the identification value from dropdown in excel
    Api({
      sp: 'getIdentifierValuesBulkUpdate',
      json: {
        selectedIdentifierFields: identifyingFields,
        types: selectedEntityTypes,
      },
    }).then((identifierValues) => {
      // map the values to the identifier fields
      fields
        .filter((field) =>
          selectedIdentifierFields
            .map((selectedField) => selectedField.UniqueEntityMetaType_DBID)
            .includes(field.EntityMetaType_DBID)
        )
        // mark which ones are identifier to avoid updating the identifier data
        .forEach((x) => dbColumns.push({ ...x, Identifier: true }));
      // add the "to update" in dbColumns for header row
      selectedToUpdateFields.forEach((x) => dbColumns.push(x));

      downloadTemplate(
        selectedEntityTypes,
        dbColumns,
        response,
        selectedIdentifierFields,
        identifierValues
      );
    });
  });
};

const downloadTemplate = (
  selectedEntityTypes,
  dbColumns,
  metaOptions,
  selectedIdentifierFields,
  identifierValues
) => {
  const workbook = new Excel.Workbook(); // Create a new workbook
  const worksheet = workbook.addWorksheet('data');
  const worksheet2 = workbook.addWorksheet('validation');
  let colList = [];
  let obj = {};

  // add a column for selected entity types
  colList.push({ header: 'Type', key: 'EntityType' });
  obj['EntityType'] = 'Select Type';
  let options = [];
  selectedEntityTypes.map((entType) => {
    options.push(entType.EntityType);
  });
  // create validation for selected entity types in the workbook
  validationList(
    Object.keys(obj).length,
    worksheet,
    worksheet2,
    options,
    'EntityType'
  );

  // add a column to select the identifier meta type for each row (e.g., Etag)
  colList.push({ header: 'Identifier', key: 'EntityMetaType' });
  obj['EntityMetaType'] = 'Select Identifier';
  options = [];
  selectedIdentifierFields.map((identifier) => {
    options.push(identifier.EntityMetaType);
  });
  validationList(
    Object.keys(obj).length,
    worksheet,
    worksheet2,
    options,
    'EntityMetaType'
  );
  // add a column to select the value of the identifier meta type (e.g., Cxxxx if selected Identifier is Etag)
  colList.push({ header: 'Identifier Values', key: 'EntityMetaValue' });
  obj['EntityMetaValue'] = 'Select Identifier Value';
  options = [];
  identifierValues.map((identifier) => {
    options.push(identifier.EntityMetaValue);
  });
  // create validation for selected odentifier meta type in the workbook to avoid creating nw identification values in bulk edit
  // the identification value must pre-exit
  validationList(
    Object.keys(obj).length,
    worksheet,
    worksheet2,
    options,
    'EntityMetaType'
  );

  dbColumns.forEach((col) => {
    // if any "to update" field need to be unique by different field
    // add the "unique by" field in the head and update the label
    let key = col.Identifier
      ? col.EntityMetaType + ' (Identified By)'
      : col.EntityMetaType;
    if (col.DataType !== 'Image') {
      colList.push({ header: key, key: key });
      // validation list for locations
      if (col.EntityMetaType === 'Location') {
        obj[key] = 'Select Location';
        let options = [];
        locations.map((location) => {
          options.push(location.Path);
        });
        validationList(
          Object.keys(obj).length,
          worksheet,
          worksheet2,
          options,
          'Location'
        );
        // provide sample format for all the columns in the excel
      } else if (col.DataType === 'String') {
        obj[key] = 'Text...';
      } else if (col.DataType === 'Integer') {
        obj[key] = '12';
      } else if (col.DataType === 'Float') {
        obj[key] = '1.2';
      } else if (
        col.DataType === 'Group' ||
        col.DataType === 'Multiple Choice'
      ) {
        obj[key] = 'Select Option';
        let options = [];
        metaOptions.forEach((y) => {
          if (key === y.EntityMetaType) {
            options.push(y.EntityMetaOption);
          }
        });
        validationList(colList.length, worksheet, worksheet2, options, key);
      } else if (col.DataType === 'Binary') {
        obj[key] = '0(or 1)';
      } else if (col.DataType === 'URL') {
        obj[key] = 'www.myurl.com';
      } else if (col.DataType === 'Email') {
        obj[key] = 'name@intel.com';
      } else if (col.DataType === 'Date') {
        obj[key] = '12/30/2019 (mm/dd/yyyy)';
      } else if (col.DataType === 'Phone Number') {
        obj[key] = '123-123-1234';
      } else if (col.DataType === 'User Lookup') {
        // validation for any user type field such as owner
        obj[key] = 'Select User';
        let options = [];
        users.map((user) => {
          options.push(user.UserName);
        });
        validationList(
          Object.keys(obj).length,
          worksheet,
          worksheet2,
          options,
          'EntityOwner'
        );
      }
      // format for meta type (e.g., notes) which can be multiple to allow users to provide multiple values in one row
      if (col.AllowMultiple) {
        obj[key] = obj[key] + '|' + obj[key];
      }
    }
    // create a validation list for dropdown in excel if the column is an identifier field
    if (col.Identifier) {
      obj[key] = 'Select Option';
      let options = [];
      identifierValues.forEach((y) => {
        if (col.EntityMetaType === y.EntityMetaType) {
          options.push(y.EntityMetaValue);
        }
      });
      validationList(colList.length, worksheet, worksheet2, options, key);
    }
  });

  worksheet.columns = colList;
  worksheet.addRow(obj);
  worksheet2.columns = colList;

  workbook.xlsx.writeBuffer().then(function (data) {
    var blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    FileSaver.saveAs(blob, 'Bulk_Update_Format_' + getDateTime() + '.xlsx');
  });
};

// create validation list for excel
const validationList = (position, sheet1, sheet2, options, key) => {
  let letter = sheet1.getColumn(position).letter;

  let address = `${letter}2:${letter}1048576`;
  let range = `validation!$${letter}$2:$${letter}$${options.length + 1}`;
  sheet1.dataValidations.add(address, {
    type: 'list',
    allowBlank: true,
    formulae: [range],
    showErrorMessage: true,
    errorStyle: 'error',
    error:
      'The value does not match the data validation restrictions defined for this cell',
  });

  sheet2.getColumn(position).values = [key, ...options];
};

const processUploadBulkEdit = (
  selectedEntityTypes,
  metaOptions,
  excelRows,
  colList,
  selectedToUpdateFields,
  level,
  entitiesFound,
  parentLocations,
  allUsers,
  canEdit
) => {
  invalidMeta = [];
  locations = parentLocations;
  users = allUsers;

  // fetch current user from browser local storage to verify the permissions
  let currentUser = users.filter((user) => user?.OID === bake('user')['oid']);

  // verify all the entity types provided in excel are of selected types in the modal
  let typesNotSelected = excelRows.filter(
    (row) =>
      !selectedEntityTypes
        .map((entityType) => entityType.EntityType)
        .includes(row['type'])
  );
  if (typesNotSelected?.length) {
    let errMsg =
      'Row(s) :' +
      typesNotSelected.map((type) => type.rowNum + 1) +
      ' have unselected types';
    invalidMeta.push(errMsg);
  }
  let uniqueToUpdateMetaType = {};

  // get the list of "unique" field (or combination thereof) in the "to update" list
  if (selectedToUpdateFields.filter((field) => field.IsUnique)) {
    let toUpdateUniqueField = selectedToUpdateFields.filter(
      (field) => field.IsUnique
    )[0];
    // create an object of "unique" and "unique by" meta
    // this object will have all the details of the meta, i.e., EntityMetaType, DataType, etc.
    // this object will be used to verify against all the rows in the excel and not in db
    if (toUpdateUniqueField) {
      let toUpdateUnique = {
        uniqueToUpdateField: toUpdateUniqueField,
        // if there's no "unique by" use the "unique" itself
        uniqueByField: selectedToUpdateFields.filter(
          (field) =>
            field.EntityMetaType_DBID ===
              toUpdateUniqueField?.UniqueEntityMetaType_DBID ??
            toUpdateUniqueField?.EntityMetaType_DBID
        )[0],
      };

      // create an object of only EntityMetaType of unique fields to send to db to verify against existing values in db
      // this object will be used to verify against data in db
      uniqueToUpdateMetaType = {
        ...uniqueToUpdateMetaType,
        uniqueToUpdateField: toUpdateUniqueField.EntityMetaType,
      };
      // if there's a "unique by" add the property to the object
      if (toUpdateUnique?.uniqueByField?.EntityMetaType)
        uniqueToUpdateMetaType = {
          ...uniqueToUpdateMetaType,
          uniqueByField: toUpdateUnique?.uniqueByField?.EntityMetaType,
        };

      // check for duplicate rows in excel n case of unique "to update" fields
      checkExcelDataUniqueValidity(toUpdateUnique, excelRows);
    }
  }

  // if any record in excel is not found, add the rows to the error report
  let notFound = excelRows.filter((row) => {
    return !entitiesFound.filter(
      (item) =>
        item.EntityType === row['type'] &&
        item.EntityMetaType === row['identifier'] &&
        item.EntityMetaValue === row['value']
    ).length;
  });
  if (notFound?.length) {
    let errMsg =
      'Row(s) :' + notFound.map((entry) => entry.rowNum + 1) + ' do not exist';
    invalidMeta.push(errMsg);
  }

  // if any permission is required, add to the error report
  if (!canEdit) {
    let unauthorizedEntities = excelRows.filter((row) => {
      return !entitiesFound.filter(
        (item) =>
          item.EntityOwnerName !== currentUser?.UserName &&
          item.Entity_DBID === row.Entity_DBID
      ).length;
    });
    if (unauthorizedEntities?.length) {
      let errMsg =
        'Row(s) :' +
        unauthorizedEntities.map((entry) => entry.rowNum + 1) +
        ' not owned by user. Proper role(s) required';
      invalidMeta.push(errMsg);
    }
  }
  // attach entity_dbid to the "found" entries in excel
  excelRows = excelRows.map((row) => {
    let item = entitiesFound.filter((item) => item.rowNum === row['rowNum']);

    return item?.length === 1
      ? { ...row, Entity_DBID: item[0].Entity_DBID }
      : row;
  });

  return processData(
    selectedToUpdateFields,
    colList,
    excelRows,
    metaOptions,
    level,
    uniqueToUpdateMetaType
  );
};

// verify the validity of each value in each "to update" column
const processData = (
  selectedToUpdateFields,
  colList,
  excelRows,
  metaOptions,
  level,
  uniqueToUpdateMetaType
) => {
  let fileColumns = colList,
    dbColumns = selectedToUpdateFields,
    finalJSON = [],
    keys = [],
    isName = 0,
    isLocation = 0;

  // attach meta options to the "to update" field if it's a grouped field, such as "Model" to verify the data
  dbColumns.forEach((c) => {
    if (c.DataType === 'Group' || c.DataType === 'Multiple Choice') {
      c.options = [];

      metaOptions.forEach((y) => {
        if (c.EntityMetaType === y.EntityMetaType) {
          c.options.push(y.EntityMetaOption);
        }
      });
    }
  });

  // loop through entire excel data to verify data based on data type
  excelRows.forEach((row, i) => {
    let obj = { Entity_DBID: row['Entity_DBID'], rowNum: row['rowNum'] };
    if (row[i] && typeof row[i] === 'string') {
      row[i] = row[i].trim();
    }

    fileColumns.forEach((c) => {
      let col = dbColumns.find(
        (dbCol) => dbCol.EntityMetaType.toLowerCase() === c.toLowerCase()
      );

      let entityMetaType = col?.EntityMetaType;
      // the column in excel should be in the selected list
      if (col) {
        if (row[entityMetaType] === null || row[entityMetaType] === undefined) {
          return; // skip null or undefined values, no deletions
        }
        obj[entityMetaType] = [];

        if (
          (col.DataType === 'Group' || col.DataType === 'Multiple Choice') &&
          col.AllowMultiple === 0
        ) {
          let temp = col.options.find((opt) => {
            return (
              opt.toString().toLowerCase() ===
              row[entityMetaType]?.toString()?.toLowerCase()
            );
          });
          // verify if the provided value is present in meta options or not
          if (temp) {
            row[entityMetaType] = temp;
          } else {
            invalidMeta.push(
              groupSelectError(
                row.rowNum,
                row[entityMetaType],
                col.EntityMetaType
              )
            );
          }
        } else if (
          (col.DataType === 'Group' || col.DataType === 'Multiple Choice') &&
          col.AllowMultiple === 1
        ) {
          // if the grouped values can be multiple, split by "|" and verify each
          let array = row[entityMetaType].toString().split('|');
          let values = [];
          array.forEach((ary) => {
            let temp = col.options.find((opt) => {
              return (
                opt.toString().toLowerCase() === ary.toString().toLowerCase()
              );
            });
            if (temp) {
              values.push(temp);
            } else {
              invalidMeta.push(
                groupSelectError(row.rowNum, ary, col.EntityMetaType)
              );
            }
            row[entityMetaType] = values.join('|');
          });
        }
        // if the field is not group or dropdown but allows multiple, e.g., notes, verify the data
        else if (
          col.DataType !== 'Group' &&
          col.DataType !== 'Multiple Choice' &&
          col.AllowMultiple === 1
        ) {
          let array = row[entityMetaType].toString().split('|');

          array.forEach((ary) => {
            let resultArray = validateData(col, ary, row.rowNum); // Multi string, int, date, phone etc.

            if (resultArray.length > 0) {
              invalidMeta.push(...resultArray);
            }
          });
        } else {
          let resultArray = validateData(col, row[entityMetaType], row.rowNum); // single string, int, date, float etc. type
          if (resultArray.length > 0) {
            invalidMeta.push(...resultArray);
          }
        }
        // if any field is binary, for 0 make it null, otherwise use the data
        if (col.DataType === 'Binary') {
          row[entityMetaType] =
            row[entityMetaType] === 0 ? '' : row[entityMetaType];
        }
        // format the date type entity meta
        if (col.DataType === 'Date') {
          if (col.AllowMultiple === 0) {
            let date = new Date(row[entityMetaType]);
            date.setMinutes(date.getMinutes() + date.getTimezoneOffset());
            row[entityMetaType] = date;
          }
        }

        // stringify the json object for dynamic sqlto read based on selected meta type
        obj[col.EntityMetaType] =
          col.AllowMultiple === 1
            ? JSON.stringify([...row[entityMetaType].toString().split('|')])
            : col.DataType
            ? JSON.stringify([row[entityMetaType].toString()])
            : row[entityMetaType];
      }
    });

    // no location or owner update for groups
    // bulk edit for groups is not implemented
    if (level !== 'Group') {
      if (
        selectedToUpdateFields.filter(
          (field) => field.EntityMetaType === 'Location'
        )?.length
      ) {
        // validate provided location
        obj = validateLocation(obj, i);
      }

      if (
        selectedToUpdateFields.filter(
          (field) => field.EntityMetaType === 'EntityOwner'
        )?.length
      ) {
        // validate provided owner
        obj = validateOwner(obj, i);
      }
    }

    // if there is no error addd the row to be updated
    if (invalidMeta.length === 0) {
      finalJSON.push(obj);
    }
  });
  // excel loop ends
  // if any error, send the report with "Error" status
  if (invalidMeta.length > 0) {
    return { data: invalidMeta, Status: 'Error' };
  } else {
    let fields = selectedToUpdateFields;

    // make a flat list of "to update" fields
    fields.forEach((field) => {
      if (field.DataType) {
        keys.push(field.EntityMetaType);
      }
    });

    // add EntityOwnerName if "Owner" is part of "to update" list
    if (keys.find((val) => val === 'EntityOwner')) {
      keys.push('EntityOwnerName');
    }

    // mark a flag is Location is part of update
    if (fields.find((f) => f.EntityMetaType === 'Location')) {
      isLocation = 1;
    }

    if (fields.find((f) => f.EntityMetaType === 'EntityName')) {
      isName = 1;
    }

    // return the processed data
    return {
      finalJSON: finalJSON,
      keys: keys,
      excelRows: excelRows,
      isName: isName,
      isLocation: isLocation,
      uniqueToUpdateMetaType: uniqueToUpdateMetaType,
      Status: 'Processed',
    };
  }
};

const checkExcelDataUniqueValidity = (toUpdateUnique, excelRows) => {
  let uniqueToUpdateField = toUpdateUnique.uniqueToUpdateField.EntityMetaType;
  let uniqueByField =
    toUpdateUnique.uniqueByField?.EntityMetaType ??
    toUpdateUnique.uniqueToUpdateField.EntityMetaType;
  // check for duplicates in the excel "to update" fields if they are supposed to be unique
  let duplicates = excelRows.filter((row) => {
    return (
      excelRows.filter(
        (r) =>
          r[uniqueToUpdateField] === row[uniqueToUpdateField] &&
          r[uniqueByField] === row[uniqueByField] &&
          row['Entity_DBID'] !== r['Entity_DBID']
      ).length > 1
    );
  });

  // if there are any duplicates, add the rows to the error report
  if (duplicates?.length) {
    let errMsg =
      'Row(s) : ' +
      duplicates.map((row) => row.rowNum + 1) +
      ' - have duplicate (' +
      uniqueToUpdateField;
    let uniqueByErrMsg =
      uniqueByField === uniqueToUpdateField ? ')' : ', ' + uniqueByField + ')';
    invalidMeta.push(errMsg + uniqueByErrMsg);
  }
};

const prepareExcelData = (rows, identifyingFields) => {
  // get the "unique by" entity meta based on header row of excel
  let uniqueBy = {
    uniqueIdentifyField: identifyingFields.filter(
      (field) => field.UniqueEntityMetaType
    )[0],
    uniqueByField: identifyingFields.filter(
      (field) =>
        field.label ===
        rows[0].filter((row) => row.includes('(Identified By)'))[0]
    )[0],
  };

  let identifyingList = [];
  let uniqueByIdentifyingList = [];
  let excelRows = [];

  let colList = rows[0];
  let colListIdx = uniqueBy?.uniqueByField ? 4 : 3;
  // convert excel rows into list of objects
  rows.forEach((row, i) => {
    if (i > 0) {
      let obj = {};

      obj['rowNum'] = i;
      obj['type'] = row[0];
      obj['identifier'] = row[1];
      obj['value'] = row[2];
      colList
        .slice(colListIdx)
        .forEach((field, idx) => (obj[field] = row[idx + colListIdx]));

      if (row[1] === uniqueBy?.uniqueIdentifyField?.EntityMetaType) {
        obj['uniqueBy_DBID'] = uniqueBy?.uniqueByField?.EntityMetaType_DBID;
        obj['uniqueByEntityMetaType'] = uniqueBy?.uniqueByField?.EntityMetaType;
        obj['uniqueByValue'] = row[3];
        uniqueByIdentifyingList.push(obj);
      } else identifyingList.push(obj);
      excelRows.push(obj);
    }
  });
  let excelData = {
    identifyingList: identifyingList, // list to identify entities in db
    uniqueByIdentifyingList: uniqueByIdentifyingList, // if applicable, or else, empty
    excelRows: excelRows, // list of objects of excel rows
  };
  return excelData;
};

// provided location in excel must be present in db
const validateLocation = (obj, i) => {
  if (obj['Location'] !== undefined) {
    let location = JSON.parse(obj['Location'])[0];
    let parent = locations.find((loc) => {
      return loc.Path.toLowerCase() === location.toLowerCase();
    });
    if (parent) {
      obj['Location'] = parent.Entity_DBID; // add Path
    } else {
      //invalid location error
      invalidMeta.push('Row Num : ' + (i + 1) + ' - Location is Invalid.');
    }
  }

  return obj;
};

// provided owner in excel must be present in db
const validateOwner = (obj, i) => {
  if (obj.EntityOwner) {
    let owner = JSON.parse(obj.EntityOwner)[0];
    if (owner) {
      let user = users.find((user) => {
        return user.UserName.toLowerCase() === owner.toLowerCase();
      });
      if (user) {
        obj['EntityOwner'] = JSON.stringify([user.User_DBID.toString()]);
        obj['EntityOwnerName'] = JSON.stringify([user.UserName]);
      } else {
        //invalid owner error
        invalidMeta.push('Row Num : ' + (i + 1) + ' - Owner is Invalid.');
      }
    }
  }

  return obj;
};

// download the error report as simple text file
const downloadErrReport = (data) => {
  const blob = new Blob([data], { type: 'text/plain' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = 'bulk_edit_error_report' + getDateTime() + '.txt';
  link.href = url;
  link.click();
};
export {
  templateDownloadBulkEdit,
  processUploadBulkEdit,
  downloadErrReport,
  prepareExcelData,
};
