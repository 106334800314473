import {
  Autocomplete,
  Box,
  Chip,
  MenuItem,
  MenuList,
  Divider,
  Paper,
  Popper,
  TextField,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Form, Modal } from 'react-bootstrap';
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  ReactFlowProvider,
  useReactFlow,
  Handle,
  Position,
  MarkerType,
  updateEdge,
  BaseEdge,
  EdgeLabelRenderer,
  // getBezierPath,
  getStraightPath,
} from 'reactflow';
import { ExclamationCircleFill } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import 'reactflow/dist/style.css';
import { v4 as uuidv4 } from 'uuid';
import { Api } from './interface';
import { Hint } from './constants';
import { oven } from './common';

const CustomNode = ({ data }) => {
  return (
    <>
      <Handle type="target" position={Position.Left} id="target" />
      <div className="custom-node">
        <label className="pointer">{data.label}</label>
      </div>
      <Handle type="source" position={Position.Right} id="source" />
    </>
  );
};

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  markerEnd,
  data,
}) => {
  const [edgePath, labelX, labelY] = getStraightPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  let tX = -50;
  let tY = -50;
  if (data.double) {
    tY -= 100;
  }

  return (
    <>
      <BaseEdge
        path={edgePath}
        markerEnd={markerEnd}
        style={style}
        interactionWidth={data.interactionWidth}
      />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(${tX}%, ${tY}%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            pointerEvents: 'all',
          }}
          className="nodrag nopan"
        >
          <div className="edge-button d-flex justify-content-between" id={id}>
            {data.roles?.map((x) => {
              return (
                <Hint
                  key={x.Role_DBID}
                  placement="top"
                  delay={{ show: 250, hide: 400 }}
                  title={x.Role}
                >
                  <span
                    key={x.Role_DBID}
                    className="role-dot pointer"
                    style={{ backgroundColor: x.color }}
                  ></span>
                </Hint>
              );
            })}
          </div>
        </div>
      </EdgeLabelRenderer>
    </>
  );
};

const initialNodes = [];

const initialEdges = [];

const fitViewOptions = {
  padding: 3,
};

const nodeTypes = { customNode: CustomNode };

const edgeTypes = {
  customEdge: CustomEdge,
};

const AddNodeOnEdgeDrop = () => {
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [contextAnchor, setContextAnchor] = useState(null);
  const [xOffset, setXOffset] = useState(0);
  const [yOffset, setYOffset] = useState(0);
  const [editNode, setEditNode] = useState(null);
  const [currentModal, setCurrentModal] = useState(null);
  const [currentLabel, setCurrentLabel] = useState(null);
  const [currentEdge, setCurrentEdge] = useState(null);
  const [rfInstance, setRfInstance] = useState(null);
  const [nodeContext, setNodeContext] = useState(false);
  const [roles, setRoles] = useState([]);
  const [selected, setSelected] = useState([]);
  const [levels, setLevels] = useState([]);
  const [level, setLevel] = useState([]);

  const edgeUpdateSuccessful = useRef(true);

  const { project } = useReactFlow();

  const bake = (g, c, v) => {
    return oven({}, true, c, v);
  };

  useEffect(() => {
    Api({
      sp: 'getLevels',
      json: {},
    }).then((lvls) => {
      lvls = lvls.filter((x) => x.Level !== 'Material' && x.Level !== 'EPO');
      lvls.filter((x) => x.Level === 'Equipment')[0].Level_DBID = 0;
      setLevels(lvls);
      setLevel(lvls[0]);
      Api({
        sp: 'getRoles',
        json: {},
      }).then((response) => {
        response.unshift({
          Role: 'User',
          Role_DBID: 0,
        });
        response = response.filter((x) => x.Role !== 'Dev');
        response.forEach((r) => {
          r.color = selectColor(r.Role_DBID);
        });
        setRoles(response);
      });
      Api({
        sp: 'getWorkflow',
        json: { name: 'Status', levelId: lvls[0].Level_DBID },
      }).then((flow) => {
        flow = flow.filter((x) => x.Level_DBID === lvls[0].Level_DBID);
        let eds = [];
        let nds = [];
        flow.forEach((f) => {
          f.data = JSON.parse(f.data);
          f.position = JSON.parse(f.position);

          if (f.source) {
            delete f.position;
            f.type = 'customEdge';
            f.sourceHandle = 'source';
            f.targetHandle = 'target';
            f.markerEnd = { type: 'arrowclosed' };
            eds.push(f);
          } else {
            delete f.source;
            delete f.target;
            f.type = 'customNode';
            f.selected = false;
            nds.push(f);
          }
        });

        setNodes(nds || []);
        setEdges(eds || []);
        setViewport({ x: 0, y: 0, zoom: 1 });
      });
    });
  }, []);

  const handleClick = (event) => {
    setContextAnchor(null);
    setNodeContext(false);
    let edge = edges.filter((x) => x.id === event.target.id)[0];
    // let biEdge = null;
    if (edge) {
      editEdge(edge.id);
      setSelected(edge.data.roles ?? []);
    }
  };

  const selectColor = (id) => {
    const hue = id * 137.508; // use golden angle approximation
    return `hsl(${hue},50%,50%)`;
  };

  const contextClick = (event) => {
    event.preventDefault();
    if (!['custom-node', 'pointer'].includes(event.target.className)) {
      setContextAnchor(event.target);
      setNodeContext(false);
      setYOffset(event.clientY);
      setXOffset(event.clientX);
    }
  };

  const onConnect = useCallback((params) => {
    let es = [];
    edges.forEach((e) => {
      if (
        (e.source === params.source && e.target === params.target) ||
        (e.target === params.source && e.source === params.target)
      ) {
        es.push(e);
      }
    });
    setEdges((eds) =>
      addEdge(
        {
          ...params,
          type: 'customEdge',
          markerEnd: {
            type: MarkerType.ArrowClosed,
          },
          data: {
            interactionWidth: 50,
            double: es.length > 0,
            biRoles: es[0]?.roles ?? [],
          },
        },
        eds
      )
    ),
      [setEdges];
  });

  const editEdge = (id) => {
    setCurrentModal('EditEdge');
    let edge = edges.filter((x) => x.id === id)[0];
    setCurrentEdge(edge);
  };

  const onEdgeUpdateStart = useCallback(() => {
    edgeUpdateSuccessful.current = false;
  }, []);

  const onEdgeUpdate = useCallback((oldEdge, newConnection) => {
    edgeUpdateSuccessful.current = true;
    setEdges((els) => updateEdge(oldEdge, newConnection, els));
  }, []);

  const onEdgeUpdateEnd = useCallback((_, edge) => {
    if (!edgeUpdateSuccessful.current) {
      setEdges((eds) => eds.filter((e) => e.id !== edge.id));
    }

    edgeUpdateSuccessful.current = true;
  }, []);

  const nodeClick = (event, node) => {
    if (event.detail === 2) {
      setEditNode(node);
      setCurrentLabel(node.data.label);
      setCurrentModal('EditNode');
    }
  };

  const closeModal = () => {
    setEditNode(null);
    setCurrentEdge(null);
    setCurrentModal(null);
    // setDoubleLabel(false);
    setSelected([]);
  };

  const createNode = () => {
    const id = uuidv4();
    const newNode = {
      id: id,
      type: 'customNode',
      position: project({ x: xOffset - 50, y: yOffset }),
      sourcePosition: 'right',
      targetPosition: 'left',
      data: {
        label: `Node...`,
      },
    };
    setNodes((nds) => nds.concat(newNode));
    setContextAnchor(null);
    setNodeContext(false);
  };

  useEffect(() => {
    Api({
      sp: 'getWorkflow',
      json: { name: 'Status', levelId: level.Level_DBID },
    }).then((flow) => {
      flow = flow.filter((x) => x.Level_DBID === level.Level_DBID);
      let eds = [];
      let nds = [];
      flow.forEach((f) => {
        f.data = JSON.parse(f.data);
        f.position = JSON.parse(f.position);

        if (f.source) {
          delete f.position;
          f.type = 'customEdge';
          f.sourceHandle = 'source';
          f.targetHandle = 'target';
          f.markerEnd = { type: 'arrowclosed' };
          eds.push(f);
        } else {
          delete f.source;
          delete f.target;
          f.type = 'customNode';
          f.selected = false;
          nds.push(f);
        }
      });

      setNodes(nds || []);
      setEdges(eds || []);
      setViewport({ x: 0, y: 0, zoom: 1 });

      toast.success('Flow loaded');
    });
  }, [level]);

  useEffect(() => {
    setNodes((nds) =>
      nds.map((node) => {
        if (node.id === editNode?.id) {
          node.data = {
            ...node.data,
            label: currentLabel,
          };
        }
        return node;
      })
    );
  }, [currentLabel, setNodes]);

  useEffect(() => {
    setEdges((eds) =>
      eds.map((edge) => {
        if (edge.id === currentEdge?.id) {
          edge.data = {
            ...edge.data,
            roles: selected,
          };
        }
        return edge;
      })
    );
  }, [selected, setEdges]);

  const updateLabel = (val) => {
    setCurrentLabel(val);
  };

  const nodeContextClick = (event, node) => {
    event.preventDefault();
    setContextAnchor(event.target);
    setNodeContext(true);
    setEditNode(node);
    setYOffset(event.clientY);
    setXOffset(event.clientX);
  };

  const onDelete = (val) => () => {
    if (selected.length > 1) {
      setSelected((selected) => selected.filter((v) => v.Role_DBID !== val));
    }
  };

  const updateFields = (event, val) => {
    if (event.key === 'Backspace') {
      return;
    }
    const map = new Map(val.map((pos) => [pos.Role_DBID, pos]));
    const uniques = [...map.values()];
    setSelected(uniques);
  };

  const deleteNode = () => {
    if (editNode.count <= 1) {
      setNodes(nodes.filter((x) => x.id !== editNode.id));
      setEditNode(null);
    } else {
      toast.warn('Cannot delete status that is in use');
    }
  };

  useEffect(() => {
    let edge = currentEdge;
    if (edge) {
      edge.data.roles = selected;
      let eds = edges;
      eds = eds.filter((x) => x.id !== edge.id);
      eds.push(edge);
    }
  }, [selected]);

  const { setViewport } = useReactFlow();

  const createFlowJSON = (flow) => {
    let eds = flow.edges.map((x) => ({
      id: x.id,
      data: x.data,
      source: x.source,
      target: x.target,
    }));
    let nds = flow.nodes.map((x) => ({
      id: x.id,
      data: x.data,
      position: x.position,
    }));
    return { nodes: nds, edges: eds };
  };

  const onSave = useCallback(() => {
    if (rfInstance) {
      const flow = rfInstance.toObject();
      Api({
        sp: 'updateWorkflow',
        json: {
          name: 'Status',
          levelId: level.Level_DBID,
          definition: createFlowJSON(flow),
        },
      }).then(() => {
        toast.success('Flow saved');
      });
    }
  }, [rfInstance]);

  const onRestore = useCallback(() => {
    const restoreFlow = async () => {
      Api({
        sp: 'getWorkflow',
        json: { name: 'Status', levelId: bake('level').Level_DBID },
      }).then((flow) => {
        flow = flow.filter((x) => x.Level_DBID === level.Level_DBID);
        let eds = [];
        let nds = [];
        flow.forEach((f) => {
          f.data = JSON.parse(f.data);
          f.position = JSON.parse(f.position);

          if (f.source) {
            delete f.position;
            f.type = 'customEdge';
            f.sourceHandle = 'source';
            f.targetHandle = 'target';
            f.markerEnd = { type: 'arrowclosed' };
            eds.push(f);
          } else {
            delete f.source;
            delete f.target;
            f.type = 'customNode';
            f.selected = false;
            nds.push(f);
          }
        });

        setNodes(nds || []);
        setEdges(eds || []);
        setViewport({ x: 0, y: 0, zoom: 1 });

        toast.success('Flow loaded');
      });
    };

    restoreFlow();
  }, [setNodes, setViewport]);

  const updateLevel = (event, val) => {
    setLevel(val);
    bake('level', val);
  };

  const targetLabel = nodes.filter((x) => x.id === currentEdge?.target)[0]?.data
    .label;
  const sourceLabel = nodes.filter((x) => x.id === currentEdge?.source)[0]?.data
    .label;

  const warningUnlabeled = nodes.some((x) => !x.data.label);

  const warningDuplicate =
    nodes
      .map((x) => x.data.label)
      .reduce((x, y) => (x.includes(y) ? x : [...x, y]), []).length !==
    nodes.length;

  const warnings = warningUnlabeled
    ? 'One or more nodes do not have a label!'
    : warningDuplicate
    ? 'One or more nodes have duplicate labels!'
    : '';

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h2 className="status-workflow-title">
          {level.Level} Status Workflow{' '}
          {warnings ? (
            <span>
              <Hint
                placement="right"
                delay={{ show: 250, hide: 400 }}
                title={warnings}
              >
                <ExclamationCircleFill
                  size={20}
                  color={'red'}
                  className="pointer"
                ></ExclamationCircleFill>
              </Hint>
            </span>
          ) : null}
        </h2>
        {levels ? (
          <Autocomplete
            id="workflow-autocomplete"
            className="status-workflow-options mt-2"
            clearIcon={false}
            value={level}
            options={levels ?? []}
            autoHighlight={true}
            getOptionLabel={(option) => option.Level}
            onChange={(event, newValue) => updateLevel(event, newValue)}
            renderTags={() => null}
            style={{ width: '20%' }}
            renderInput={(params) => <TextField {...params} label="Workflow" />}
          />
        ) : null}
      </div>
      <div
        className="flow-wrapper"
        ref={reactFlowWrapper}
        style={{ height: '90vh', paddingTop: '50px' }}
        onContextMenu={contextClick}
        onClick={handleClick}
      >
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodeContextMenu={(event, node) => nodeContextClick(event, node)}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          onEdgeUpdate={onEdgeUpdate}
          onEdgeUpdateStart={onEdgeUpdateStart}
          onEdgeUpdateEnd={onEdgeUpdateEnd}
          // snapToGrid={true}
          fitView
          fitViewOptions={fitViewOptions}
          onNodeClick={nodeClick}
          onInit={setRfInstance}
        />

        <Popper
          id={'context-menu'}
          open={contextAnchor !== null}
          anchorEl={contextAnchor}
          transition
          placement="bottom-start"
          style={{
            position: 'absolute',
            left: `${xOffset}px`,
            top: `${yOffset}px`,
          }}
        >
          {() => (
            <Paper>
              <MenuList autoFocus>
                <MenuItem onClick={createNode}>Add Node</MenuItem>
                <Divider></Divider>
                {nodeContext ? (
                  <MenuItem onClick={deleteNode}>Delete Node</MenuItem>
                ) : null}
                <MenuItem onClick={onSave}>Save</MenuItem>
                <MenuItem onClick={onRestore}>Load</MenuItem>
              </MenuList>
            </Paper>
          )}
        </Popper>

        <Modal
          show={currentModal === 'EditNode'}
          onHide={() => closeModal()}
          className="node-modal"
        >
          <Modal.Header closeButton="true">
            <Modal.Title>Update {editNode?.data.label}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6 className="mb-1">Status:</h6>
            <Form.Control
              defaultValue={editNode?.data.label}
              type="text"
              placeholder="Status"
              onChange={(e) => updateLabel(e.target.value)}
              autoFocus
            />
          </Modal.Body>
        </Modal>

        <Modal
          show={currentModal === 'EditEdge'}
          onHide={() => closeModal()}
          className="edge-modal"
        >
          <Modal.Header closeButton="true">
            <Modal.Title>
              {'Update ' +
                (sourceLabel ?? '(blank)') +
                ' to ' +
                (targetLabel ?? '(blank)')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6 className="mb-1">Roles:</h6>
            <Autocomplete
              multiple
              id="roles-autocomplete"
              value={selected}
              options={roles ?? []}
              autoHighlight={true}
              getOptionLabel={(option) => option.Role}
              onChange={(event, newValue) => updateFields(event, newValue)}
              renderTags={() => null}
              style={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} label="Roles" />}
              renderOption={(props, option) => {
                const { Role, color } = option;
                return (
                  <span {...props} style={{ color: color }}>
                    {Role}
                  </span>
                );
              }}
            />
            <Box
              mt={1}
              sx={{
                '& > :not(:last-child)': { mr: 1 },
                '& > *': { mr: 1, mt: 1 },
              }}
            >
              {selected.map((v) => (
                <Chip
                  key={v.Role_DBID}
                  label={v.Role}
                  onDelete={onDelete(v.Role_DBID)}
                  style={{ backgroundColor: v.color }}
                />
              ))}
            </Box>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
};

export default function StatusWorkflow() {
  return (
    <ReactFlowProvider>
      <AddNodeOnEdgeDrop />
    </ReactFlowProvider>
  );
}
