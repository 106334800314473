// JavaScript source code
import Button from 'react-bootstrap/Button';
import React, { Component } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { DownloadExcel } from './interface';
import { toast } from 'react-toastify';

class ExportCSV extends Component {
  constructor(props) {
    super(props);

    this.state = {
      exportCSV: this.props.exportCSV,
      fileName: this.props.fileName,
      loading: false,
    };

    this.fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    this.fileExtension = '.xlsx';
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props) {
      if (!this.props.exportCSV) {
        this.setState({ loading: this.props.exportCSV });
      }
      this.setState({
        exportCSV: this.props.exportCSV,
        fileName: this.props.fileName,
      });
    }
  };

  downloadExcel = (data) => {
    let cols = data.cols ?? this.props.columns;
    let group = data.group ?? this.props.groupId;

    const levels = data.levels ?? this.props.levels;
    data.page = 0;
    data.rows = this.props.totalRows + 1;
    data.currentEntityType = this.props.entityType;
    data.export = true;

    let sp, payload, columns;

    if (group === -1) {
      payload = {
        ...this.props.getQueryJSON(levels, data, cols, this.props.entityType),
      };
      sp = 'exportSearchResults';
      columns = cols;
    } else {
      sp = 'getPageByGroup';
      payload = this.props.getGroupPayload(data, group, cols);
      columns = this.props.groupColumns;
    }

    DownloadExcel({
      sp: sp,
      cols: columns,
      level: this.props.level,
      json: payload,
    })
      .then(async (res) => {
        var blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;',
        });

        // Programatically create a link and click it:
        var a = document.createElement('a');
        a.href = URL.createObjectURL(blob);
        a.download = `${data.fileName}.xlsx`;
        a.click();
        this.props.closeExport();
      })
      .catch((e) => {
        console.log('Error ' + e);
        this.props.closeExport();
        toast.error('Please try again later as server is loaded with requests');
      });
  };

  exportToCSV = () => {
    this.setState({ loading: true });
    this.downloadExcel({ fileName: this.state.fileName });
  };

  closeModal = () => {
    if (!this.state.loading) {
      this.setState({ exportCSV: false });
      this.props.closeExport();
    }
  };

  rename = (value) => {
    this.setState({ fileName: value });
  };

  render = () => {
    return (
      <>
        <Modal
          size="lg"
          show={this.state.exportCSV}
          onHide={() => this.closeModal()}
          className="entity-modal"
        >
          <Modal.Header closeButton="true">
            <Modal.Title>Confirm Export</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            File Name
            <InputGroup className="mb-3">
              <Form.Control
                disabled={this.state.loading}
                type="text"
                autoFocus
                placeholder="File Name"
                defaultValue={this.state.fileName}
                onBlur={(e) => this.rename(e.target.value)}
              />
              <InputGroup.Text id="basic-addon2">.xlsx</InputGroup.Text>
            </InputGroup>
          </Modal.Body>
          <Modal.Footer>
            {this.state.loading ? (
              <div className="spinner-border spinner" role="status"></div>
            ) : null}

            <Button
              disabled={this.state.loading}
              variant="outline-primary"
              onClick={() => this.closeModal()}
            >
              Cancel
            </Button>
            <Button
              disabled={this.state.loading}
              variant="primary"
              onClick={(e) => {
                e.currentTarget.disabled = true;
                this.exportToCSV();
              }}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  };
}

export default ExportCSV;
