import * as d3 from 'd3';
import { useEffect } from 'react';

const LineGraph = (props) => {
  useEffect(() => {
    if (props.height && props.width) {
      renderGraph(props.data);
    }
  }, [props.data, props.width, props.height]);

  const renderGraph = (data) => {
    let height = 0.8 * props.height,
      width = props.width,
      margin = { top: 30, right: 30, bottom: 30, left: 50 };

    // Setup a DateTime parser
    let parseTime = d3.timeFormat('%B-%d');
    let parseGphTime = d3.timeFormat('%d %b');

    // Create SVG

    d3.select('#myGraph').selectAll('*').remove();

    let svg = null;
    svg = d3
      .select('#myGraph')
      .append('svg')
      .attr('width', width)
      .attr('height', props.height);

    height = height - margin.top - 1.5 * margin.bottom;
    width = width - margin.left - margin.right;

    svg
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip')
      .append('rect')
      .attr('width', width)
      .attr('height', height);
    let graph = svg
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
    graph
      .append('defs')
      .append('clipPath')
      .attr('id', 'clip')
      .append('rect')
      .attr('x', 0)
      .attr('y', margin.top)
      .attr('width', width)
      .attr('height', height);

    let dataKeys = data.length
      ? Object.keys(data[0]).filter((x) => x !== 'date')
      : [];
    dataKeys = dataKeys.length ? dataKeys.map((x) => x.replace(' ', '_')) : [];
    // Setup scales
    let x = d3.scaleTime().range([0, width]),
      x0 = d3.scaleTime().range([0, width]),
      y = d3.scaleLinear().range([height, 0]),
      y0 = d3.scaleLinear().range([height, 0]),
      z = d3
        .scaleOrdinal()
        .domain(dataKeys)
        .range(d3.schemeCategory10.concat(d3.schemeDark2));

    // Setup Axis
    let xAxis = d3.axisBottom(x),
      yAxis = d3.axisLeft(y);

    // Setup zoom & area
    let zoom = d3
      .zoom()
      .scaleExtent([1, 5])
      .translateExtent([
        [0, 0],
        [width, height],
      ])
      .extent([
        [0, 0],
        [width, height],
      ])
      .on('zoom', onZoom);
    // Setup data binding on line
    let line = d3
      .line()
      // .curve(d3.curveBasis)
      .x(function (d) {
        return x(d.date);
      })
      .y(function (d) {
        return y(d.number);
      });

    function onZoom(event) {
      let t = event.transform,
        xt = t.rescaleX(x),
        yt = t.rescaleY(y);
      x0.domain(xt.domain());

      y0.domain(yt.domain());
      // Method to rescale
      let zoomedLine = d3
        .line()
        // .curve(d3.curveBasis)
        .x(function (d) {
          return xt(d.date);
        })
        .y(function (d) {
          return yt(d.number);
        });

      path.attr('d', function (d) {
        return zoomedLine(d.values);
      });
      path
        .attr('stroke-dasharray', function () {
          let factor = d3.zoomTransform(graph.node()).k;
          let totalLength = d3.select(this).node().getTotalLength() * factor;
          return totalLength + ' ' + totalLength;
        })
        .attr('stroke-dashoffset', function () {
          return (
            d3.select(this).node().getTotalLength() *
            d3.zoomTransform(graph.node()).k
          );
        })
        .attr('stroke-dashoffset', 0);
      graph
        .select('.axis--x')
        .call(xAxis.scale(xt))
        .selectAll('text')
        .style('text-anchor', 'end')
        .attr('font-size', '0.8rem')
        .attr('font-weight', 'bold')
        .attr('dx', '-.8em')
        .attr('dy', '-.15em')
        .attr('transform', 'rotate(-65)')
        .append('text');

      graph.select('.axis--y').call(yAxis.scale(yt));
      // .selectAll("text")
      // .attr("transform", "rotate(-90)")
      // .attr("font-size", "0.8rem")
      // .attr("y", -margin.bottom - 8)
      // .attr("x", -3 * margin.left)
      // .text(props.yLabel);
    }

    // Rearrange data
    let statusData = data.length
      ? Object.keys(data[0])
          .filter((x) => x !== 'date')
          .map(function (status) {
            let stat = status.replace(' ', '_');
            return {
              status: stat,
              values: data.map(function (d) {
                let number = d[status];
                return {
                  date: new Date(parseTime(d['date'])),
                  number: isNaN(number) ? 0 : number,
                };
              }),
            };
          })
      : [];

    var dataNest = d3.group(dataKeys);

    // calculate the legendspace required for each legend
    let legendSpace = width / dataNest.length;
    legendSpace = legendSpace < 120 ? 120 : legendSpace;
    // Setup scales with data
    let xtraY = 9;
    x.domain(
      d3.extent(data, function (d) {
        return new Date(parseTime(d['date']));
      })
    );
    x0.domain(
      d3.extent(data, function (d) {
        return new Date(parseTime(d['date']));
      })
    );

    y.domain([
      d3.min(statusData, function (b) {
        return d3.min(b.values, function (d) {
          return d.number;
        });
      }),
      d3.max(statusData, function (b) {
        return d3.max(b.values, function (d) {
          return d.number;
        });
      }) + xtraY,
    ]);

    y0.domain([
      d3.min(statusData, function (b) {
        return d3.min(b.values, function (d) {
          return d.number;
        });
      }),
      d3.max(statusData, function (b) {
        return d3.max(b.values, function (d) {
          return d.number;
        });
      }) + xtraY,
    ]);

    z.domain(
      statusData.map(function (b) {
        return b.status;
      })
    );

    graph
      .append('g')
      .attr('class', 'axis axis--x')
      .attr('transform', 'translate(0,' + height + ')')
      .call(xAxis)
      .selectAll('text')
      .style('text-anchor', 'end')
      .attr('font-size', '0.9rem')
      .attr('font-weight', 'bold')
      .attr('dx', '-.8em')
      .attr('dy', '-.15em')
      .attr('transform', 'rotate(-65)')
      .append('text');

    graph
      .append('g')
      .attr('class', 'axis axis--y')
      .call(yAxis)
      .style('text-anchor', 'end')
      .attr('font-size', '0.9rem')
      .attr('font-weight', 'bold');

    graph
      .append('g')
      .attr('class', 'axis axis--y')
      .append('text')
      .attr('class', 'axis-label')
      .attr('transform', 'rotate(-90)')
      .attr('font-size', '1rem')
      .attr('font-weight', 'bold')
      .attr('y', -margin.bottom - 8)
      .attr('x', -3 * margin.left)
      .text(props.yLabel);

    let statusDataPath = graph
      .selectAll('.line')
      .data(statusData)
      .enter()
      .append('g');
    let path = statusDataPath
      .append('path')
      .attr('class', 'line')
      .attr('clip-path', 'url(#clip)')
      .style('stroke-width', '3px')
      .attr('id', function (d) {
        return d.status;
      })
      .attr('d', function (d) {
        return line(d.values);
      })
      .style('stroke', function (d) {
        return z(d.status);
      });

    graph.transition().duration(1000);
    graph.call(zoom);
    let row = 1;
    let idx = 0;
    let totalLegendSpace = 0;
    dataNest.forEach(function (d, i) {
      d = d.replace(' ', '_');

      let posX = legendSpace / 2 + (i - idx) * legendSpace;
      totalLegendSpace = posX;
      // add new line when legends overflow in the width
      if (totalLegendSpace + 120 > width) {
        row = row + 1;
        idx = i;
        posX = legendSpace / 2 + (i - idx) * legendSpace;
        totalLegendSpace = 0;
      }
      let posY = height + margin.bottom + 2 * margin.top + row * 20;
      svg
        .append('text')
        .attr('id', d + 'LegText')
        .attr('x', posX) // spacing
        .attr('y', posY)
        .attr('class', 'legend') // style the legend
        .style('fill', function () {
          return z(d);
        })
        .attr('font-weight', 'bold')
        .style('cursor', 'pointer')
        .text(d)
        .on('click', function () {
          var opacity = d3.select('#' + d).style('opacity') == 1 ? 0 : 1;
          let legOpacity = opacity === 0 ? 0.3 : 1;
          d3.select('#' + d + 'LegCirc').style('opacity', legOpacity);
          d3.select('#' + d + 'LegText').style('opacity', legOpacity);
          d3.select('#' + d)
            .transition()
            .duration(500)
            .style('opacity', opacity);
        });

      svg
        .append('circle')
        .attr('id', d + 'LegCirc')
        .attr('cx', posX - 10)
        .attr('cy', posY - 5)
        .style('cursor', 'pointer')
        .attr('r', 6)
        .style('fill', 'white')
        .attr('stroke', z(d))
        .style('stroke-width', '2px')
        .on('click', function () {
          let opacity = d3.select('#' + d).style('opacity') == 1 ? 0 : 1;
          let legOpacity = opacity === 0 ? 0.3 : 1;
          d3.select('#' + d)
            .transition()
            .duration(500)
            .style('opacity', opacity);
          d3.select('#' + d + 'LegCirc').style('opacity', legOpacity);
          d3.select('#' + d + 'LegText').style('opacity', legOpacity);
        });
    });
    // Animations
    path
      .attr('stroke-dasharray', function () {
        let factor = d3.zoomTransform(graph.node()).k;
        let totalLength = d3.select(this).node().getTotalLength() * factor;
        return totalLength + ' ' + totalLength;
      })
      .attr('stroke-dashoffset', function () {
        return (
          d3.select(this).node().getTotalLength() *
          d3.zoomTransform(graph.node()).k
        );
      })
      .transition()
      .duration(3000)
      .ease(d3.easeSinInOut)
      .attr('stroke-dashoffset', 0);

    let mouseG = graph.attr('class', 'mouse-over-effects');

    mouseG
      .append('path') // this is the black vertical line to follow mouse
      .attr('class', 'mouse-line')
      .style('stroke', 'black')
      .style('stroke-width', '1px')
      .style('opacity', '0');

    let lines = document.getElementsByClassName('line');

    let mousePerLine = mouseG
      .selectAll('.mouse-per-line')
      .data(statusData)
      .enter()
      .append('g')
      .attr('class', 'mouse-per-line');

    mousePerLine
      .attr('clip-path', 'url(#clipper)')
      .append('circle')
      .attr('class', 'mouseCircle')
      .attr('r', 5)
      .style('stroke', function (d) {
        return z(d.status);
      })
      .style('fill', 'white')
      .style('stroke-width', '3px')
      .style('opacity', '0');

    mousePerLine
      .append('text')
      .attr('class', 'text-date')
      .attr('transform', 'translate(-60,3)');
    mousePerLine
      .append('text')
      .attr('class', 'text-value')
      .attr('transform', 'translate(-60,40)');

    mouseG
      .append('svg:rect') // append a rect to catch mouse movements on canvas
      .attr('width', width) // can't catch mouse events on a g element
      .attr('height', height)
      .attr('fill', 'none')
      .attr('pointer-events', 'all')
      .on('mouseout', function () {
        // on mouse out hide line, circles and text
        d3.select('.mouse-line').style('opacity', '0');
        d3.selectAll('.mouse-per-line circle').style('opacity', '0');
        d3.selectAll('.mouse-per-line text').style('opacity', '0');
      })
      .on('mouseover', function () {
        // on mouse in show line, circles and text
        d3.select('.mouse-line')
          .style('opacity', '0.4')
          .attr('stroke-width', '3px');
        d3.selectAll('.mouse-per-line circle').style('opacity', '1');
        d3.selectAll('.mouse-per-line text').style('opacity', '1');
      })
      .on('mousemove', (event) => {
        // mouse moving over canvas
        let mouse = d3.pointer(event);
        d3.select('.mouse-line').attr('d', function () {
          let d = 'M' + mouse[0] + ',' + height;
          d += ' ' + mouse[0] + ',' + 0;
          return d;
        });

        d3.selectAll('.mouse-per-line').attr('transform', function (d, i) {
          let xDate = x0.invert(mouse[0]),
            bisect = d3.bisector(function (d) {
              return d.date;
            }).right;
          bisect(d.values, xDate);
          let beginning = 0,
            end = lines[i].getTotalLength(),
            target = null;
          let pos;
          // eslint-disable-next-line no-constant-condition
          while (true) {
            target = Math.floor((beginning + end) / 2);
            pos = lines[i].getPointAtLength(target);
            if (
              (target === end || target === beginning) &&
              pos.x !== mouse[0]
            ) {
              break;
            }
            if (pos.x > mouse[0]) end = target;
            else if (pos.x < mouse[0]) beginning = target;
            else break; //position found
          }
          let curPath = d3.select(this);
          var opacity = d3.select('#' + d.status).style('opacity');

          let legendIdx = dataKeys.indexOf(d.status) + 1;
          let newLegendPosX = legendIdx % 2 === 0 ? -margin.left - 100 : 10;
          let newLegendPosY = 0;

          curPath
            .select('.text-date')
            .text(parseGphTime(xDate) + ' - ' + d.status)
            .attr(
              'transform',
              'translate(' + newLegendPosX + ', ' + newLegendPosY + ')'
            )
            .attr('font-weight', 600)
            .style('fill', function () {
              return z(d.status);
            })
            .style('opacity', opacity - 0.25);

          newLegendPosY = newLegendPosY + 20;
          curPath
            .select('.text-value')
            .text(y0.invert(pos.y).toFixed(2))
            .attr(
              'transform',
              'translate(' + (newLegendPosX + 50) + ', ' + newLegendPosY + ')'
            )
            .attr('font-weight', 'bold')
            .style('opacity', opacity);

          curPath.select('.mouseCircle').style('opacity', opacity);

          return 'translate(' + mouse[0] + ',' + pos.y + ')';
        });
      });
  };

  return <div id="myGraph"></div>;
};

export default LineGraph;
