import { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { debounce } from '@mui/material/utils';
import { Api } from './interface';

const DebouncedMetaValuesAutocomplete = (props) => {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filterText, setFilterText] = useState(false);

  const handleSearch = debounce((event) => {
    const userInput = event.target.value;
    setFilterText(userInput);
    setOptions([]);

    if (userInput.length > 0) {
      setLoading(true);

      Api({
        sp: 'getDebouncedMetaValues',
        json: {
          filterText: userInput,
          entityMetaTypeId: props.fieldId,
        },
      }).then((response) => {
        let newOptions = response.map((o) => o.EntityMetaValue);
        setOptions(newOptions);

        setLoading(false);
      });
    }
  }, 700);

  const sortMetaValues = (newValue) => {
    // Sort values if they are provided to ensure report definition consistency
    let newMetaFilters = [...props.selectedMetaFilters];

    if (newValue.length > 0) {
      let sortedMetaValues = newValue.sort();

      newMetaFilters[props.index].fieldValues = sortedMetaValues;
      props.setSelectedMetaFilters(newMetaFilters);
    } else {
      newMetaFilters[props.index].fieldValues = [];
      props.setSelectedMetaFilters(newMetaFilters);
    }
  };

  return (
    <Autocomplete
      multiple
      filterSelectedOptions
      disableClearable
      className="type-ahead"
      size="small"
      open={open}
      onOpen={() => {
        setOptions([]);
        setOpen(true);
      }}
      onClose={() => setOpen(false)}
      value={props.fieldValues || []}
      noOptionsText={
        filterText.length > 0 ? 'No Options Found' : 'Type To Search'
      }
      onInputChange={handleSearch}
      loading={loading}
      options={options}
      onChange={(e, newValue) => {
        sortMetaValues(newValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          placeholder={props.fieldValues.length > 0 ? '' : 'Search Values'}
        />
      )}
    />
  );
};

export default DebouncedMetaValuesAutocomplete;
