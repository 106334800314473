import { toast } from 'react-toastify';
import { Api, Sas } from './interface';
import { useIdleTimer } from 'react-idle-timer';

const bake = (c, v) => {
  return oven({}, true, c, v);
};

let oktaKeys = [];
let oKeys = [];

const minHeight = (pageNo, rowsPerPage, totalRows) => {
  let endOfPage = (pageNo + 1) * rowsPerPage;
  if (totalRows < endOfPage) {
    let diff = rowsPerPage - (endOfPage - totalRows);
    return diff <= 5 ? false : true;
  } else {
    return true;
  }
};

const formatHistoryResponse = (response) => {
  let metadata = {};

  return response.map((entry) => {
    entry.EntityMetaType =
      entry.EntityMetaType === 'EntityStatus' ? 'Status' : entry.EntityMetaType;
    entry.EntityMetaValue =
      entry.DataType === 'Binary'
        ? entry.EntityMetaValue === '1'
          ? 'True'
          : 'False'
        : entry.EntityMetaValue;
    entry.EntityMetaValue = entry.EntityMetaValue || '(blank)';
    let type = entry.EntityMetaType;
    let value = entry.EntityMetaValue;
    if (type) {
      entry.PrevEntityMetaValue = metadata[type] || '';
      metadata[type] = value;
    }
    return entry;
  });
};

const handleResize = () => {
  let element = document.getElementById('tab-container');
  if (element) {
    let bounds = element.getBoundingClientRect();
    let top = bounds.top; // table top position from window top
    let height = bounds.height; // table height
    let totalHeight = top + height + 70; // 70px is the footer of the table
    let windowHeight = window.innerHeight;
    if (windowHeight <= totalHeight) {
      let diff = totalHeight - windowHeight;
      element.style.height = '' + (height - diff) + 'px';
    } else {
      if (windowHeight - totalHeight >= 30) {
        let diff = windowHeight - totalHeight;
        element.style.height = '' + (height + diff) + 'px';
      }
    }
  }
};

const isNum = (val) => {
  return !isNaN(val);
};

const uploadError = (row, val, type, format) => {
  return (
    'Row Num : ' +
    (row + 1) +
    ' - ' +
    val +
    ' is not a valid value/format for ' +
    type +
    '. Expected Format : ' +
    format
  );
};

const groupSelectError = (row, val, type) => {
  return (
    'Row Num : ' +
    (row + 1) +
    ' - ' +
    val +
    ' is not a valid option for ' +
    type
  );
};

const toastMsg = (val, item) => {
  return item.DataType === 'Binary'
    ? val
      ? 'True'
      : 'False'
    : val.length
    ? val
    : '(Blank)';
};

const extractValue = (obj, orderBy) => {
  let value = obj[orderBy];
  if (Array.isArray(value)) {
    return value[0].toLowerCase();
  } else if (typeof value === 'string') {
    return value.toLowerCase();
  } else if (typeof value === 'undefined') {
    return '\uffff';
  } else if (value === '') {
    // check if value is an empty string
    return '\uffff'; // return a value larger than any other value
  } else {
    return value;
  }
};

const descendingComparator = (a, b, orderBy) => {
  let aValue = extractValue(a, orderBy);
  let bValue = extractValue(b, orderBy);

  if (aValue != null) {
    aValue = isNum(parseInt(aValue, 10))
      ? parseInt(aValue, 10)
      : aValue.toString();
  }

  if (bValue != null) {
    bValue = isNum(parseInt(bValue, 10))
      ? parseInt(bValue, 10)
      : bValue.toString();
  }

  return (
    (aValue === null) - (bValue === null) ||
    -(aValue > bValue) ||
    +(aValue < bValue)
  );

  // return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const isJson = (str) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const clearCookies = () => {
  getOktaKeys(); // backup of okta keys
  // remove any fields, filters, orders
  localStorage.clear();
  setOktaKeys(); // setting back okta keys to local storage

  toast.success('Cache cleared successfully');
};

const getOktaKeys = () => {
  oktaKeys = [];
  oKeys = [];

  for (let key in localStorage) {
    if (key.includes('okta')) {
      oKeys.push(key);
      let obj = {};
      obj[key] = localStorage.getItem(key);
      oktaKeys.push(obj);
    }
  }
};

const setOktaKeys = () => {
  for (let obj in oktaKeys) {
    let key = oKeys[obj];
    let object = oktaKeys[obj];
    localStorage.setItem(oKeys[obj], object[key]);
  }
};

const getRoles = (setRoleFlag) => {
  let user = bake('user');
  Api({
    sp: 'getRolesByUser',
    json: { user: user.oid },
  }).then((response) => {
    bake('roles', response);
    if (setRoleFlag) {
      setRoleFlag(true);
    }
    return response;
  });
};

const checkVersion = () => {
  Api({ sp: 'getVersion', json: {} }).then((response) => {
    bake('version', response[0].Version);
  });
};

const getSas = () => {
  Sas().then((response) => {
    bake('sas', response.SAS_STRING);
  });
};

const getEnvironment = () => {
  // Determine environment based on current URL
  const hostname = window.location.hostname;

  const currentEnv = hostname.includes('elm.solidapps.net')
    ? 'PROD'
    : hostname.includes('elm-qa.solidapps.net')
    ? 'QA'
    : 'DEV';

  return currentEnv;
};

const setEnvironmentVariables = () => {
  // Get all environment variables
  Api({
    sp: 'getEnvironmentVariables',
    json: { environment: getEnvironment() },
  }).then((response) => {
    // Loop through each env variable and set a cookie
    response.forEach((x) => {
      bake(x.Variable, x.Value);
    });
  });
};

const getNextSteps = (workflow, step) => {
  Api({
    sp: 'getNextWorkflowSteps',
    json: { workflow: workflow, step: step() },
  }).then((response) => {
    return response;
  });
};

const isObj = (str) => {
  if (typeof str === 'object') {
    return true;
  }
  return false;
};

const oven = (props, global, c, v) => {
  const level = props.level ?? '';
  const type = props.type ?? '';
  const suffix = global ? '' : '-' + level + '-' + type;
  if (v || v === '') {
    localStorage.setItem(c + suffix, isObj(v) ? JSON.stringify(v) : v);
  } else {
    const item = localStorage.getItem(c + suffix);
    return isJson(item) ? JSON.parse(item) : item;
  }
};

const clearStorage = (props, c) => {
  const level = props.level ?? '';
  const type = props.type ?? '';
  const suffix = '-' + level + '-' + type;
  localStorage.removeItem(c + suffix);
};

/**
 * @param onIdle - function to notify user when idle timeout is close
 * @param idleTime - number of seconds to wait before user is logged out
 */
const idleTimeout = ({ onIdle, idleTime = 1, handleIdle }) => {
  const idleTimeout = 1000 * idleTime;

  const idleTimer = useIdleTimer({
    timeout: idleTimeout,
    promptTimeout: idleTimeout / 2,
    onPrompt: onIdle,
    onIdle: handleIdle,
    debounce: 500,
  });
  return {
    idleTimer,
  };
};

export {
  minHeight,
  handleResize,
  isNum,
  uploadError,
  groupSelectError,
  toastMsg,
  getComparator,
  formatHistoryResponse,
  isJson,
  clearCookies,
  getRoles,
  checkVersion,
  getSas,
  getEnvironment,
  setEnvironmentVariables,
  getNextSteps,
  oven,
  idleTimeout,
  clearStorage,
  bake,
};
