import { useEffect, useState } from 'react';
import Graph from './graph';
import { Api } from './interface';

import { normalizeStatusData } from './normalizeData';
const GraphPicker = (props) => {
  const [data, setData] = useState([]);
  const [type, setType] = useState('');

  useEffect(() => {
    renderGraph();
  }, [props.item, props.report, props.data]);

  const renderGraph = () => {
    if (props.report === 'Status Percentage') {
      prepareStatusData();
    }
  };

  const prepareStatusData = () => {
    Api({
      sp: 'getEntityStatusHistory',
      json: { entityId: props.item.Entity_DBID },
    }).then((response) => {
      setData(normalizeStatusData(response));
      setType('Donut');
    });
  };

  return (
    <>
      <Graph
        width="100%"
        height="100%"
        data={data}
        type={type}
        hostName={props.hostName}
      />
    </>
  );
};

export default GraphPicker;
